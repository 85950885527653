import * as React from 'react';

import classNames from 'classnames';

import { BaseProps } from '@/core';
import { Container, Row, Col } from '@/layout';

import styles from './Table.scss';

export interface TableHeaderItem {
    label: React.ReactNode;
    width?: string;
    minWidth?: string;
    maxWidth?: string;
    whiteSpace?: string;
}

interface IProps {
    header?: (TableHeaderItem | false)[];
    body: React.ReactNode;
    extra?: React.ReactNode;
    extraHeader?: React.ReactNode;
    containerClassName?: string;
    disableRowHover?: boolean;
    onTableScroll?: (tableWidth: number, maxScroll: number, scrollLeft: number) => void;
    noOutline?: boolean;
    visibleOverflow?: boolean;
}

interface IState {
    minScrollOffset: number;
    maxScrollOffset: number;
    scrollParent?: any;
}

type Props = Omit<React.HTMLProps<HTMLDivElement>, 'ref'> & BaseProps & IProps;

const getScrollParent = (ofNode: any): any => {
    const regex = /(auto|scroll)/;

    // tslint:disable-next-line:only-arrow-functions
    const parents = function (node: any, ps: any): any {
        if (node.parentNode === null) {
            return ps;
        }

        return parents(node.parentNode, ps.concat([node]));
    };

    // tslint:disable-next-line:only-arrow-functions
    const style = function (node: any, prop: any) {
        return getComputedStyle(node, null).getPropertyValue(prop);
    };

    // tslint:disable-next-line:only-arrow-functions
    const overflow = function (node: any) {
        return style(node, 'overflow') + style(node, 'overflow-y') + style(node, 'overflow-x');
    };

    // tslint:disable-next-line:only-arrow-functions
    const scroll = function (node: any) {
        return regex.test(overflow(node));
    };

    // tslint:disable-next-line:only-arrow-functions
    const scrollParent = function (node: any) {
        if (!(node instanceof HTMLElement || node instanceof SVGElement)) {
            return;
        }

        const ps = parents(node.parentNode, []);

        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < ps.length; i += 1) {
            if (scroll(ps[i])) {
                return ps[i];
            }
        }

        return document.scrollingElement || document.documentElement;
    };

    return scrollParent(ofNode);
};

export default class Table extends React.PureComponent<Props, IState> {
    private tableElement: HTMLDivElement | null = null;
    private tableContainer = React.createRef<HTMLDivElement>();
    private scrollCounter: number;
    private resizeObserver: ResizeObserver;

    constructor(props: Props) {
        super(props);

        this.state = {
            minScrollOffset: 0,
            maxScrollOffset: 0,
            scrollParent: window,
        };

        this.scrollCounter = 999; // always re-compute scroll metrics on first scroll
    }

    componentWillUnmount(): void {
        window.removeEventListener('scroll', this.handleWindowScroll, true);
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }

        if (this.tableContainer.current) {
            this.tableContainer.current.removeEventListener('scroll', this.handleTableScroll, true);
        }
    }

    componentDidMount(): void {
        this.handleTableScroll();

        // refresh a few times so that row height variations due to late content load is taken into account
        setTimeout(() => {
            this.handleTableScroll();
        }, 250);

        setTimeout(() => {
            this.handleTableScroll();
        }, 500);

        setTimeout(() => {
            this.handleTableScroll();
        }, 1000);

        setTimeout(() => {
            this.handleTableScroll();
        }, 2000);

        setTimeout(() => {
            this.handleTableScroll();
        }, 3000);

        this.computeState(() => {
            window.addEventListener('scroll', this.handleWindowScroll, true);

            if (this.tableContainer.current) {
                this.tableContainer.current.addEventListener('scroll', this.handleTableScroll, true);
            }

            this.resizeObserver = new ResizeObserver(() => {
                this.handleWindowResize();
                this.handleWindowScroll();
            });

            const body = document.querySelector('body');
            if (body) {
                this.resizeObserver.observe(body);
            }
        });
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (prevProps.body !== this.props.body) {
            this.computeState();
        }
    }

    render() {
        const {
            body,
            header,
            extra,
            extraHeader,
            containerClassName,
            disableRowHover,
            onTableScroll,
            visibleOverflow,
            style,
            className,
            noOutline,
            ...rest
        } = this.props;

        return (
            <Container
                {...rest}
                ref={this.tableContainer}
                className={styles.TableContainer + ' ' + (containerClassName || '') + ' ' + (noOutline ? styles.NoOutline : '')}
                style={
                    !visibleOverflow
                        ? {
                              overflowX: 'auto',
                          }
                        : undefined
                }
            >
                <div
                    ref={element => {
                        this.tableElement = element;
                        this.handleTableScroll();
                    }}
                    style={style}
                    className={classNames(styles.Table, disableRowHover ? styles.TableNoRowHover : undefined, className)}
                >
                    {this.renderHead(false)}
                    <div className={styles.FixedHeadWrap}>{this.renderHead(true)}</div>
                    {header ? <span className={styles.TableHeaderShadow} /> : null}

                    {body}
                </div>

                {extra}
            </Container>
        );
    }

    private renderHead(fixed = false) {
        const { header, extraHeader } = this.props;

        return (
            <React.Fragment>
                {header ? (
                    <Row className={classNames(styles.TableHeader, fixed ? styles.FixedHead : styles.StaticHead)}>
                        {header.map((item, index) => {
                            if (item === false) {
                                return null;
                            }

                            const thStyle: React.CSSProperties = {};
                            const itemStyle: React.CSSProperties = {};

                            if (!fixed) {
                                if (item.width) {
                                    thStyle.width = item.width;
                                }

                                if (item.minWidth) {
                                    thStyle.minWidth = item.minWidth;
                                }

                                if (item.maxWidth) {
                                    thStyle.maxWidth = item.maxWidth;
                                }
                            }

                            return (
                                <Col
                                    key={index}
                                    data-test-id={`${fixed ? 'tableFixedHeaderCol' : 'tableHeaderCol'}-${index}`}
                                    style={thStyle}
                                >
                                    <span
                                        className={styles.HeadItem}
                                        style={itemStyle}
                                    >
                                        {item.label || <i>&nbsp;</i>}
                                    </span>
                                </Col>
                            );
                        })}
                    </Row>
                ) : null}

                {extraHeader ? (
                    <Row className={styles.TableExtraHeaderRow}>
                        <Col>{extraHeader}</Col>
                    </Row>
                ) : null}
            </React.Fragment>
        );
    }

    private handleWindowScroll = (): void => {
        const { minScrollOffset, maxScrollOffset, scrollParent } = this.state;
        if (this.tableElement) {
            if (scrollParent.scrollTop > minScrollOffset && scrollParent.scrollTop < maxScrollOffset) {
                if (!this.tableElement.classList.contains(styles.FixedHeaderVisible)) {
                    this.tableElement.classList.add(styles.FixedHeaderVisible);
                    this.handleTableScroll();
                }
            } else {
                this.tableElement.classList.remove(styles.FixedHeaderVisible);
            }
        }

        // re-compute scroll metrics from time to time
        this.scrollCounter += 1;
        if (this.scrollCounter > 100) {
            this.scrollCounter = 0;
            this.computeState();
        }
    };

    private handleWindowResize = (): void => {
        this.handleTableScroll();
        this.computeState();
    };

    private handleTableScroll = (): void => {
        const tableContainer = this.tableContainer.current;
        if (tableContainer) {
            const fixedHeadWrap = tableContainer.querySelector('.FixedHeadWrap');
            if (fixedHeadWrap) {
                fixedHeadWrap.scrollLeft = tableContainer.scrollLeft;
            }

            if (this.tableElement && this.props.onTableScroll) {
                const tableContainerRect = tableContainer.getBoundingClientRect();
                const tableRect = this.tableElement.getBoundingClientRect();
                this.props.onTableScroll(tableContainerRect.width, tableRect.width, tableContainer.scrollLeft);
            }
        }
    };

    private computeState = (callback?: () => void): void => {
        const tableContainer = this.tableContainer.current as any;
        const headerWidths = [];
        let minScrollOffset = 0;
        let maxScrollOffset = 0;
        let scrollParent;

        if (this.tableElement && tableContainer) {
            scrollParent = getScrollParent(tableContainer);
            const head = this.tableElement.querySelector('.TableHeader.StaticHead');
            if (!head) {
                return;
            }

            const headRect = head.getBoundingClientRect();
            const fixedHeadWrap = this.tableElement.querySelector('.FixedHeadWrap') as HTMLElement;
            const fixedHeadSpans = this.tableElement.querySelectorAll('.TableHeader.FixedHead .Col .HeadItem') as unknown as HTMLElement[];
            const tableContainerRect = tableContainer.getBoundingClientRect();
            const headSpans = head.querySelectorAll('.Col .HeadItem');
            const tableRect = this.tableElement.getBoundingClientRect();
            const scrollParentRect = scrollParent.getBoundingClientRect();
            const shadow = this.tableElement.querySelector('.TableHeaderShadow') as HTMLElement;

            if (shadow) {
                shadow.style.top = headRect.height + 'px';
            }

            fixedHeadWrap.style.maxWidth = tableContainerRect.width - 2 + 'px';

            for (const fixedHeadSpan of fixedHeadSpans) {
                fixedHeadSpan.style.width = 'auto';
                fixedHeadSpan.style.height = headRect.height + 'px';
            }

            minScrollOffset = tableRect.top - scrollParentRect.top;
            if (-scrollParentRect.top !== scrollParent.scrollTop) {
                minScrollOffset += scrollParent.scrollTop;
            }
            maxScrollOffset = minScrollOffset + tableRect.height - headRect.height;

            for (let i = 0; i < headSpans.length; i++) {
                const headStyle = getComputedStyle(headSpans[i]);
                if (headStyle.width) {
                    headerWidths[i] = headStyle.width;
                }
            }

            for (let i = 0; i < fixedHeadSpans.length; i++) {
                fixedHeadSpans[i].style.minWidth = headerWidths[i];
            }
        }

        this.setState(
            {
                minScrollOffset,
                maxScrollOffset,
                scrollParent,
            },
            callback,
        );
    };
}

import DelegatedEventTarget from './DelegatedEventTarget';
import Peer from './Peer';
import PeerTunnel, { TunnelSide } from './PeerTunnel';

export default class DeviceTunnel<MessageType extends object> extends DelegatedEventTarget {
    private readonly peer: Peer;
    private readonly deviceId: string;
    private readonly tunnelPrefix: string;
    private readonly maxEstablishTime: number;
    private readonly retryConneciton: boolean;
    private readonly onConnectionFailure?: (e: Event) => void;
    private tunnel: PeerTunnel | undefined;

    public constructor(
        peer: Peer,
        deviceId: string,
        options: {
            tunnelPrefix: string;
            tunnelSide: TunnelSide;
            maxEstablishTime?: number;
            doNotRetryConnection?: boolean;
            onConnectionFailure?: (e: Event) => void;
        },
    ) {
        super();

        this.peer = peer;
        this.deviceId = deviceId;
        this.tunnelPrefix = options.tunnelPrefix;
        this.maxEstablishTime = options.maxEstablishTime !== undefined ? options.maxEstablishTime : 10000;
        this.retryConneciton = !options.doNotRetryConnection;
        this.onConnectionFailure = options.onConnectionFailure;

        this.peer.onFirstConnect(() => {
            if (options.tunnelSide === 'initiate') {
                this.establishTunnel(this.deviceId, 'initiate');
            } else {
                this.startListening();
            }
        });
    }

    public isConnected(): boolean {
        return this.tunnel?.getConnectionStatus() === 'connected';
    }

    public close(): void {
        this.tunnel?.close();
        this.stopListening();
    }

    public send(message: MessageType): void {
        this.tunnel?.send(message);
    }

    private handleConnectionStatusChanged = () => {
        if (this.tunnel?.getConnectionStatus() === 'closed') {
            this.tunnel.removeEventListener('connectionStatusChanged', this.handleConnectionStatusChanged);
            this.tunnel.removeEventListener('message', this.handleMessage);
            this.close();
            this.dispatchEvent(new Event('disconnected'));
        } else if (this.tunnel?.getConnectionStatus() === 'connected') {
            this.tunnel.removeEventListener('message', this.handleMessage);
            this.tunnel.addEventListener('message', this.handleMessage);
            this.dispatchEvent(new Event('connected'));
        }
    };

    private establishTunnel(toPeerId: string, tunnelSide: TunnelSide): void {
        if (!this.peer.isConnected()) {
            throw new Error('Cannot establish tunnel when Peer is not connected');
        }

        if (this.tunnel) {
            this.tunnel.removeEventListener('message', this.handleMessage);
            this.tunnel.removeEventListener('connectionStatusChanged', this.handleConnectionStatusChanged);
        }

        this.tunnel = new PeerTunnel(this.peer, toPeerId, {
            tunnelPrefix: this.tunnelPrefix,
            tunnelSide,
            maxEstablishTime: this.maxEstablishTime,
            retryConnection: this.retryConneciton,
            onConnectionFailure: this.onConnectionFailure,
        });

        this.tunnel.removeEventListener('connectionStatusChanged', this.handleConnectionStatusChanged);
        this.tunnel.addEventListener('connectionStatusChanged', this.handleConnectionStatusChanged);
    }

    private startListening(): void {
        this.peer.removeEventListener('tunnelRequest:' + this.tunnelPrefix, this.listenForTunnelRequests);
        this.peer.addEventListener('tunnelRequest:' + this.tunnelPrefix, this.listenForTunnelRequests);
    }

    private stopListening(): void {
        this.peer.removeEventListener('tunnelRequest:' + this.tunnelPrefix, this.listenForTunnelRequests);
    }

    private listenForTunnelRequests = (event: MessageEvent): void => {
        if (event.data.prefix === this.tunnelPrefix) {
            this.establishTunnel(event.data.from, 'expect');
        }
    };

    private handleMessage = (event: MessageEvent) => {
        const data = JSON.parse(event.data);

        if (data.action === 'message' && data.payload?.message?.tunnel === true) {
            this.dispatchEvent(
                new MessageEvent('message', {
                    data: JSON.stringify(data.payload.message),
                }),
            );
        }
    };
}

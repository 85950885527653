/* eslint-disable local-rules/force-use-gettext */
import * as React from 'react';
import { useSelector } from 'react-redux';

import { Domain } from 'api';
import { TVScreen, useThunkDispatch } from 'data-store';

import findHiddenSlideContents from '@/utils/findHiddenSlideContents';
import findSlideContents from '@/utils/findSlideContents';
import getDeviceViewStyle from '@/utils/getDeviceViewStyle';

import PreloaderProgress, { PreloaderProgressWrap } from './PreloaderProgress';
import SlideshowPreloaderService from './SlideshowPreloaderService';

import styles from './SlideshowPreloader.scss';

const preloaderService = new SlideshowPreloaderService();

export default function SlideshowPreloader(props: { children: React.ReactNode | React.ReactNode[]; usePreviewImageForContents?: boolean }) {
    const dispatch = useThunkDispatch();
    const device = useSelector(TVScreen.selectors.selectDevice);
    const configurationIsInvalid = useSelector(TVScreen.selectors.selectConfigurationIsInvalid);
    const schedule = useSelector(TVScreen.selectors.selectSchedule);
    const timeline = useSelector(TVScreen.selectors.selectTimeline);
    const slides = useSelector(TVScreen.selectors.selectSlides);
    const hiddenSlides = useSelector(TVScreen.selectors.selectHiddenSlides);
    const allProducts = useSelector(TVScreen.selectors.selectAllSlideshowProducts);
    const [timelineIdToShow, setTimelineIdToShow] = React.useState<false | Domain.Timeline['timelineId']>(false);
    const [allSlidesAreLoaded, setAllSlidesAreLoaded] = React.useState(false);

    const getAllSlidesAreLoaded = () => {
        if (timeline) {
            for (const slide of timeline.content) {
                if (slide.isHidden) {
                    continue;
                }

                if (!findSlideContents(slide, slides)) {
                    return false;
                }
            }
        }

        if (device && device.hiddenSlides) {
            for (const hiddenSlide of device.hiddenSlides) {
                if (!findHiddenSlideContents(hiddenSlide, hiddenSlides)) {
                    return false;
                }
            }
        }

        return true;
    };

    const handleDeviceLoad = async () => {
        if (device && timeline) {
            if (timelineIdToShow !== timeline.timelineId) {
                console.log('HANDLE NEW TIMELINE');
                setTimelineIdToShow(timeline.timelineId);
                dispatch(TVScreen.slideshowState.handleNewSlideshow());
            }

            preloaderService.preload(timeline, device, props.usePreviewImageForContents || false, dispatch);

            const newAllSlidesLoaded = getAllSlidesAreLoaded();
            if (allSlidesAreLoaded !== newAllSlidesLoaded) {
                setAllSlidesAreLoaded(newAllSlidesLoaded);
                dispatch(TVScreen.slideshowState.reducerActions.setPreloaded(newAllSlidesLoaded));
                if (newAllSlidesLoaded) {
                    const newProducts = preloaderService.getAllProducts();
                    if (JSON.stringify(newProducts) !== JSON.stringify(allProducts)) {
                        dispatch(TVScreen.slideshowState.reducerActions.setAllProducts(newProducts));
                    }
                }
                console.log('SLIDESHOW PRELOAD STATUS', newAllSlidesLoaded ? 'preloaded' : 'not yet');
            }
        }
    };

    React.useEffect(() => {
        handleDeviceLoad();
    }, [device, timeline, timelineIdToShow]);
    const showPayIntegrationWarning = device?.type === 'vending-machine' && !device?.configuration.mtMaticPayConfiguredIntegrationId;
    return (
        <PreloaderProgressWrap style={getDeviceViewStyle(device)}>
            {device && !device.isActive ? (
                <div className={styles.SlideshowPreloaderMessage}>This device is inactive.</div>
            ) : showPayIntegrationWarning ? (
                <div className={styles.SlideshowPreloaderMessage}>This device has no pay integration.</div>
            ) : configurationIsInvalid ? (
                <div className={styles.SlideshowPreloaderMessage}>
                    This device is not configured correctly. Please check the configuration page.
                </div>
            ) : !schedule || schedule.length === 0 ? (
                <div className={styles.SlideshowPreloaderMessage}>Please configure a schedule for this device.</div>
            ) : !allSlidesAreLoaded ? (
                <PreloaderProgress />
            ) : (
                props.children
            )}
        </PreloaderProgressWrap>
    );
}

import { createAction, createReducer } from '@reduxjs/toolkit';

import { Domain } from 'api';
import { config } from 'config';
import { DeviceLog } from 'logger';
import * as DeviceInterface from 'meditech-device-interface';
import { browserStorage } from 'utils';

import { ThunkAction, withPayloadType } from '@/action';
import { deviceApi, scheduleApi, deviceSignalingApi, deviceBackendApi } from '@/api';
import { selectLoggedInUser, SignInWithDeviceSignalingToken, getImpersonatingDeviceWithMacAddress } from '@/Authentication';
import { getDeviceLastAutomaticRefresh, setDeviceLastAutomaticRefresh } from '@/Device/browserStorage';
import { SetLocale } from '@/I18n';
import { getCurrentLocale } from '@/I18n/browserStorage';
import { ALL_LOCALES } from '@/Localisation/availableCountriesState';
import { meditechDeviceApi } from '@/meditechDeviceApi';

import { DEVICE_CONTENT_HASH_KEY } from './constants';
import * as interactionState from './interactionState';
import {
    loadDeviceDetails,
    loadDeviceScheduleAndSlideshows,
    loadBranchDetails,
    loadConfiguredIntegrations,
    loadIntegrationPaymentMethods,
} from './loaders';
import { selectSchedule, selectDevice, selectAllSlideshowProducts } from './selectors';
import * as slideshowState from './slideshowState';
import { Slide, Stock } from './types';
import { getSlideId, getDeviceContentHash } from './utils';
import { productCodesMatchFlat } from './VendingMachineCatalog';
import * as vendingMachineState from './vendingMachineState';

export interface DeviceState {
    device?: Domain.DeviceDetails;
    branch?: Domain.BranchDetails;
    schedule?: Domain.DeviceSchedule;
    slideshows: {
        [key: string]: Domain.Slideshow;
    };
    timelines: {
        [key: string]: Domain.Timeline;
    };
    slides?: Slide[];
    hiddenSlides?: Slide[];
    slideMinute: number;
    configurationIsInvalid?: boolean;
    configuredIntegrations: Domain.ConfiguredIntegration[];
    stock: Stock;
    integrationPaymentMethods?: Domain.IntegrationPaymentMethods;
}

const initialState: DeviceState = {
    slideshows: {},
    timelines: {},
    slideMinute: new Date().getHours() * 60 + new Date().getMinutes(),
    configuredIntegrations: [],
    stock: {},
};

export const reducerActions = {
    setDevice: createAction('@tvScreen/device/setDevice', withPayloadType<Domain.DeviceDetails>()),
    setBranch: createAction('@tvScreen/device/setBranch', withPayloadType<Domain.BranchDetails>()),
    setSchedule: createAction('@tvScreen/device/setSchedule', withPayloadType<Domain.DeviceSchedule>()),
    addSlideshow: createAction('@tvScreen/device/addSlideshow', withPayloadType<Domain.Slideshow>()),
    addTimeline: createAction('@tvScreen/device/addTimeline', withPayloadType<Domain.Timeline>()),
    addSlide: createAction('@tvScreen/device/addSlide', withPayloadType<Slide>()),
    addHiddenSlide: createAction('@tvScreen/device/addHiddenSlide', withPayloadType<Slide>()),
    addDeviceHiddenSlide: createAction('@tvScreen/device/addDeviceHiddenSlide', withPayloadType<Domain.DeviceHiddenSlide>()),
    setSlideMinute: createAction('@tvScreen/device/setSlideMinute', withPayloadType<number>()),
    setConfigurationIsInvalid: createAction('@tvScreen/device/setConfigurationIsInvalid', withPayloadType<boolean>()),
    setConfiguredIntegrations: createAction(
        '@tvScreen/device/setConfiguredIntegrations',
        withPayloadType<Domain.ConfiguredIntegration[]>(),
    ),
    setStock: createAction('@tvScreen/device/setStock', withPayloadType<Stock>()),
    setIntegrationPaymentMethods: createAction(
        '@tvScreen/device/setIntegrationPaymentMethods',
        withPayloadType<Domain.IntegrationPaymentMethods>(),
    ),
};

export const deviceReducer = createReducer(initialState, builder =>
    builder
        .addCase(reducerActions.setDevice, (state, action) => {
            state.device = action.payload;
        })
        .addCase(reducerActions.addDeviceHiddenSlide, (state, action) => {
            if (state.device) {
                state.device = {
                    ...state.device,
                    hiddenSlides: [...(state.device.hiddenSlides ? state.device.hiddenSlides : []), action.payload],
                };
            }
        })
        .addCase(reducerActions.setBranch, (state, action) => {
            state.branch = action.payload;
        })
        .addCase(reducerActions.setSchedule, (state, action) => {
            state.schedule = action.payload;
        })
        .addCase(reducerActions.addSlideshow, (state, action) => {
            state.slideshows[action.payload.slideshowId] = action.payload;
        })
        .addCase(reducerActions.addTimeline, (state, action) => {
            state.timelines[action.payload.timelineId] = action.payload;
        })
        .addCase(reducerActions.addSlide, (state, action) => {
            state.slides = [
                ...(state.slides || []).filter(existingSlide => getSlideId(existingSlide) !== getSlideId(action.payload)),
                action.payload,
            ];
        })
        .addCase(reducerActions.addHiddenSlide, (state, action) => {
            state.hiddenSlides = [
                ...(state.hiddenSlides || []).filter(existingSlide => getSlideId(existingSlide) !== getSlideId(action.payload)),
                action.payload,
            ];
        })
        .addCase(reducerActions.setSlideMinute, (state, action) => {
            state.slideMinute = action.payload;
        })
        .addCase(reducerActions.setConfigurationIsInvalid, (state, action) => {
            state.configurationIsInvalid = action.payload;
        })
        .addCase(reducerActions.setConfiguredIntegrations, (state, action) => {
            state.configuredIntegrations = action.payload;
        })
        .addCase(reducerActions.setStock, (state, action) => {
            state.stock = action.payload;
        })
        .addCase(reducerActions.setIntegrationPaymentMethods, (state, action) => {
            state.integrationPaymentMethods = action.payload;
        }),
);

export const handleNewDeviceContentVersion =
    (newDeviceContentVersion: number): ThunkAction =>
    async (dispatch, getState) => {
        const cachedDeviceContentHash = browserStorage.getItem(DEVICE_CONTENT_HASH_KEY);
        const state = getState();
        const device = selectDevice(state);

        if (device) {
            await dispatch(checkDeviceContentHash(device.deviceId));
        }

        if (!cachedDeviceContentHash || cachedDeviceContentHash.value.indexOf(newDeviceContentVersion.toString()) === -1) {
            browserStorage.setItem(DEVICE_CONTENT_HASH_KEY, newDeviceContentVersion.toString());
            // we are checking if no one is interacting with the device outside this function
            window.location.reload();
        }
    };

export const checkDeviceContentHash =
    (deviceId: string): ThunkAction<Promise<false | number>> =>
    async () => {
        const device = await deviceApi.GetDeviceDetails(deviceId);
        const deviceContentHash = getDeviceContentHash(device);
        const cachedDeviceContentHash = browserStorage.getItem(DEVICE_CONTENT_HASH_KEY);

        if (!cachedDeviceContentHash || cachedDeviceContentHash.value !== deviceContentHash) {
            return parseInt(deviceContentHash, 10) || 0;
        }

        return false;
    };

export const checkDeviceSchedule = (): ThunkAction<Promise<boolean>> => async (_1, getState) => {
    const state = getState();
    const schedule = selectSchedule(state);

    if (!schedule) {
        return true;
    }

    const newSchedule = await scheduleApi.GetScheduleForDevice();

    if (JSON.stringify(schedule) !== JSON.stringify(newSchedule)) {
        return true;
    }

    return false;
};

export const pollDeviceContentHash =
    (deviceId: string): ThunkAction =>
    async (dispatch, getState) => {
        setTimeout(
            async () => {
                const newContentHashFound = await dispatch(checkDeviceContentHash(deviceId));
                if (newContentHashFound) {
                    const state = getState();
                    const hasBeenInteractedWith = interactionState.selectHasBeenInteractedWith(state);
                    const disableInactivityPeriod = interactionState.selectDisableInactivityPeriod(state);
                    if (!hasBeenInteractedWith && !disableInactivityPeriod) {
                        dispatch(handleNewDeviceContentVersion(newContentHashFound));
                    } else {
                        dispatch(interactionState.reducerActions.setContentVersionChangedDuringInteraction(newContentHashFound));
                    }
                    return;
                }

                dispatch(pollDeviceContentHash(deviceId));
            },
            1 * 60 * 60 * 1000,
        ); // one hour in milliseconds
    };

export const pollSchedule = (): ThunkAction => async (dispatch, getState) => {
    setTimeout(
        async () => {
            const newScheduleFound = await dispatch(checkDeviceSchedule());
            if (newScheduleFound) {
                const currentState = getState();
                const hasBeenInteractedWith = interactionState.selectHasBeenInteractedWith(currentState);
                const disableInactivityPeriod = interactionState.selectDisableInactivityPeriod(currentState);
                if (!hasBeenInteractedWith && !disableInactivityPeriod) {
                    window.location.reload();
                    return;
                }
            }

            dispatch(pollSchedule());
        },
        1 * 60 * 60 * 1000,
    ); // one hour in milliseconds
};

let deviceSlideshowMinuteTimer: ReturnType<typeof setTimeout>;
export const updateDeviceSlideshowMinute = (): ThunkAction => async dispatch => {
    let lastRunMinute = new Date().getMinutes();

    const run = async () => {
        const now = new Date();
        const minuteNow = now.getMinutes();
        if (lastRunMinute !== minuteNow) {
            await dispatch(reducerActions.setSlideMinute(now.getHours() * 60 + now.getMinutes()));
            lastRunMinute = minuteNow;
        }

        if (deviceSlideshowMinuteTimer) {
            clearTimeout(deviceSlideshowMinuteTimer);
        }

        deviceSlideshowMinuteTimer = setTimeout(async () => {
            run();
        }, 1000);
    };

    run();
};

const initializeVendingMachineState =
    (device: Domain.DeviceDetails): ThunkAction =>
    async dispatch => {
        await meditechDeviceApi.GetDeviceInformation();
        await dispatch(vendingMachineState.initializeVendingMachine(device));

        if (config.featureFlags.enableProductWallStockDisplayForBranches.includes(device.branchId)) {
            dispatch(refreshVisionStock());
        }
    };

let automaticRefreshInterval: ReturnType<typeof setInterval> | undefined;

const triggerAutomaticRefresh = (): ThunkAction => async (_1, getState) => {
    setDeviceLastAutomaticRefresh(Date.now());

    const tryRefresh = () => {
        const now = new Date();
        const ONE_HOUR = 1000 * 60 * 60;

        if ([0, 3, 5, 7, 10, 13, 18, 21].indexOf(now.getHours()) === -1) {
            console.info('AUTO_REFRESH: hour is ', now.getHours(), ', only refreshing if hour is [0, 3, 5, 7, 10, 13, 18, 21]');
            return;
        }

        const lastRefresh = getDeviceLastAutomaticRefresh();
        if (lastRefresh && Date.now() < lastRefresh + ONE_HOUR) {
            console.info('AUTO_REFRESH: not refreshing because last refresh was', (Date.now() - lastRefresh) / 1000 / 60, 'minutes ago');
            return;
        }

        const currentState = getState();
        const hasBeenInteractedWith = interactionState.selectHasBeenInteractedWith(currentState);
        const disableInactivityPeriod = interactionState.selectDisableInactivityPeriod(currentState);
        if (hasBeenInteractedWith || disableInactivityPeriod) {
            DeviceLog.cannotTriggerAutomaticRefresh('INTERACTION');
            console.info('AUTO_REFRESH: not refreshing because device is being interacted with');
            return;
        }

        console.info('AUTO_REFRESH: refreshing now!');
        DeviceLog.triggeredAutomaticRefresh();
        setDeviceLastAutomaticRefresh(Date.now());
        setTimeout(() => {
            window.location.reload();
        }, 3000);
    };

    clearInterval(automaticRefreshInterval);
    automaticRefreshInterval = setInterval(
        () => {
            tryRefresh();
        },
        1000 * 60 * 5,
    ); // every five minutes
};

let monitorDeviceBackendUsageInterval: ReturnType<typeof setInterval> | undefined;

const monitorResourceUsage = (): ThunkAction => async () => {
    const monitor = async () => {
        try {
            if (deviceBackendApi.monitorIsAvailable()) {
                const usage = await deviceBackendApi.getSystemUsage();
                DeviceLog.deviceBackendSystemUsage(usage);
            }
        } catch (e) {
            console.error(e);
        }
    };

    clearInterval(monitorDeviceBackendUsageInterval);
    monitor();
    monitorDeviceBackendUsageInterval = setInterval(
        () => {
            monitor();
        },
        1000 * 60 * 30,
    ); // every half hour
};

const refreshVisionStock = (): ThunkAction => async (dispatch, getState) => {
    const state = getState();
    const device = selectDevice(state);

    if (!device || !device.robotStockIsAvailable || device.type === 'vending-machine') {
        return;
    }

    const newStock: Stock = {};

    const allProducts = selectAllSlideshowProducts(state);
    if (!device.configuration.general?.simulateStock) {
        const allStock = await meditechDeviceApi.GetStockInformation();
        if (allStock.Success) {
            for (const product of allProducts) {
                for (const stock of allStock.Articles) {
                    if (productCodesMatchFlat(product.productCodes, [stock.ArticleCode])) {
                        newStock[product.productId] = parseInt(stock.Quantity) * 1;
                    }
                }
            }
        }

        meditechDeviceApi.StockEventEmitter.on(DeviceInterface.StockEvent.NeedsRefresh, () => {
            dispatch(refreshVisionStock());
        });
    } else {
        for (const product of allProducts) {
            newStock[product.productId] = Math.random() < 0.5 ? 0 : Math.round(Math.random() * 10);
        }
    }

    dispatch(reducerActions.setStock(newStock));
};

export const load = (): ThunkAction => async (dispatch, getState) => {
    const state = getState();
    const loggedInAs = selectLoggedInUser(state);
    const deviceId = loggedInAs.userId;
    const impersonatingMacAddress = getImpersonatingDeviceWithMacAddress();

    dispatch(triggerAutomaticRefresh());
    dispatch(monitorResourceUsage());

    DeviceLog.setDeviceId(deviceId);

    if (impersonatingMacAddress) {
        deviceSignalingApi.disable();
    }

    const [device, configuredIntegrations] = await Promise.all([
        dispatch(loadDeviceDetails(deviceId)),
        dispatch(loadConfiguredIntegrations()),
        dispatch(loadDeviceScheduleAndSlideshows()),
        !impersonatingMacAddress ? dispatch(SignInWithDeviceSignalingToken(deviceId)) : Promise.resolve(true),
    ]);

    if (device.connectedToLockers) {
        let configurationIsInvalid = true;
        if (configuredIntegrations && configuredIntegrations.length > 0) {
            const validIntegrations = configuredIntegrations.filter(
                integration =>
                    integration.activeStatus === 'active' &&
                    integration.integrationTypes.includes('locker') &&
                    integration.config?.deviceId === device.deviceId,
            );
            if (validIntegrations.length === 1) {
                configurationIsInvalid = false;
            }
        }
        dispatch(reducerActions.setConfigurationIsInvalid(configurationIsInvalid));
    }

    DeviceLog.setDeviceType(device.type);

    await dispatch(loadBranchDetails(device.branchId));
    const mtMaticPayConfiguredIntegrationId = device.configuration.mtMaticPayConfiguredIntegrationId;
    if (mtMaticPayConfiguredIntegrationId) {
        // no need to await this
        dispatch(loadIntegrationPaymentMethods(mtMaticPayConfiguredIntegrationId));
    }
    await dispatch(loadBranchDetails(device.branchId));

    DeviceLog.setCompanyId(device.companyId);
    DeviceLog.setBranchId(device.branchId);

    if (!getCurrentLocale()) {
        for (const locale of ALL_LOCALES) {
            if (device.companyLocales.indexOf(locale) >= 0) {
                dispatch(SetLocale(locale));
                break;
            }
        }
    }

    dispatch(pollDeviceContentHash(deviceId));
    dispatch(pollSchedule());
    dispatch(updateDeviceSlideshowMinute());

    dispatch(slideshowState.startSignaling());
    dispatch(slideshowState.startWatchingForSlideshowTimingChanges());

    dispatch(initializeVendingMachineState(device));
};

import { ProductImageSize } from './computeProductSize';

export default function computeMaxUsableScale(
    area: {
        width: number;
        height: number;
    },
    product: ProductImageSize,
    maxScale: number,
) {
    let scaleX = maxScale;
    let scaleY = maxScale;

    if (area.width < product.width * scaleX) {
        scaleX = area.width / product.width - 0.01;
    }

    if (area.height < product.height * scaleY) {
        scaleY = area.height / product.height - 0.01;
    }

    return Math.floor(Math.min(scaleX, scaleY) * 100) / 100;
}

import * as React from 'react';

import classNames from 'classnames';

import { Button, ButtonSize, ButtonVariant } from '@/button';
import { InputBase, InputBaseProps } from '@/core';

import styles from './ToggleButton.scss';

export type ControlledToggleButtonProps = InputBaseProps & {
    size?: ButtonSize;
    active?: boolean;
    onChange?: (isOn: boolean) => void;
    onMouseDown?: (event: React.MouseEvent) => void;
    forwardRef?: React.Ref<HTMLInputElement>;
    variant?: ButtonVariant;
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
};

export type ControlledToggleButtonPropsWithIsOn = ControlledToggleButtonProps & {
    isOn: boolean;
};

class ControlledToggleButton extends InputBase<ControlledToggleButtonPropsWithIsOn> {
    protected renderInput(): React.ReactNode {
        const { name, isOn, forwardRef, size, variant, disabled, style, active, onMouseDown } = this.props;

        return (
            <React.Fragment>
                <input
                    ref={forwardRef}
                    type="checkbox"
                    onChange={() => {
                        // do nothing
                    }}
                    name={name}
                    checked={isOn}
                    tabIndex={-1}
                />

                <Button
                    variantSize={size || 'l'}
                    disabled={disabled}
                    active={active}
                    onClick={this.handleButtonClick}
                    onMouseDown={onMouseDown}
                    variant={variant || 'tertiary'}
                    style={style}
                    startIcon={this.props.startIcon}
                    endIcon={this.props.endIcon}
                >
                    {this.props.children}
                </Button>
            </React.Fragment>
        );
    }

    private handleButtonClick = (event: React.MouseEvent): void => {
        event.preventDefault();

        const { isOn, onChange } = this.props;

        if (!onChange) {
            return;
        }

        onChange(!isOn);
    };

    protected getBaseWrapperComponent(): string {
        return 'div';
    }

    protected getClassName(): string {
        const { isOn } = this.props;

        return classNames(super.getClassName(), styles.ToggleButton, isOn ? styles.ToggleButtonOn : null);
    }
}

export default React.forwardRef<HTMLInputElement, ControlledToggleButtonPropsWithIsOn>((props, ref) => {
    return (
        <ControlledToggleButton
            forwardRef={ref}
            {...props}
        />
    );
});

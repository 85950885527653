import * as React from 'react';

import classNames from 'classnames';

import { Button } from '@/button';
import { BaseProps } from '@/core';
import { Icon } from '@/icon';
import { Select } from '@/select';
import { Tx } from '@/typography';

import styles from './Pagination.scss';

interface IBaseProps {
    maxVisiblePages?: number;
    hideInfoText?: boolean;
    total: number | null;
    totalIsLimited?: {
        at: number;
        displayedAs?: string;
        message: React.ReactNode;
    };
    perPage: number;
    variant?: 'contained' | 'plain';
    onChange?: (offset: number, page: number) => void;
    showPageSizeSwitcher?: {
        onPageSizeChange: (perPage: number) => void;
        availableSizes: {
            label: React.ReactNode;
            value: number;
        }[];
    };
}

interface OffsetBased {
    page?: never;
    offset: number;
}

interface PageBased {
    page: number;
    offset?: never;
}

export type PaginationProps = BaseProps & IBaseProps & (OffsetBased | PageBased);

export default class Pagination extends React.PureComponent<
    Omit<React.HTMLProps<HTMLDivElement>, 'onChange'> &
        PaginationProps & {
            gettext: (text: string) => string;
        }
> {
    render() {
        const {
            className,
            hideInfoText,
            children,
            maxVisiblePages,
            total,
            totalIsLimited,
            perPage,
            showPageSizeSwitcher,
            onChange,
            page,
            offset,
            gettext,
            ...rest
        } = this.props;

        return (
            <div
                {...rest}
                className={classNames(styles.Pagination, className)}
            >
                {total === null ? (
                    <span>{gettext ? gettext('Loading...') : null}</span>
                ) : total > 0 ? (
                    <span>
                        {this.renderPageSizeSwitcher()}
                        {this.renderInfoText()}
                        {this.renderGotoFirstPage()}
                        {this.renderGotoPrevPage()}
                        {this.renderGotoPageIndexes()}
                        {this.renderGotoNextPage()}
                        {this.renderGotoLastPage()}
                    </span>
                ) : (
                    <span>{gettext ? gettext('No results.') : null}</span>
                )}
            </div>
        );
    }

    private renderPageSizeSwitcher() {
        const { perPage, showPageSizeSwitcher } = this.props;

        if (!showPageSizeSwitcher) {
            return null;
        }

        return (
            <Select
                className="hidden-sm"
                value={perPage.toString()}
                onChange={(newSize: string) => showPageSizeSwitcher.onPageSizeChange(parseInt(newSize, 10))}
                options={showPageSizeSwitcher.availableSizes.map(item => {
                    return {
                        ...item,
                        value: item.value.toString(10),
                    };
                })}
            />
        );
    }

    private renderInfoText() {
        const { total, totalIsLimited, gettext, hideInfoText } = this.props;

        if (hideInfoText) {
            return null;
        }

        const from = this.getFrom().toString();
        const limitReached = totalIsLimited && total === totalIsLimited.at;
        const to = this.getTo().toString();
        const limitReachedMessage = limitReached ? totalIsLimited.message : null;
        const totalMessage = limitReached
            ? totalIsLimited.displayedAs
                ? totalIsLimited.displayedAs
                : total.toString() + '+'
            : (total || 0).toString();

        return (
            <span className={`${styles.PaginationInfoText} hidden-sm`}>
                {gettext ? (
                    <Tx
                        level="body-md"
                        sx={{
                            weight: 'light',
                            color: '--content-tertiary',
                        }}
                    >
                        {total !== null
                            ? gettext(':from: - :to: of :total:')
                                  .replace(':from:', from)
                                  .replace(':to:', to)
                                  .replace(':total:', totalMessage)
                            : gettext(':from: - :to:').replace(':from:', from).replace(':to:', to)}
                        {limitReachedMessage}
                    </Tx>
                ) : null}
            </span>
        );
    }

    private renderGotoFirstPage() {
        const disabled = this.getOffset() === 0;

        return (
            <Button
                startIcon={<Icon type="action_first_page" />}
                onClick={event => this.handleGotoOffset(event, 0)}
                tabIndex={disabled ? -1 : 0}
                className={classNames({
                    'hidden-sm': true,
                    disabled: disabled,
                })}
                variant={this.props.variant === 'plain' ? 'plain' : 'tertiary'}
                variantSize="m"
                data-test-id="pagination-action-first"
            />
        );
    }

    private renderGotoLastPage() {
        const lastPageOffset = this.getLastPageOffset();
        const disabled = this.props.total === null || this.getOffset() >= lastPageOffset;

        return (
            <Button
                startIcon={<Icon type="action_last_page" />}
                onClick={event => this.handleGotoOffset(event, lastPageOffset)}
                tabIndex={disabled ? -1 : 0}
                className={classNames({
                    'hidden-sm': true,
                    disabled: disabled,
                })}
                variant={this.props.variant === 'plain' ? 'plain' : 'tertiary'}
                variantSize="m"
                data-test-id="pagination-action-last"
            />
        );
    }

    private renderGotoPrevPage() {
        const { perPage } = this.props;
        const prevPageOffset = this.getOffset() - perPage;
        const disabled = prevPageOffset < 0;

        return (
            <Button
                startIcon={<Icon type="action_left" />}
                onClick={event => this.handleGotoOffset(event, prevPageOffset)}
                tabIndex={disabled ? -1 : 0}
                className={classNames({
                    [styles.PrevPage]: true,
                    disabled: disabled,
                })}
                variant={this.props.variant === 'plain' ? 'plain' : 'tertiary'}
                variantSize="m"
                data-test-id="pagination-action-prev"
            />
        );
    }

    private renderGotoNextPage() {
        const { perPage } = this.props;
        const nextPageOffset = this.getOffset() + perPage;
        const disabled = this.props.total === null || nextPageOffset > this.getLastPageOffset();

        return (
            <Button
                startIcon={<Icon type="action_right" />}
                onClick={event => this.handleGotoOffset(event, nextPageOffset)}
                tabIndex={disabled ? -1 : 0}
                className={classNames({
                    [styles.NextPage]: true,
                    disabled: disabled,
                })}
                variant={this.props.variant === 'plain' ? 'plain' : 'tertiary'}
                variantSize="m"
                data-test-id="pagination-action-next"
            />
        );
    }

    private renderGotoPageIndexes() {
        const { perPage, maxVisiblePages } = this.props;
        const total = this.props.total || 0;
        const currentOffset = this.getOffset();
        const lastOffset = this.getLastPageOffset();
        const pages: {
            offset: number;
            pageIndex: number;
            isEllipsis: boolean;
        }[] = [];
        let pagesBeforeCurrent = 0;
        let pagesAfterCurrent = 0;

        if (maxVisiblePages) {
            const halfOfPagesAround = Math.floor((maxVisiblePages - 2) / 2);
            pagesBeforeCurrent = Math.ceil(currentOffset / perPage);
            pagesAfterCurrent = Math.ceil((total - currentOffset) / perPage) - 1;
            pagesBeforeCurrent = Math.min(pagesBeforeCurrent, halfOfPagesAround);
            pagesAfterCurrent = Math.min(pagesAfterCurrent, halfOfPagesAround);

            if (pagesBeforeCurrent < halfOfPagesAround) {
                pagesAfterCurrent += halfOfPagesAround - pagesBeforeCurrent;
            } else if (pagesAfterCurrent < halfOfPagesAround) {
                pagesBeforeCurrent += halfOfPagesAround - pagesAfterCurrent;
            }

            if (currentOffset < Math.floor(maxVisiblePages / 2) * perPage) {
                pagesAfterCurrent += 1;
            } else if (currentOffset > this.getLastPageOffset() - Math.floor(maxVisiblePages / 2) * perPage) {
                pagesBeforeCurrent += 1;
            }
        }

        let pageIndex = 1;
        for (let offset = 0; offset < total; offset += perPage) {
            if (
                !maxVisiblePages ||
                offset === lastOffset ||
                offset === 0 ||
                offset === currentOffset ||
                (offset >= currentOffset - perPage * pagesBeforeCurrent && offset <= currentOffset + perPage * pagesAfterCurrent)
            ) {
                pages.push({ offset, pageIndex, isEllipsis: false });
            }

            pageIndex += 1;
        }

        if (pages.length > 2) {
            if (pages[0].pageIndex + 1 !== pages[1].pageIndex) {
                pages[1].isEllipsis = true;
            }

            if (pages[pages.length - 1].pageIndex - 1 !== pages[pages.length - 2].pageIndex) {
                pages[pages.length - 2].isEllipsis = true;
            }
        }

        return (
            <ol
                className={classNames({
                    [styles.PaginationPages]: true,
                    [styles.plain]: this.props.variant === 'plain',
                })}
            >
                {pages.map(page => (
                    <li key={page.offset}>
                        {page.isEllipsis ? (
                            <Tx
                                level="body-sm"
                                sx={{
                                    align: 'center',
                                }}
                                className={classNames({
                                    [styles.PaginationEllipsis]: true,
                                })}
                                as="span"
                            >
                                ...
                            </Tx>
                        ) : (
                            <Tx
                                level="body-sm"
                                sx={{
                                    weight: 'light',
                                }}
                                as="a"
                                href=""
                                className={classNames({
                                    active: this.getOffset() === page.offset,
                                })}
                                onClick={event => this.handleGotoOffset(event, page.offset)}
                                tabIndex={page.offset === this.getOffset() ? -1 : 0}
                                data-test-id={`pagination-action-index-${page.pageIndex}`}
                            >
                                <span>{page.pageIndex}</span>
                            </Tx>
                        )}
                    </li>
                ))}
            </ol>
        );
    }

    private handleGotoOffset(event: React.MouseEvent, offset: number): void {
        event.preventDefault();

        if (offset >= 0 && offset <= this.getLastPageOffset()) {
            this.handleChange(offset);
        }
    }

    private handleChange(offset: number): void {
        const { onChange, perPage } = this.props;

        if (onChange) {
            onChange(offset, Math.ceil(offset / perPage) + 1);
        }
    }

    private getFrom(): number {
        return this.getOffset() + 1;
    }

    private getTo(): number {
        const { total, perPage } = this.props;

        const to = this.getOffset() + perPage;

        if (total === null || to > total) {
            return total || 0;
        }

        return to;
    }

    private getOffset() {
        if (this.isOffsetBased(this.props)) {
            return this.props.offset;
        }

        return this.props.perPage * (this.props.page - 1);
    }

    private getLastPageOffset() {
        const { total, perPage } = this.props;
        return (Math.ceil(Math.max(total || 0, perPage) / perPage) - 1) * perPage;
    }

    private isOffsetBased(props: PaginationProps): props is PaginationProps & OffsetBased {
        return props.hasOwnProperty('offset');
    }
}

import { Domain } from 'api';
import { getScreenSizeInPX, getScreenSizeInMM } from 'utils';

// TV screen size in px: 1080W x 1920H
// TV screen size in mm: 680W  x 1200H

// New Matic screen size in px: 1280W x 962H
// New Matic screen size in mm: 400W  x 300H

interface ProductImageSize {
    width: number;
    height: number;
}

export default function computeProductSize(
    screenResolution: Domain.DeviceScreenResolution,
    availableProduct: Domain.SlideshowProduct,
    productImageSize: ProductImageSize,
): ProductImageSize {
    const screenShortEdgeInPX = getScreenSizeInPX(screenResolution).width;
    const screenShortEdgeInMM = getScreenSizeInMM(screenResolution).width;

    const largestDimensionInPX = Math.max(productImageSize.width, productImageSize.height);

    let largestDimensionInMM = Math.max(availableProduct.depth || 0, availableProduct.length || 0, availableProduct.width || 0);

    if (largestDimensionInMM === 0) {
        largestDimensionInMM = 90;
    }

    const screenDensity = screenShortEdgeInPX / screenShortEdgeInMM;
    const imageDensity = largestDimensionInPX / largestDimensionInMM;

    return {
        width: (productImageSize.width * screenDensity) / imageDensity,
        height: (productImageSize.height * screenDensity) / imageDensity,
    };
}

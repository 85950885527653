import { Domain } from 'api';

export function getScreenSizeInPX(screenResolution: Domain.DeviceScreenResolution): {
    width: number;
    height: number;
} {
    const parts = screenResolution.split('x');

    return {
        width: parseInt(parts[0]),
        height: parseInt(parts[1]),
    };
}

import * as React from 'react';

import { Domain } from 'api';
import { Canvas, CanvasFrame } from 'editor-canvas';
import { ISearchProvider } from 'utils';

import { getProductImages, getSelectedImage } from './utils';

export interface ProductImageSizes {
    [key: string]: {
        width: number;
        height: number;
    };
}

export interface TextStyle {
    font?: string;
    size?: number;
    color?: string;
    bold?: boolean;
    italic?: boolean;
    underline?: boolean;
    strikethrough?: boolean;
    align?: 'center' | 'justify' | 'left' | 'right';
    verticalAlign?: 'flex-start' | 'center' | 'flex-end';
}

export type PriceStyleShape = 'circle' | 'rect' | 'rect-top-left';

export interface PriceStyle {
    shape: PriceStyleShape;
    text: TextStyle;
    backgroundColor: string;
    borderColor: string;
}

export type ImageCrop = 'proportional-inside' | 'stretch-fit' | 'proportional-outside';

export type BackgroundType = 'color' | 'image' | 'video';

export interface TextFrame {
    type: 'text';
    text: string;
    textStyle: TextStyle;
    styleBackground: boolean;
    backgroundType: BackgroundType;
    backgroundColor?: string;
    backgroundMediaItemId?: string;
    backgroundImageCrop?: ImageCrop;
}

export interface RichTextFrame {
    type: 'richText';
    html: string;
    styleBackground: boolean;
    backgroundType: BackgroundType;
    backgroundColor?: string;
    backgroundMediaItemId?: string;
    backgroundImageCrop?: ImageCrop;
}

export type MediaFrame = {
    type: 'media';
} & (
    | {
          mediaType: 'image';
          mediaItemId?: string;
          prevImageMediaItemId?: string;
          imageCrop?: ImageCrop;
      }
    | {
          mediaType: 'video';
          mediaItemId?: string;
          prevVideoMediaItemId?: string;
          videoDuration?: number;
          imageCrop?: ImageCrop;
      }
);

export interface ShelfFrame {
    type: 'shelf';
    shelfStyle: '2D' | '3D';
}

export interface ProductsShelfAreaBox {
    key: number | string;
    left: number;
    bottom: number;
    width: number;
    height: number;
}

export interface ProductFrame {
    type: 'product';
    productId?: string;
    productMediaType: 'frontal' | 'packshot';
    imageId?: string;
    placedProduct: {
        enableItemsLimit?: boolean;
        itemsLimit?: number;
        alignItems?: 'left' | 'center' | 'right';
        scale?: number;
        spacingX?: number;
        spacingY?: number;
        showPrice?: boolean;
        hideOriginalPrice?: boolean;
        enableCustomPriceStyling?: boolean;
        customPriceStyle?: PriceStyle;
        customPromoPriceStyle?: PriceStyle & { promoText: TextStyle };
    };
}

export interface BackgroundFrame {
    type: 'background';
    backgroundType: BackgroundType;
    backgroundColor?: string;
    backgroundMediaItemId?: string;
    backgroundImageCrop?: ImageCrop;
}

export type HotSpotFrameHiddenSlideType = 'customPage' | 'productWall' | 'image' | 'video';

export type HotSpotFrame =
    | {
          type: 'hotSpot';
          hotSpotType: 'productDetails';
          productId?: string;
      }
    | {
          type: 'hotSpot';
          hotSpotType: 'hiddenSlide';
          hiddenSlideType: HotSpotFrameHiddenSlideType;
          hiddenSlideId?: string;
          hiddenSlideName?: string;
      };

export interface PharmacistOnDutyFrame {
    type: 'pharmacistOnDuty';
    textStyle: TextStyle;
    numPharmacists: number;
}

export interface OpeningHoursFrame {
    type: 'openingHours';
    textStyle: TextStyle;
    showDays: {
        Monday: boolean;
        Tuesday: boolean;
        Wednesday: boolean;
        Thursday: boolean;
        Friday: boolean;
        Saturday: boolean;
        Sunday: boolean;
    };
}

export type Frame = CanvasFrame &
    (
        | TextFrame
        | RichTextFrame
        | MediaFrame
        | ShelfFrame
        | ProductFrame
        | BackgroundFrame
        | HotSpotFrame
        | PharmacistOnDutyFrame
        | OpeningHoursFrame
    );

export type FrameWithMeta = Frame & {
    boxes?: ProductsShelfAreaBox[];
    product?: Domain.SlideshowProduct;
};

export type PageLayout = Canvas & {
    frames: Frame[];
    priceStyle: PriceStyle;
    promoPriceStyle: PriceStyle & { promoText: TextStyle };
    forceHideStockDisplay?: boolean;
};

export type ISearchProduct = {
    value: string;
    label: React.ReactNode;
} & Domain.SlideshowProduct;

export type ISearchProductProvider = ISearchProvider<ISearchProduct> & {
    byValueUnmapped: (selectedProductId: string) => Promise<Domain.SelectedProduct | undefined>;
    resetByValueCacheFor: (value: string) => void;
    setCategories: (categories: string[]) => void;
};

export interface SnapPoints {
    vertical: number[];
    horizontal: number[];
}

export type MediaItemPickerRenderer = (props: {
    value: string | undefined;
    onChange: (newValue: string | undefined, videoDuration?: number) => void;
    placeholder?: string;
    smallHeader?: boolean;
    type: Domain.MediaItemType | undefined;
    desiredMinimumResolution?: {
        width: number;
        height: number;
    };
}) => React.ReactNode;

export function hasEyedropCapableFrameMedia(frame: Frame): boolean {
    if (frame.type === 'background' && frame.backgroundType === 'image' && frame.backgroundMediaItemId) {
        return true;
    } else if (frame.type === 'media' && frame.mediaItemId && frame.mediaType === 'image') {
        return true;
    } else if ((frame.type === 'text' || frame.type === 'richText') && frame.backgroundType === 'image' && frame.backgroundMediaItemId) {
        return true;
    } else if (frame.type === 'product' && frame.productId) {
        return true;
    }

    return false;
}

export function getFrameMediaUrl(
    frame: Frame,
    availableProducts: Domain.SlideshowProduct[],
    getMediaItemURL: (mediaItemId: string) => string,
): string | undefined {
    if (frame.type === 'background' && frame.backgroundType === 'image' && frame.backgroundMediaItemId) {
        return getMediaItemURL(frame.backgroundMediaItemId);
    } else if (frame.type === 'media' && frame.mediaItemId) {
        return getMediaItemURL(frame.mediaItemId);
    } else if ((frame.type === 'text' || frame.type === 'richText') && frame.backgroundType === 'image' && frame.backgroundMediaItemId) {
        return getMediaItemURL(frame.backgroundMediaItemId);
    } else if (frame.type === 'product') {
        const product = availableProducts.find(searchedProduct => searchedProduct.productId === frame.productId);

        if (!product) {
            return;
        }

        const productImages = getProductImages(product);
        const selectedImage = getSelectedImage(productImages, frame.productMediaType, frame.imageId);

        if (selectedImage) {
            return selectedImage.url;
        } else if (frame.productMediaType === 'packshot') {
            return productImages.productImage;
        } else {
            return productImages.frontalImage;
        }
    }
}

export function getFrameMediaCrop(frame: Frame): ImageCrop | undefined {
    if (frame.type === 'background' && frame.backgroundType === 'image' && frame.backgroundMediaItemId) {
        return frame.backgroundImageCrop;
    } else if (frame.type === 'media' && frame.mediaItemId) {
        return frame.imageCrop;
    } else if ((frame.type === 'text' || frame.type === 'richText') && frame.backgroundType === 'image' && frame.backgroundMediaItemId) {
        return frame.backgroundImageCrop;
    }
}

import * as React from 'react';

import { ShelfWithMeta } from '../types';
import CustomShelfBody from './CustomShelfBody';
import ProductsShelfBody from './ProductsShelfBody';
import ShelfGFX from './ShelfGFX';

import styles from './styles/Canvas.scss';

export default function ShelfBody(props: {
    shelf: ShelfWithMeta;
    locale: string;
    proxyImageURL: (url: string) => string;
    prevShelf?: ShelfWithMeta;
    nextShelf?: ShelfWithMeta;
    hideMoverHandle?: boolean;
    disableEditing?: boolean;
    displayStock?: boolean;
    onMouseDown?: (shelfId: number, placedProductId: number) => void;
    onMouseUp?: (shelfId: number, placedProductId: number) => void;
    onMouseMove?: (shelfId: number, placedProductId: number, x: number, y: number, w: number, h: number) => void;
    onMouseOut?: () => void;
}) {
    const {
        shelf,
        prevShelf,
        nextShelf,
        hideMoverHandle,
        disableEditing,
        onMouseDown,
        onMouseUp,
        onMouseMove,
        onMouseOut,
        locale,
        proxyImageURL,
        displayStock,
    } = props;

    if (shelf.shelf.type === 'emptyShelf') {
        return (
            <React.Fragment>
                <div className={styles.EmptyShelf} />
                <ShelfGFX />
            </React.Fragment>
        );
    } else if (shelf.shelf.type === 'productsShelf') {
        return (
            <React.Fragment>
                <ProductsShelfBody
                    shelf={shelf}
                    prevShelf={prevShelf}
                    nextShelf={nextShelf}
                    hideMoverHandle={hideMoverHandle}
                    disableEditing={disableEditing}
                    displayStock={displayStock}
                    onMouseDown={placedProductId => (onMouseDown ? onMouseDown(shelf.shelf.id, placedProductId) : undefined)}
                    onMouseUp={placedProductId => (onMouseUp ? onMouseUp(shelf.shelf.id, placedProductId) : undefined)}
                    onMouseMove={(placedProductId, x, y, w, h) =>
                        onMouseMove ? onMouseMove(shelf.shelf.id, placedProductId, x, y, w, h) : undefined
                    }
                    onMouseOut={onMouseOut}
                    locale={locale}
                    proxyImageURL={proxyImageURL}
                />
                <ShelfGFX />
            </React.Fragment>
        );
    } else if (shelf.shelf.type === 'customShelf') {
        return (
            <CustomShelfBody
                shelf={shelf}
                prevShelf={prevShelf}
                nextShelf={nextShelf}
                hideMoverHandle={hideMoverHandle}
            />
        );
    }

    return null;
}

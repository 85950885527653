import * as React from 'react';

import classNames from 'classnames';

import { ControlledCheckboxBase, ControlledCheckboxProps } from '@/checkbox/ControlledCheckbox';
import { RadioGroupContext } from '@/radioGroup/RadioGroupContext';
import { Tx } from '@/typography';

import styles from './RadioButton.scss';
import checkboxStyles from '@/checkbox/Checkbox.scss';

interface RadioButtonProps {
    defaultChecked?: boolean;
}

class RadioButton extends ControlledCheckboxBase<RadioButtonProps> {
    static contextType = RadioGroupContext;
    declare context: React.ContextType<typeof RadioGroupContext>;

    protected renderInput(): React.ReactNode {
        const { name, children, defaultChecked, size } = this.props;
        return (
            <React.Fragment>
                <input
                    ref={this.inputRef}
                    type="radio"
                    name={name}
                    defaultChecked={defaultChecked}
                    checked={this.isChecked()}
                    onChange={this.handleChange}
                    tabIndex={-1}
                    disabled={this.isDisabled()}
                />

                <span className={styles.gfx}>
                    <span />
                </span>

                {children ? (
                    <Tx
                        className={checkboxStyles.CheckboxLabel}
                        level={size === 'l' ? 'body-md' : 'body-sm'}
                        as="span"
                        sx={{
                            disableSelection: true,
                        }}
                    >
                        {children}
                    </Tx>
                ) : null}
            </React.Fragment>
        );
    }

    protected handleChange = (): void => {
        const checkboxList = this.context;
        const { onChange, value } = this.props;

        if (checkboxList.name && checkboxList.onChange) {
            checkboxList.onChange(true, value);
        }

        if (onChange) {
            onChange(true);
        }
    };

    protected isChecked(): boolean | undefined {
        const checkboxList = this.context;

        if (checkboxList.name) {
            const { value } = this.props;
            return !!(value && checkboxList.value === value);
        }

        return undefined;
    }

    protected getClassName(): string {
        return classNames(super.getClassName(), styles.RadioButton);
    }
}

export default React.forwardRef<HTMLInputElement, ControlledCheckboxProps & RadioButtonProps>((props, ref) => {
    return (
        <RadioButton
            forwardRef={ref}
            checked={false}
            {...props}
        />
    );
});

import { LocalizedValue } from './Localisation';
import { Price } from './Price';
import { ProductCodes } from './ProductCode';

export interface SlideshowProductWithFrontalImage {
    frontalImage: string;
    allFrontals?: {
        id: string;
        url: string;
    }[];
}

export interface SlideshowProductWithProductImage {
    productImage: string;
    allPackshots?: {
        id: string;
        url: string;
    }[];
}

export interface SlideshowProductBase {
    productId: string;
    localizedNames?: LocalizedValue;
    price?: Price;
    robotStock?: number;
    promoPrice?: Price;
    width: number;
    length: number;
    depth: number;
    productCodes: ProductCodes;
}

export type SlideshowProduct = SlideshowProductBase &
    (
        | SlideshowProductWithFrontalImage
        | SlideshowProductWithProductImage
        | (SlideshowProductWithFrontalImage & SlideshowProductWithProductImage)
        | Record<string, unknown> // no images
    );

export function slideshowProductHasFrontalImage(
    product: SlideshowProduct,
): product is SlideshowProductBase & SlideshowProductWithFrontalImage {
    return product.hasOwnProperty('frontalImage') && !!(product as SlideshowProductWithFrontalImage).frontalImage;
}

export function slideshowProductHasProductImage(
    product: SlideshowProduct,
): product is SlideshowProductBase & SlideshowProductWithProductImage {
    return product.hasOwnProperty('productImage') && !!(product as SlideshowProductWithProductImage).productImage;
}

import * as React from 'react';

import { Domain } from 'api';
import { Canvas, CanvasFrame } from 'editor-canvas';
import { ISearchProvider } from 'utils';

export interface ProductImageSizes {
    [key: string]: {
        width: number;
        height: number;
    };
}

export interface ShelfWithMeta {
    shelf: Shelf;
    meta?: {
        maxBottom: number;
        placedProducts: {
            boxes: ProductsShelfAreaBox[];
            product: Domain.SlideshowProduct;
            area: PlacedProduct;
        }[];
    };
}

interface ShelfStyleParameters {
    shelfBottomOffset: number;
    shelfBaseHeight: number;
    minShelfHeight: number;
    minProductAreaWidth: number;
    productAreaPadding: number;
    minShelfHeightBeforeSplit: number;
    shelfHeight: number;
}

export const ShelfStyleParameters: {
    [key: string]: ShelfStyleParameters;
} = {
    style2D: {
        shelfBottomOffset: 23,
        shelfBaseHeight: 20,
        productAreaPadding: 4,
        minProductAreaWidth: 30,
        minShelfHeight: 60,
        minShelfHeightBeforeSplit: 149,
        shelfHeight: 45,
    },
    style3D: {
        shelfBottomOffset: 23,
        shelfBaseHeight: 20,
        productAreaPadding: 4,
        minProductAreaWidth: 30,
        minShelfHeight: 60,
        minShelfHeightBeforeSplit: 149,
        shelfHeight: 45,
    },
};

export interface ProductsShelfAreaBox {
    key: number | string;
    left: number;
    bottom: number;
    width: number;
    height: number;
}

export type PlacedProductAlignment = 'left' | 'center' | 'right';

export interface PlacedProductFillOptions {
    scale?: number;
    itemsLimit?: number;
    alignItems?: PlacedProductAlignment;
    spacingX?: number;
    spacingY?: number;
    imageId?: string;
    mediaItemId?: string;
    showPrice?: boolean;
    enableCustomPriceStyling?: boolean;
    customPriceStyle?: PriceLabelStyle;
    customPromoPriceStyle?: PriceLabelStyle & { promoText: TextStyle };
    hideOriginalPrice?: boolean;
}

export type AddProductToShelfPayload = {
    shelfId: number;
    product: Domain.SlideshowProduct;
    area: {
        x: number;
        width: number;
        height: number;
        dropAreaRect?: {
            left: number;
            top: number;
            height: number;
        };
    };
} & PlacedProductFillOptions;

export interface DropArea {
    x: number;
    width: number;
    tooSmall: boolean;
}

export interface TextStyle {
    font?: string;
    size?: number;
    color?: string;
    bold?: boolean;
    italic?: boolean;
    underline?: boolean;
    strikethrough?: boolean;
    align?: 'center' | 'justify' | 'left' | 'right';
    verticalAlign?: 'flex-start' | 'center' | 'flex-end';
}

export type PlacedProduct = {
    id: number;
    productId: string;
    x: number;
    width: number;
    height: number;
    enableItemsLimit?: boolean;
} & PlacedProductFillOptions;

export type ImageFrameCrop = 'proportional-inside' | 'stretch-fit';

type EmptyFrame = CanvasFrame & {
    type: 'none';
};

type TextFrame = CanvasFrame & {
    type: 'text';
    text?: string;
    textStyle: TextStyle;
};

type RichTextFrame = CanvasFrame & {
    type: 'richText';
    html?: string;
};

type ImageFrame = CanvasFrame & {
    type: 'image';
    mediaItemId?: string;
    prevImageMediaItemId?: string;
    imageCrop?: ImageFrameCrop;
};

type VideoFrame = CanvasFrame & {
    type: 'video';
    mediaItemId?: string;
    prevVideoMediaItemId?: string;
    videoDuration?: number;
};

type HotSpotFrame = CanvasFrame & {
    type: 'hotSpot';
    hotSpotType: 'productDetails';
    productId?: string;
};

export type Frame = EmptyFrame | TextFrame | RichTextFrame | ImageFrame | VideoFrame | HotSpotFrame;

interface BaseShelf {
    id: number;
    y: number;
    height: number;
}

type EmptyShelf = BaseShelf & {
    type: 'emptyShelf';
};

type CustomShelf = BaseShelf & {
    type: 'customShelf';
};

export type ProductsShelf = BaseShelf & {
    type: 'productsShelf';
    products: PlacedProduct[];
};

export type Shelf = EmptyShelf | CustomShelf | ProductsShelf;

export type ShelfStyle = 'style2D' | 'style3D';

export type LabelStyleShape = 'circle' | 'rect' | 'rect-top-left';

export interface PriceLabelStyle {
    shape: LabelStyleShape;
    text: TextStyle;
    backgroundColor: string;
    borderColor: string;
}

export interface StockLabelStyle {
    text: TextStyle;
    backgroundColor: string;
    borderColor: string;
}

export type WallBackgroundType = 'color' | 'image' | 'video';

export type Layout = Canvas & {
    shelves: Shelf[];
    frames: Frame[];
    style: ShelfStyle;
    backgroundType: WallBackgroundType;
    backgroundColor: string;
    headerColor?: string;
    backgroundMediaItemId: string;
    backgroundVideoDuration?: number;
    priceStyle: PriceLabelStyle;
    promoPriceStyle: PriceLabelStyle & { promoText: TextStyle };
    forceHideStockDisplay?: boolean;
    inStockStyle: StockLabelStyle;
    outOfStockStyle: StockLabelStyle;
};

export type MediaItemPickerRenderer = (props: {
    value: string | undefined;
    onChange: (newValue: string | undefined, videoDuration?: number) => void;
    placeholder?: string;
    smallHeader?: boolean;
    type: Domain.MediaItemType | undefined;
    desiredMinimumResolution?: {
        width: number;
        height: number;
    };
}) => React.ReactNode;

export function hasEyedropCapableFrameMedia(frame: Frame): boolean {
    if (frame.type === 'image' && frame.mediaItemId) {
        return true;
    }

    return false;
}

export function getFrameMediaUrl(frame: Frame, getMediaItemURL: (mediaItemId: string) => string): string | undefined {
    if (frame.type === 'image' && frame.mediaItemId) {
        return getMediaItemURL(frame.mediaItemId);
    } else if (frame.type === 'video' && frame.mediaItemId) {
        return getMediaItemURL(frame.mediaItemId);
    }
}

export function getFrameMediaCrop(frame: Frame): ImageFrameCrop | undefined {
    if (frame.type === 'image' && frame.mediaItemId) {
        return frame.imageCrop;
    }
}

export type ISearchProductProvider = ISearchProvider<ISearchProduct> & {
    byValueUnmapped: (selectedProductId: string) => Promise<Domain.SelectedProduct | undefined>;
    resetByValueCacheFor: (value: string) => void;
};

export type ISearchProduct = {
    value: string;
    label: React.ReactNode;
} & Domain.SlideshowProduct;

export type Stock = {
    [key: string]: number | undefined;
};

import * as React from 'react';
import { useSelector } from 'react-redux';

import { Domain } from 'api';
import { useThunkDispatch, useGettext, I18n, getLocalizedImages, productSelectionApi, TVScreen } from 'data-store';
import { Icon, Input, Container, Row, Col } from 'ui-components';

import Button from '@/components/Button';
import InteractiveScreenHeader from '@/components/InteractiveScreenHeader';
import InteractiveScreenNavigation from '@/components/InteractiveScreenNavigation';
import OnScreenKeyboard from '@/components/OnScreenKeyboard';

import styles from './Search.scss';
import screenStyles from '@/components/InteractiveScreen.scss';

export default function Search(props: {
    getProductTitle: (product: Domain.SelectedProduct) => React.ReactNode;
    renderProductImage: (image: Domain.ProductImage, asThumbnail?: boolean, key?: string) => React.ReactNode;
    getLanguageLabel: (locale: Domain.Locale) => React.ReactNode;
}) {
    const { getProductTitle, renderProductImage, getLanguageLabel } = props;
    const dispatch = useThunkDispatch();
    const { gettext } = useGettext();
    const locale = useSelector(I18n.selectCurrentLocale);
    const device = useSelector(TVScreen.selectors.selectDevice);
    const currentHistory = useSelector(TVScreen.interactionState.selectCurrentHistory);
    const accessibilityModeEnabled = useSelector(TVScreen.interactionState.selectAccessibilityModeEnabled);
    const inputRef = React.useRef<HTMLInputElement>();
    const [isInitLoad, setIsInitLoad] = React.useState(true);
    const [isSearching, setIsSearching] = React.useState(false);
    const [oskVisible, setOskVisible] = React.useState(
        currentHistory && currentHistory.type === 'search' && currentHistory.results && currentHistory.results.length > 0 ? false : true,
    );

    const handleFocus = () => {
        setOskVisible(true);
    };

    const handleBlur = () => {
        setTimeout(() => {
            setOskVisible(false);
        }, 100);
    };

    React.useEffect(() => {
        if (inputRef.current) {
            inputRef.current.addEventListener('focus', handleFocus);
            inputRef.current.addEventListener('blur', handleBlur);
        }

        return () => {
            if (inputRef.current) {
                inputRef.current.removeEventListener('focus', handleFocus);
                inputRef.current.removeEventListener('blur', handleBlur);
            }
        };
    }, [inputRef.current]);

    React.useEffect(() => {
        if (isInitLoad) {
            setIsInitLoad(false);
            return;
        }

        setTimeout(() => {
            setOskVisible(true);
        }, 500);
    }, [accessibilityModeEnabled]);

    React.useEffect(() => {
        if (isInitLoad) {
            setIsInitLoad(false);
            return;
        }
    }, []);

    if (!device || !currentHistory || currentHistory.type !== 'search') {
        return null;
    }

    const searchForm = (
        <div className={screenStyles.SearchForm}>
            {accessibilityModeEnabled && oskVisible ? <OnScreenKeyboard inputElement={inputRef.current} /> : null}

            <Input
                ref={inputRef as any}
                value={currentHistory.query}
                onChange={newQuery => dispatch(TVScreen.interactionState.reducerActions.setSearchQuery(newQuery))}
                placeholder={gettext('Search by name, CNK, etc.')}
                buttonRight={
                    <React.Fragment>
                        {currentHistory.query ? (
                            <Button
                                variant="plain"
                                onClick={() => dispatch(TVScreen.interactionState.reducerActions.setSearchQuery(''))}
                                startIcon={
                                    <Icon
                                        type="action_remove"
                                        iconSize="m"
                                    />
                                }
                            />
                        ) : null}

                        <Button
                            variant="primary"
                            disabled={!currentHistory.query || isSearching}
                            onClick={async () => {
                                setOskVisible(false);
                                setIsSearching(true);
                                dispatch(TVScreen.interactionState.reducerActions.setSearchResults(undefined));

                                const resultsPage = await productSelectionApi.GetSelectedProducts(
                                    { type: 'branch', ownerId: device.branchId },
                                    'inStoreProductSelection',
                                    { page: 1, size: 16 },
                                    locale,
                                    currentHistory.query,
                                );

                                if (resultsPage.items.length === 0) {
                                    setOskVisible(true);
                                }

                                dispatch(
                                    TVScreen.interactionState.reducerActions.setSearchResults(
                                        resultsPage.items.length === 0 ? undefined : resultsPage.items,
                                    ),
                                );

                                setIsSearching(false);
                            }}
                        >
                            {gettext('Search')}
                        </Button>
                    </React.Fragment>
                }
            />

            {!accessibilityModeEnabled && oskVisible ? <OnScreenKeyboard inputElement={inputRef.current} /> : null}
        </div>
    );

    const searchStatus = isSearching ? (
        <h1 className={screenStyles.SearchStatus}>{gettext('Searching...')}</h1>
    ) : currentHistory.results === undefined ? (
        <h1 className={screenStyles.SearchStatus}>{gettext('No results. Try a different query.')}</h1>
    ) : null;

    return (
        <div
            className={`${screenStyles.InteractiveScreen} ${styles.TVScreenSearch} ${accessibilityModeEnabled ? screenStyles.AccessibilityMode : ''}`}
        >
            {!accessibilityModeEnabled ? (
                <React.Fragment>
                    <InteractiveScreenHeader />
                    <h1 className={screenStyles.ScreenTitle}>{gettext('Products search')}</h1>
                    {searchForm}
                    {searchStatus}
                </React.Fragment>
            ) : null}

            <Container
                gutter={24}
                className={`${screenStyles.SearchResults} ${oskVisible ? screenStyles.WithOSK : ''}`}
            >
                <Row wrap={true}>
                    {currentHistory.results
                        ? currentHistory.results.map(productDetails => {
                              const images = getLocalizedImages(productDetails.images.concat(productDetails.frontals), locale);

                              return (
                                  <Col key={productDetails.productId}>
                                      <a
                                          className={screenStyles.SearchResult}
                                          href=""
                                          onClick={e => {
                                              e.preventDefault();
                                              dispatch(
                                                  TVScreen.interactionState.reducerActions.pushToHistory({
                                                      type: 'productDetails',
                                                      productDetails,
                                                  }),
                                              );
                                          }}
                                      >
                                          <span className={screenStyles.SearchResultImage}>
                                              {images.length > 0 ? renderProductImage(images[0], true) : null}
                                          </span>

                                          <span className={screenStyles.SearchResultTitle}>{getProductTitle(productDetails)}</span>
                                      </a>
                                  </Col>
                              );
                          })
                        : undefined}
                </Row>
            </Container>

            {accessibilityModeEnabled ? (
                <React.Fragment>
                    {searchStatus}
                    {searchForm}
                    <InteractiveScreenHeader />
                    <h1 className={screenStyles.ScreenTitle}>{gettext('Products search')}</h1>
                </React.Fragment>
            ) : null}

            <InteractiveScreenNavigation getLanguageLabel={getLanguageLabel} />
        </div>
    );
}

import * as React from 'react';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';

import { Infrastructure } from 'api';
import {
    useThunkDispatch,
    Route,
    BaseRoute,
    DeviceRoute,
    BrowsershotRoute,
    RedirectRoute,
    useQuery,
    IsAuthorizedAction,
    Authentication,
    I18n,
    Localisation,
    Webshop,
    Device,
    PlatformNotification,
    DarkMode,
    CmsItem,
    store,
} from 'data-store';
import { AppCoreContext } from 'ui-components';
import { useParams } from 'utils';

import { AppRouteErrorData } from './types';

interface INavigate {
    to: string;
}

interface AppRouteDataPreloadReader {
    read: () => React.ComponentType | INavigate;
}

function isRedirectRoute(route: Route): route is BaseRoute & RedirectRoute {
    return (route as RedirectRoute).hasOwnProperty('redirect');
}

function isDeviceRoute(route: Route): route is BaseRoute & DeviceRoute {
    return (route as DeviceRoute).hasOwnProperty('isDevice') && (route as DeviceRoute).isDevice;
}

function isBrowsershotRoute(route: Route): route is BaseRoute & BrowsershotRoute {
    return (route as DeviceRoute).hasOwnProperty('isBrowsershot') && (route as BrowsershotRoute).isBrowsershot;
}

function isINavigate(preloadedResource: React.ComponentType | INavigate): preloadedResource is INavigate {
    return preloadedResource && preloadedResource.hasOwnProperty('to');
}

function isListOfAuthorizers(authorizers: Route['isAuthorized']): authorizers is IsAuthorizedAction[] {
    return Array.isArray(authorizers);
}

export default function AppRoute({ route }: { route: Route }) {
    const appCoreContext = React.useContext(AppCoreContext);
    const navigate = useNavigate();
    const dispatch = useThunkDispatch();
    const urlParams = useParams();
    const urlQuery = useQuery();
    const location = useLocation();
    const oldLocation = React.useRef(location);
    const preloadResource = React.useRef<ReturnType<typeof wrapPromiseInSuspense>>();

    if (!(store as any).getNavigate) {
        (store as any).getNavigate = () => navigate;
    }

    React.useEffect(() => {
        appCoreContext.handleLocationChange(location, oldLocation.current);
    }, [location]);

    if (isRedirectRoute(route)) {
        navigate(route.redirect);
        return null;
    }

    const initPromise = async () => {
        if (location.pathname.indexOf('/error') === -1) {
            await dispatch(Localisation.availableCountriesState.load());
        }

        try {
            if (isDeviceRoute(route)) {
                await dispatch(Authentication.SignInWithDeviceToken());
            } else if (isBrowsershotRoute(route)) {
                const authToken = urlQuery.get('auth');
                if (!authToken) {
                    throw new Error('auth query param not found in URL');
                }
                await dispatch(Authentication.SignInWithBrowsershotToken(authToken));
            } else {
                await dispatch(Authentication.SignInFromSavedToken());
            }
        } catch (e) {
            if (e instanceof Authentication.InvalidMacAddressError) {
                console.warn(e);
                if (location.pathname !== '/error/invalid-device-configuration') {
                    return { to: '/error/invalid-device-configuration' };
                }
            } else {
                throw e;
            }
        }

        const isAuthorizedResults = await dispatch((_1, getState) => {
            const state = getState();
            if (!isListOfAuthorizers(route.isAuthorized)) {
                route.isAuthorized = [route.isAuthorized];
            }
            return route.isAuthorized.map(authorizer => authorizer(state));
        });

        const isAuthorized = isAuthorizedResults.indexOf(true) > -1;
        // if the user is not authorized to access this route
        //   then we redirect to the login page
        if (location.pathname !== '/' && !isAuthorized) {
            return { to: '/?back=' + encodeURIComponent(window.location.href) };
        }

        if (!isDeviceRoute(route) && !isBrowsershotRoute(route) && route.path !== '*') {
            await Promise.all([
                dispatch(Webshop.globalSelectionState.InitGlobalSelectedWebshopId()),
                dispatch(Device.globalSelectionState.InitGlobalSelectedDeviceId()),
                dispatch(PlatformNotification.actions.PollUserUnreadNotificationsCount()),
                dispatch(CmsItem.publicAnnouncementsOverviewState.load()),
            ]);

            if (route.path === '/dashboard/analytics') {
                await dispatch(DarkMode.SetDarkMode(true));
            } else {
                await dispatch(DarkMode.SetDarkMode(false));
            }
        }

        const allPromises = [];

        if (location.pathname.indexOf('/error') === -1) {
            allPromises.push(dispatch(I18n.LoadTranslations()));
        }

        allPromises.push(dispatch(I18n.InitMomentLocale()));

        if (route.preloadData) {
            allPromises.push(dispatch(route.preloadData({ urlParams, urlQuery })));
        }

        // delay so appCoreContext.handleLocationChangeComplete is always called after appCoreContext.handleLocationChange
        allPromises.push(
            new Promise(resolve => {
                setTimeout(resolve, 100);
            }),
        );

        // this has to be last always since it loads the component to render and we're fetching it below
        allPromises.push(route.main());

        try {
            const res = await Promise.all(allPromises);

            appCoreContext.handleLocationChangeComplete(location);

            const lastPromiseResult = res[res.length - 1] as any;
            if (lastPromiseResult.default) {
                return lastPromiseResult.default;
            }

            return lastPromiseResult;
        } catch (e) {
            appCoreContext.handleLocationChangeComplete(location);

            // if any API call in `route.preloadData` returns a 401 status
            //   then the token is no longer valid and we sign out
            if (e instanceof Infrastructure.Api.HttpApiException && e.status === 401) {
                await dispatch(Authentication.SignOut());
                if (location.pathname !== '/') {
                    return { to: '/' };
                }
            }
            //  if any API call in `route.preloadData` returns a status other than 200 or otherwise throws and error
            //    then we display the error page
            else {
                // tslint:disable-next-line:no-console
                console.error(e);

                if (e.name === 'ChunkLoadError' || e.code === 'CSS_CHUNK_LOAD_FAILED') {
                    window.location.reload();
                }

                const errorData: AppRouteErrorData = {
                    message: e.message ? e.message.substring(0, 1000) : e.toString(),
                };

                if (e instanceof Infrastructure.Api.HttpApiException) {
                    errorData.status = e.status;
                    errorData.statusText = e.text;
                }

                const errorParam = encodeURIComponent(JSON.stringify(errorData));
                return { to: '/error/' + errorParam };
            }
        }

        return (route.main as any).default;
    };

    if (!preloadResource.current || locationKey(oldLocation.current) !== locationKey(location)) {
        oldLocation.current = location;
        preloadResource.current = wrapPromiseInSuspense(initPromise());
    }

    return (
        <React.Suspense fallback={route.preloading ? <route.preloading /> : <span />}>
            <AppRouteDataPreloader preloadResource={preloadResource.current} />
        </React.Suspense>
    );
}

const locationKey = (location: ReturnType<typeof useLocation>) => {
    return location.pathname + location.search + location.hash + location.key;
};

function AppRouteDataPreloader(props: { preloadResource: AppRouteDataPreloadReader }) {
    const RouteMain = props.preloadResource.read();

    if (RouteMain === undefined) {
        return (
            <Navigate
                to="/error/unauthorized"
                replace
            />
        );
    }

    if (isINavigate(RouteMain)) {
        return (
            <Navigate
                to={RouteMain.to}
                replace
            />
        );
    }

    return <RouteMain />;
}

function wrapPromiseInSuspense(promise: Promise<any>): AppRouteDataPreloadReader {
    let promiseStatus = 'pending';
    let promiseResult: any;

    const suspender = promise.then(
        result => {
            promiseStatus = 'success';
            promiseResult = result;
        },
        error => {
            promiseStatus = 'error';
            promiseResult = error;
        },
    );

    return {
        read() {
            if (promiseStatus === 'pending') {
                throw suspender;
            } else if (promiseStatus === 'error') {
                throw promiseResult;
            } else if (promiseStatus === 'success') {
                return promiseResult;
            }
        },
    };
}

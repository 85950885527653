import { combineReducers } from '@reduxjs/toolkit';

import { overviewReducer } from './overviewState';
import { pageReducer } from './pageState';
import { publicAnnouncementsOverviewReducer } from './publicAnnouncementsOverviewState';
import { publicCmsTagsOverviewReducer } from './publicCmsTagsState';
import { updateReducer } from './updateState';

export const cmsItemReducer = combineReducers({
    overview: overviewReducer,
    publicAnnouncementsOverview: publicAnnouncementsOverviewReducer,
    publicCmsTagsOverviewReducer: publicCmsTagsOverviewReducer,
    update: updateReducer,
    page: pageReducer,
});

import * as React from 'react';

import classNames from 'classnames';

import { store } from '../state';
import { Frame } from '../types';

import styles from './styles/TouchTargets.scss';

export default function TouchTargets(props: {
    className?: string;
    style?: React.CSSProperties;
    scaleTo?: {
        width: number;
        height: number;
    };
    onProductClick: (productId: string) => void;
    onHiddenSlideClick: (slideId: string) => void;
}) {
    const { scaleTo, onProductClick, onHiddenSlideClick } = props;
    const { state } = React.useContext(store);
    const scaleFactor = scaleTo ? scaleTo.width / state.canvas.width : 1;

    return (
        <div
            className={classNames(props.className, styles.TouchTargets)}
            style={{
                ...props.style,
                transform: 'scale(' + scaleFactor + ')',
                width: (scaleTo ? scaleTo.width : state.canvas.width) + 'px',
                height: (scaleTo ? scaleTo.height : state.canvas.height) + 'px',
            }}
        >
            <div
                className={classNames(styles.Body, state.screenResolution)}
                style={{
                    width: state.canvas.width + 'px',
                    height: state.canvas.height + 'px',
                }}
            >
                {state.canvas.frames.map((frame: Frame) => {
                    if (frame.type !== 'product' && frame.type !== 'hotSpot') {
                        return null;
                    }

                    return (
                        <a
                            key={frame.frameId}
                            className={styles.PlacedProduct}
                            style={{
                                top: frame.y + 'px',
                                left: frame.x + 'px',
                                height: frame.height + 'px',
                                width: frame.width + 'px',
                                zIndex: (frame.depth || 0) + 999,
                            }}
                            onClick={e => {
                                e.preventDefault();

                                if (frame.type === 'product' && frame.productId) {
                                    onProductClick(frame.productId);
                                } else if (frame.type === 'hotSpot' && frame.hotSpotType === 'productDetails' && frame.productId) {
                                    onProductClick(frame.productId);
                                } else if (
                                    frame.type === 'hotSpot' &&
                                    frame.hotSpotType === 'hiddenSlide' &&
                                    frame.hiddenSlideType &&
                                    frame.hiddenSlideId
                                ) {
                                    onHiddenSlideClick(frame.hiddenSlideId);
                                }
                            }}
                        >
                            &nbsp;
                        </a>
                    );
                })}
            </div>
        </div>
    );
}

import * as React from 'react';
import RichTextEditor, { EditorValue } from 'react-rte';

import { DraftInlineStyle, Editor as DraftEditor, ContentBlock } from 'draft-js';
// @ts-ignore
import { getTextAlignClassName } from 'react-rte/lib/lib/blockStyleFunctions';

// @ts-ignore
import { HTMLEditorValue } from 'utils';

import { InputBase, InputBaseProps, DOM } from '@/core';

import { insertBlockAfter } from './draftjs-utils/block';
import { HTMLEditorContext } from './HTMLEditorContext';
import { getToolbarConfig, ToolbarOptions } from './HTMLEditorToolbar';

import styles from './HTMLEditor.scss';

export type ControlledHTMLEditorProps = InputBaseProps & {
    placeholder?: string;
    forwardRef?: React.Ref<HTMLInputElement | null>;
    editorRef?: (editor: RichTextEditor | null) => void;
    onChange?: (newValue: EditorValue) => void;
    onChangeStart?: () => void;
    gettext: (text: string) => string;
    hideBuiltInToolbar?: boolean;
    __dangerouslyUseInternalCache?: boolean;
    toolbarOptions?: ToolbarOptions;
    onClick?: () => void;
};

export type ControlledHTMLEditorWithValue = ControlledHTMLEditorProps & {
    value: EditorValue;
};

interface IState {
    changeInProgress: boolean;
    cachedValue: HTMLEditorValue;
}

function Editor(
    props: RichTextEditor['props'] & {
        customStyleFn: DraftEditor['props']['customStyleFn'];
        forwardRef: React.RefCallback<any>;
    },
) {
    const { forwardRef, ...rest } = props;

    return (
        <RichTextEditor
            ref={forwardRef}
            {...rest}
        />
    );
}

class ControlledHTMLEditor extends InputBase<ControlledHTMLEditorWithValue, IState> {
    static contextType = HTMLEditorContext;
    declare context: React.ContextType<typeof HTMLEditorContext>;

    private changeTimeout: ReturnType<typeof setTimeout> | undefined = undefined;
    private editor = React.createRef<{
        _focus?: () => void;
    }>();

    constructor(props: ControlledHTMLEditorWithValue) {
        super(props);

        this.state = {
            changeInProgress: false,
            cachedValue: props.value,
        };
    }

    componentDidMount() {
        this.context.set({
            editorState: this.state.cachedValue.getEditorState(),
            editorValueSetter: this.setEditorValue.bind(this),
        });
    }

    protected getBaseWrapperComponent(): string {
        return 'div';
    }

    protected getOtherBaseWrapperProps() {
        return {
            onClick: this.props.onClick,
        };
    }

    protected getClassName() {
        return super.getClassName() + ' RTEditor';
    }

    private setEditorValue(newValue: EditorValue) {
        if (newValue) {
            this.setState({
                cachedValue: newValue,
            });
        }
    }

    private getValue() {
        if (this.props.__dangerouslyUseInternalCache) {
            return this.state.cachedValue;
        }

        return this.props.value;
    }

    protected renderInput(): React.ReactNode {
        const { name, disabled, forwardRef, placeholder, gettext, hideBuiltInToolbar, toolbarOptions } = this.props;
        const value = this.getValue();

        return (
            <React.Fragment>
                <input
                    ref={forwardRef}
                    type="hidden"
                    name={name}
                    value={HTMLEditorValue.toString(value)}
                    disabled={disabled}
                />

                <Editor
                    forwardRef={(editor: any) => {
                        (this.editor as any).current = editor;
                        if (this.props.editorRef) {
                            this.props.editorRef(editor);
                        }
                    }}
                    value={value}
                    onChange={newValue => {
                        this.context.set({
                            ...this.context,
                            editorState: newValue.getEditorState(),
                        });
                        this.handleChange(newValue);
                    }}
                    toolbarConfig={{
                        display: [],
                        INLINE_STYLE_BUTTONS: [],
                        BLOCK_ALIGNMENT_BUTTONS: [],
                        BLOCK_TYPE_DROPDOWN: [],
                        BLOCK_TYPE_BUTTONS: [],
                    }}
                    disabled={disabled}
                    placeholder={placeholder}
                    className={styles.HTMLEditor + ' Input'}
                    blockStyleFn={block => `${getTextAlignClassName(block)} ${this.getListItemColorClassName(block)}`}
                    customStyleFn={this.getTextStyle}
                    customControls={
                        !hideBuiltInToolbar
                            ? getToolbarConfig({ gettext, refocus: this.refocus, handleChange: this.handleChange, ...toolbarOptions })
                            : undefined
                    }
                    autoFocus={false}
                    handleReturn={this.handleReturn}
                />
            </React.Fragment>
        );
    }

    private handleReturn = (): boolean => {
        const { editorState } = this.context;

        const selection = editorState.getSelection();
        const contentState = editorState.getCurrentContent();
        const blockStartKey = selection.getStartKey();
        const block = contentState.getBlockMap().get(blockStartKey);

        if ((block.getType() === 'unordered-list-item' || block.getType() === 'ordered-list-item') && block.getText().length > 0) {
            const newState = insertBlockAfter(editorState, block.getKey(), block.getType(), block.getData());
            this.context.set({
                ...this.context,
                editorState: newState,
            });
            this.handleChange(EditorValue.createFromState(newState));
            return true;
        }

        return false;
    };

    private getListItemColorClassName = (block: ContentBlock): string => {
        const bulletColor = block.getData().toJS().bulletColor;
        if (bulletColor) {
            const className = 'bullet-color-' + bulletColor.replace(/[#\s,()]/g, '');

            DOM.injectStyle(
                `
                .${className} {
                   color: ${bulletColor};
                }
            `,
                className,
            );

            return className;
        }
        return '';
    };

    private getTextStyle = (styles: DraftInlineStyle): React.CSSProperties => {
        let fontFamily = '';
        let fontSize = '';
        let textColor = '';

        const fontFamilyStyle = styles.find(style => {
            if (style) {
                return style.indexOf('FONT_FAMILY:') === 0;
            }

            return false;
        });

        const fontSizeStyle = styles.find(style => {
            if (style) {
                return style.indexOf('FONT_SIZE:') === 0;
            }

            return false;
        });

        const textColorStyle = styles.find(style => {
            if (style) {
                return style.indexOf('TEXT_COLOR:') === 0;
            }

            return false;
        });

        if (fontFamilyStyle) {
            fontFamily = fontFamilyStyle.replace('FONT_FAMILY:', '');
        }

        if (fontSizeStyle) {
            fontSize = fontSizeStyle.replace('FONT_SIZE:', '') + 'px';
        }

        if (textColorStyle) {
            textColor = textColorStyle.replace('TEXT_COLOR:', '');
        }

        return {
            fontFamily,
            fontSize,
            color: textColor,
        };
    };

    private handleChange = (value: EditorValue): void => {
        this.setState({
            cachedValue: value,
        });

        if (this.props.onChangeStart) {
            if (this.changeTimeout) {
                clearTimeout(this.changeTimeout);
            }

            this.changeTimeout = setTimeout(() => {
                this.setState({
                    changeInProgress: false,
                });
            }, 500);

            if (!this.state.changeInProgress) {
                this.setState({
                    changeInProgress: true,
                });

                this.props.onChangeStart();
            }
        }

        if (this.props.onChange) {
            this.props.onChange(value);
        }
    };

    private refocus = () => {
        setTimeout(() => {
            if (this.editor && this.editor.current && this.editor.current._focus) {
                this.editor.current._focus();
            }
        }, 50);
    };
}

export default React.forwardRef<HTMLInputElement, ControlledHTMLEditorWithValue>((props, ref) => {
    return (
        <ControlledHTMLEditor
            forwardRef={ref}
            {...props}
        />
    );
});

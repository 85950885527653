import { Pagination } from './Pagination';
import { ProductSelectionType } from './ProductSelection';
import { Sorting } from './Sorting';

interface KeyedRow {
    [key: string]: string | number | null;
}

type UnkeyedRow = (string | number | null)[];

export type ProductImportPreview = KeyedRow[] | UnkeyedRow[];

export function isKeyedRowPreview(row: ProductImportPreview[number]): row is KeyedRow {
    return !Array.isArray(row);
}

export interface ProductImportPreviewPayload {
    csv: string;
    hasHeaderRow: boolean;
    delimiter: string;
    pagination: Pagination;
}

export interface InitializeCsvImportPayload {
    companyId: string;
    branchId: string;
    base64EncodesCsv: string;
    removePreviousStockImport: boolean;
    hasHeaderRow: boolean;
    delimiter: string;
    pricesAreInCents: boolean;
    priceDecimalSeparator?: string;
    priceThousandsSeparator?: string;
    productIdentifierColumnNumber: number;
    stockQuantityColumnNumber?: number;
    priceColumnNumber?: number;
    promotionalPriceColumnNumber?: number;
    vatRateColumnNumber?: number;
    priceSelectionTypes: ProductSelectionType[];
}

export interface ImportError {
    importErrorId: string;
    importId: string;
    lineNumber: number;
    message: string;
}

export type ImportStatus = 'initialized' | 'inProgress' | 'complete';

export interface Import {
    importId: string;
    initializedOn: string;
    progressPercentage: number;
    initializedByUserId?: string;
    errorCount: number;
    status: ImportStatus;
}

export type ImportDetails = Import;

export interface ImportsPage {
    items: Import[];
    total: number;
    pagination: Pagination;
    sorting?: Sorting<keyof Import> | null;
    search?: string | null;
}

export interface ImportErrorsPage {
    items: ImportError[];
    total: number;
    pagination: Pagination;
    sorting?: Sorting<keyof ImportError> | null;
    search?: string | null;
}

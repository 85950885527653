import * as React from 'react';

import moment from 'moment';

import { CalendarDay } from './CalendarDay';

import styles from './DatePicker.scss';

interface Range {
    from: moment.Moment;
    to: moment.Moment;
}

export const Calendar = React.memo(
    (props: {
        selectedDate?: moment.Moment;
        selection?: Range;
        secondarySelection?: Range;
        onChange?: (selectedDate: moment.Moment) => void;
        onHeaderClick?: (visibleMonth: moment.Moment) => void;
        visibleMonth?: moment.Moment;
        min?: moment.Moment;
        max?: moment.Moment;
        availableDates?: moment.Moment[];
        autoFocusedDay?: React.Ref<any>;
        selectedClassName?: string;
        secondarySelectedClassName?: string;
        overlappingSelectedClassName?: string;
    }) => {
        const visibleMonth = props.visibleMonth || moment();
        const weekDays = getWeekDays();
        const monthDays = getDaysOfMonth(visibleMonth);
        const weekDayIndexes = [0, 1, 2, 3, 4, 5, 6];

        let firstDay = true;

        return (
            <div className={styles.DatePickerCalendar}>
                <div className={styles.DatePickerCalendarHeader}>
                    <span
                        className={props.onHeaderClick ? styles.DatePickerCalendarHeaderClickable : ''}
                        onClick={() => {
                            if (props.onHeaderClick) {
                                props.onHeaderClick(visibleMonth);
                            }
                        }}
                    >
                        {visibleMonth.format('MMMM YYYY')}
                    </span>
                </div>

                <table className={styles.DatePickerCalendarBody}>
                    <thead>
                        <tr>
                            {weekDays.map((day, index) => (
                                <th key={day + '-' + index}>{day}</th>
                            ))}
                        </tr>
                    </thead>

                    <tbody>
                        {monthDays.map(week => (
                            <tr key={week.weekIndex}>
                                {weekDayIndexes.map(d => {
                                    if (typeof week.days[d] !== 'undefined') {
                                        const day = (
                                            <CalendarDay
                                                key={week.days[d].format('YY-MM-DD')}
                                                date={week.days[d]}
                                                min={props.min}
                                                max={props.max}
                                                availableDates={props.availableDates}
                                                selectedDate={props.selectedDate}
                                                selectedClassName={props.selectedClassName}
                                                secondarySelectedClassName={props.secondarySelectedClassName}
                                                selection={props.selection}
                                                secondarySelection={props.secondarySelection}
                                                onChange={props.onChange}
                                                autoFocusRef={firstDay ? props.autoFocusedDay : undefined}
                                                overlappingSelectedClassName={props.overlappingSelectedClassName}
                                            />
                                        );
                                        firstDay = false;
                                        return day;
                                    } else {
                                        return (
                                            <td
                                                key={week.weekIndex + '-' + d}
                                                className={styles.DatePickerCalendarNoDay}
                                            />
                                        );
                                    }
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    },
);

function getWeekDays(): string[] {
    const start = moment().startOf('isoWeek');
    const days = [];
    for (let i = 0; i < 7; i += 1) {
        days.push(start.format('dd').toLowerCase());
        start.add('1', 'day');
    }

    return days;
}

interface DaysOfWeek {
    weekIndex: string;
    days: moment.Moment[];
}

function getDaysOfMonth(crtMonth: moment.Moment): DaysOfWeek[] {
    const crtDate = crtMonth.clone().startOf('month');
    const rows: {
        [key: string]: moment.Moment[];
    } = {};

    while (crtDate.month() === crtMonth.month()) {
        const weekIndex = crtDate.year() + '-' + crtDate.isoWeek();
        if (!rows[weekIndex]) {
            rows[weekIndex] = [];
        }
        rows[weekIndex][crtDate.isoWeekday() - 1] = crtDate.clone();

        crtDate.add(1, 'days');
    }

    const days: DaysOfWeek[] = [];

    for (const weekIndex in rows) {
        days.push({
            weekIndex,
            days: rows[weekIndex],
        });
    }

    return days;
}

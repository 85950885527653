import { PlcStatus } from './MeditechDevicePlc';
import { ValinaTerminalStatus } from './ValinaTerminalLocalcomm';


export type keyMessageCallback = {
    [key: string]: any
}

export interface LocalcommStatus {
    PLC?: PlcStatus
    valinaTerminalLocalcomm?: ValinaTerminalStatus
    scannerIsEnabled: boolean
    printerStatus: PrinterStatus
    connected: boolean
    ip: string
    macPC: string
}

export interface PrinterStatus {
    paperNotPresent: Boolean
    nearPaperEnd: Boolean
    ticketPresentInOutput: Boolean
    thermalPrintHeadLiftedOrCoverOpened: Boolean
    spooling: Boolean
    dragMotorRolling: Boolean
    LFOrFFKeyPressed: Boolean
    headTemperatureError: Boolean
    powerSupplyVoltageError: Boolean
    commandError: Boolean
    paperJam: Boolean
    notchError: Boolean
    cutterError: Boolean
    ramOrEepromError: Boolean
    printerOffline: Boolean
}

export interface DeviceInfo {
    network: {
        mode: string
        ip: string
        mask: string
        mac: string
        dns1: string
        dns2: string
        gateway: string | null
    }
    screen: {
        rotation: string
    }
    user: {
        screenId: string
        id: string
    }
    settings: {
        scanner: boolean
        screenStandby: boolean
    }
    error: string
    version: string
    versionInteger: number
    status: string
}

export interface ReceivedPrinterMessage {
    status: []
}

export interface ReceivedBarcodeMessage {
    barcode: string
}

export interface ReceivedGetInfoMessage {
    network: {
        mode?: string
        ip: string
        mask: string
        mac: string
        dns1?: string
        dns2?: string
        gateway: string | null
        dns?: string
        dhcp?: string
    }
    screen: {
        rotation: string
    }
    user?: {
        screenId: string
        id: string
    }
    pharmacy?: {
        screenid: string
        id: string
    }
    settings?:{
        scanner: string,
        screen_standby: string
    }
    error?: string
    version?: string
    status?: string
}

export interface ReceivedPrintscreenMessage {
    command: {
        end_point: string
        filename: string
    }
    status_code: string
}

export interface ReceivedHatchPhotoMessage {
    photoData: string
}

export interface PrintscreenStatus {
    endPoint: string
    filename: string
    statusCode: string
}

export interface ReceivedWatchdogMessage {
    command?: {
        watchdog?: string
        status?: string
    }
}

export interface LockerConfigurationModuleLocker {
    lockernr: number,
    front_width: number,
    front_height: number,
    front_x_left: number,
    front_y_bottom: number
}

export interface LockerConfigurationModuleMonitor {
    front_width: number,
    front_height: number,
    front_x_left: number,
    front_y_bottom: number
}

export interface LockerConfigurationModulePayterminal {
    front_width: number,
    front_height: number,
    front_x_left: number,
    front_y_bottom: number
}

export interface LockerConfigurationModule {
    nr: number,
    type: string,
    front_x_left: number,
    bottom_y: number,
    monitor: LockerConfigurationModuleMonitor,
    payterminal: LockerConfigurationModulePayterminal,
    lockers: Array<LockerConfigurationModuleLocker>
}

export interface LockerConfigurationResponseMessage {
    messagetype: 'string',
    modules: Array<LockerConfigurationModule>
}

export interface LockerStatus {
    modulenr: number,
    lockernr: number,
    locked: number
}

export interface LockerStatusResponseMessage {
    messagetype: string,
    lockers: Array<LockerStatus>
}

export interface ThermoValuesModule {
    nr: number,
    temperature_celcius: number,
    humidity: number,
    fanspeed_pct: number,
    heating_active: number,
    cooling_active: number   
}

export interface ThermoValuesResponseMessage {
    messagetype: string,
    modules: Array<ThermoValuesModule>
}

export const PRINTERSTATUS_DEFAULT: PrinterStatus = {
    paperNotPresent: false,
    nearPaperEnd: false,
    ticketPresentInOutput: false,
    thermalPrintHeadLiftedOrCoverOpened: false,
    spooling: false,
    dragMotorRolling: false,
    LFOrFFKeyPressed: false,
    headTemperatureError: false,
    powerSupplyVoltageError: false,
    commandError: false,
    paperJam: false,
    notchError: false,
    cutterError: false,
    ramOrEepromError: false,
    printerOffline: false
};

export const DEVICEINFO_DEFAULT: DeviceInfo = {
    network: {
        mode: '',
        ip: '',
        mask: '',
        mac: '',
        dns1: '',
        dns2: '',
        gateway: ''
    },
    screen: {
        rotation: ''
    },
    settings: {
        scanner: false,
        screenStandby: false,
    },
    user: {
        screenId: '',
        id: ''
    },
    error: '',
    version: '',
    versionInteger: 0,
    status: ''
};

export const PRINTSCREENSTATUS: PrintscreenStatus = {
    endPoint: '',
    filename: '',
    statusCode: '0'
}

export function deepClone(objectToClone: any){
    return JSON.parse(JSON.stringify(objectToClone));
}

export function getCurrentDateAndTimeDetails(){
    let currDate = new Date();
    let currDay = (`0${currDate.getDate()}`).slice(-2);
    let currMonth = (`0${(currDate.getMonth() + 1)}`).slice(-2);
    let currYear = currDate.getFullYear();
    return `${currYear}-${currMonth}-${currDay} ${currDate.getHours()}:${currDate.getMinutes()}:${currDate.getSeconds()} ${currDate.getMilliseconds()}`;
}


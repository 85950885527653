import React, { MouseEventHandler, ReactNode } from 'react';

import { Icon, Tx, IconType } from '..';

import './Pill.scss';

export type ChipColor = 'primary' | 'secondary' | 'tertiary' | 'accent' | 'success' | 'error' | 's1' | 's2' | 's3' | 's4' | 's5' | 'plain';
type ChipProps = {
    size?: 'xxs' | 'xs' | 'sm' | 'md';
    radius?: 'xs' | 'sm' | 'rounded';
    color?: ChipColor;
    outlined?: boolean;
    onDelete?: MouseEventHandler<HTMLSpanElement>;
    onClick?: MouseEventHandler<HTMLDivElement>;
    startIconType?: IconType;
    className?: string;
    children: ReactNode;
    style?: React.CSSProperties;
    disabled?: boolean;
};

export default function Pill({
    size = 'sm',
    radius = 'sm',
    color = 'primary',
    outlined = false,
    onDelete,
    onClick,
    startIconType,
    children,
    className,
    style,
    disabled,
    ...props
}: ChipProps) {
    const chipClassNames = [
        'Pill',
        size,
        `radius-${radius}`,
        color,
        outlined ? 'outlined' : '',
        onClick ? 'clickable' : '',
        className ? className : '',
    ].join(' ');
    const iconSize: {
        [key: string]: 'xs' | 'xxs';
    } = {
        xs: 'xxs',
        sm: 'xxs',
        md: 'xs',
    };
    const typographySize: {
        [key: string]: 'xs' | 'sm' | 'md';
    } = {
        xs: 'xs',
        xxs: 'xs',
        sm: 'sm',
        md: 'md',
    };
    return (
        <Tx
            level={`body-${typographySize[size]}`}
            as="span"
            onClick={onClick}
            className={chipClassNames}
            style={style}
            {...props}
        >
            {startIconType && (
                <span className="chip-icon">
                    <Icon
                        type={startIconType}
                        iconSize={iconSize[size]}
                    />
                </span>
            )}
            <label>{children}</label>
            {onDelete && (
                <span
                    className="chip-delete"
                    onClick={e => {
                        if (!disabled) {
                            e.stopPropagation();
                        }
                        onDelete(e);
                    }}
                    data-test-id="remove"
                >
                    <Icon
                        type="action_remove"
                        iconSize={iconSize[size]}
                    />
                </span>
            )}
        </Tx>
    );
}

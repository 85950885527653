import * as React from 'react';

import classNames from 'classnames';

import { ISearchProvider } from 'utils';

import { Card } from '@/card';
import Checkbox from '@/checkbox/Checkbox';
import CheckboxList from '@/checkboxList/CheckboxList';
import color from '@/color';
import { InputBase, InputBaseProps, Dropdown } from '@/core';
import { Icon } from '@/icon';
import { Menu, MenuWithSearch } from '@/menu';
import { Pill } from '@/pill';

import styles from './Select.scss';
import inputStyles from '@/input/Input.scss';

export type ISelectOption<E = Record<string, unknown>> = E & {
    label: string | React.ReactNode;
    value: string;
};

export interface ISearchableSelectOption {
    searchLabel?: string;
}

type BaseProps = {
    placeholder?: string | React.ReactNode;
    alwaysDisplayPlaceholder?: boolean;
    minDropdownHeight?: string;
    maxDropdownHeight?: string;
    minWidth?: string;
    doNotCloseOnClickOutside?: boolean;
    forwardRef?: React.Ref<HTMLSelectElement | null>;
    smallHeader?: boolean;
    onMouseDown?: (event: React.MouseEvent) => void;
    bodyClassName?: string;
    withClearAction?: boolean;
    onClear?: () => void;
    showSelectedOnTop?: boolean;
    focusRef?: (focusFunc: () => void) => void;
    anchorBottom?: boolean;
    insideModal?: boolean;
};

type WithoutSearch = {
    withSearch?: never;
    searchPlaceholder?: never;
    options: ISelectOption[];
    withSelectAll?: boolean;
    selectAllText?: string;
    deselectAllText?: string;
};

type WithSearch = {
    withSearch?: true;
    options?: never;
    searchProvider?: ISearchProvider<ISelectOption<ISearchableSelectOption>>;
    searchPlaceholder?: string;
    defaultSearchQuery?: string;
    noSearchResultsPlaceholder?: string;
    searchInProgressPlaceholder?: string;
    withSelectAll?: boolean;
    selectAllText?: string;
    deselectAllText?: string;
    extraSearchHeader?: (doSearch?: () => void) => React.ReactNode;
    showLoadMore?: boolean;
    loadMoreLabel?: string;
};

type SingleSelectProps = {
    multiple?: never;
    onChange?: (newValue: string, callback?: () => void) => void;
};

type MultiSelectProps = {
    multiple?: true;
    onChange?: (newValue: string[], callback?: () => void) => void;
    options?: ISelectOption[];
    maxSelectedItems?: number;
    withSelectAll?: boolean;
    selectAllText?: string;
    deselectAllText?: string;
    noHeaderLabelItemSeparator?: boolean;
};

export type ControlledSelectProps = InputBaseProps & BaseProps & (SingleSelectProps | MultiSelectProps) & (WithoutSearch | WithSearch);

export type ControlledSelectPropsWithValue = InputBaseProps &
    BaseProps &
    (
        | (SingleSelectProps & {
              value: string;
          })
        | (MultiSelectProps & {
              value: string[];
          })
    ) &
    (WithoutSearch | WithSearch);

interface IState {
    displayedLabel: string | React.ReactNode;
    query?: string;
}

class ControlledSelect extends InputBase<ControlledSelectPropsWithValue, IState> {
    private readonly dropdown = React.createRef<Dropdown>();
    private header = React.createRef<HTMLElement>();
    private hiddenSelect: HTMLSelectElement | undefined;
    private refreshTimeout: ReturnType<typeof setTimeout>;
    private refreshInterval: ReturnType<typeof setInterval>;

    constructor(props: ControlledSelectPropsWithValue) {
        super(props);

        this.state = {
            displayedLabel: '',
            query: this.isWithSearch(props) ? props.defaultSearchQuery || '' : '',
        };
    }

    componentDidUpdate(): void {
        this.refreshDisplayedLabel();
    }

    componentDidMount(): void {
        this.refreshDisplayedLabel();
        this.refreshInterval = setInterval(() => {
            this.refreshDisplayedLabel(undefined, true);
        }, 3000);
    }

    componentWillUnmount() {
        if (this.refreshTimeout) {
            clearTimeout(this.refreshTimeout);
        }

        if (this.refreshInterval) {
            clearInterval(this.refreshInterval);
        }
    }

    render() {
        const { minWidth, doNotCloseOnClickOutside, disabled, style, smallHeader, bodyClassName, anchorBottom, insideModal } = this.props;
        const dropdownStyle: React.CSSProperties = {};

        if (minWidth) {
            dropdownStyle.minWidth = minWidth;
        }

        return (
            <Dropdown
                ref={this.dropdown}
                className={this.getBaseClassName()}
                style={{
                    ...dropdownStyle,
                    ...style,
                }}
                headerRenderer={this.renderHeader}
                headerArrow={smallHeader}
                body={this.renderDropdownWithWrap()}
                bodyClassName={styles.SelectDropdownBody + ' ' + (bodyClassName || '')}
                doNotCloseOnClickOutside={doNotCloseOnClickOutside}
                anchorBottom={anchorBottom}
                insideModal={insideModal}
                disabled={disabled}
                onMouseDown={this.props.onMouseDown}
                disableFocus={true}
                onClose={(fromClick: boolean) => {
                    if (fromClick && this.header.current) {
                        this.header.current.focus();
                    }
                }}
            />
        );
    }

    private renderHeader = (isOpen: boolean, anchorRef: React.RefObject<any>) => {
        this.header = anchorRef;

        const { displayedLabel } = this.state;
        const { placeholder, alwaysDisplayPlaceholder, disabled } = this.props;
        const baseLabel = placeholder || <span dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />;
        let label;

        if (alwaysDisplayPlaceholder) {
            label = baseLabel;
        } else {
            label = displayedLabel || baseLabel;
        }

        return (
            <label
                className={this.getClassName()}
                {...this.getDataTestIdProps()}
            >
                {this.renderLabel()}

                <div
                    ref={anchorRef}
                    className={inputStyles.InputBaseWrapperInner}
                    tabIndex={disabled ? -1 : 0}
                >
                    <div className={this.getHeaderClassName(!!displayedLabel)}>
                        <span className={styles.SelectHeaderLabel}>{label}</span>

                        {this.displayClearAction() ? (
                            <a
                                className={inputStyles.InputIconRight + ' ' + styles.ClearAction}
                                href=""
                                onClick={e => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    this.handleClear();
                                }}
                            >
                                <Icon
                                    iconSize="m"
                                    type="action_remove"
                                    className={color.Grey.Dark2}
                                />
                            </a>
                        ) : null}

                        <span className={inputStyles.InputIconRight}>
                            <Icon
                                iconSize="m"
                                type={isOpen ? 'action_arrow_up' : 'action_arrow_down'}
                                className={color.Grey.Dark2}
                            />
                        </span>
                    </div>
                </div>

                {this.renderHiddenInput()}

                {this.renderDescription()}
                {this.renderError()}
            </label>
        );
    };

    private renderHiddenInput() {
        const { name, value, forwardRef, focusRef, options, multiple } = this.props;

        let hiddenOptions = options || [];
        if (this.isWithSearch(this.props) && this.props.searchProvider) {
            if (typeof value === 'string') {
                hiddenOptions = [
                    {
                        value,
                        label: value,
                    },
                ];
            } else if (value !== null && value !== undefined) {
                hiddenOptions = value.map(oneValue => {
                    return {
                        value: oneValue,
                        label: oneValue,
                    };
                });
            }
        }

        return (
            <select
                name={name}
                ref={element => {
                    if (element) {
                        this.hiddenSelect = element;
                    } else {
                        this.hiddenSelect = undefined;
                    }

                    if (forwardRef) {
                        if (typeof forwardRef === 'function') {
                            forwardRef(element);
                        } else {
                            (forwardRef.current as any) = element;
                        }
                    }

                    if (focusRef) {
                        focusRef(() => {
                            if (this.header.current) {
                                this.header.current.focus();
                            }
                        });
                    }
                }}
                value={value}
                multiple={multiple}
                onChange={() => {
                    // do nothing
                }}
            >
                <option value={undefined} />
                {hiddenOptions.map(
                    (option: ISelectOption): React.ReactNode => (
                        <option
                            key={option.value}
                            value={option.value}
                        >
                            {option.value}
                        </option>
                    ),
                )}
            </select>
        );
    }

    private renderDropdownWithWrap() {
        if (!this.isMultiSelect(this.props)) {
            return this.renderDropdown();
        }

        const { name } = this.props;

        return (
            <CheckboxList
                name={name}
                value={(this.props.value as string[]) || []}
                onChange={this.handleCheckboxListChange}
            >
                {this.renderDropdown()}
            </CheckboxList>
        );
    }

    private renderDropdown() {
        const { value, options, maxDropdownHeight, minDropdownHeight, showSelectedOnTop } = this.props;
        const { query } = this.state;
        const isMultiSelect = this.isMultiSelect(this.props);
        let selectedItems: string[] = [];

        if (typeof value !== 'undefined') {
            if (!isMultiSelect) {
                selectedItems.push(value as string);
            } else {
                selectedItems = value as string[];
            }
        }

        const itemIsDisabled = (itemValue: string) => {
            return (
                this.isMultiSelect(this.props) &&
                this.props.maxSelectedItems !== undefined &&
                value.indexOf(itemValue) === -1 &&
                value.length >= this.props.maxSelectedItems
            );
        };

        if (!this.isWithSearch(this.props)) {
            return (
                <Card
                    hSpacing="none"
                    vSpacing="none"
                    elevated={true}
                >
                    {isMultiSelect && this.displaySelectAll(this.props) ? (
                        <div className={styles.SelectAll}>
                            <a
                                className="mr-15"
                                href=""
                                onClick={e => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    this.handleSelectAll();
                                }}
                            >
                                <Pill color="s3">{this.props.selectAllText}</Pill>
                            </a>
                            <a
                                href=""
                                onClick={e => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    this.handleClear();
                                }}
                            >
                                <Pill color="s3">{this.props.deselectAllText}</Pill>
                            </a>
                        </div>
                    ) : null}
                    <Menu
                        className={isMultiSelect && this.displaySelectAll(this.props) ? 'mt-40' : undefined}
                        items={options}
                        onItemClick={this.handleOptionClick}
                        minHeight={minDropdownHeight || 'none'}
                        maxHeight={maxDropdownHeight || '222px'}
                        selectedItems={selectedItems}
                        showSelectedOnTop={showSelectedOnTop}
                        itemRenderer={item => {
                            if (!isMultiSelect) {
                                return item.label;
                            }

                            return (
                                <Checkbox
                                    value={item.value}
                                    disabled={itemIsDisabled(item.value)}
                                >
                                    {item.label}
                                </Checkbox>
                            );
                        }}
                        disableItemFocus={isMultiSelect}
                    />
                </Card>
            );
        } else {
            return (
                <Card
                    hSpacing="none"
                    vSpacing="none"
                    elevated={true}
                    className="pt-9"
                >
                    {isMultiSelect && this.displaySelectAll(this.props) ? (
                        <div className={styles.SelectAll + ' ' + styles.SelectAllWithSearch}>
                            <a
                                className="mr-15"
                                href=""
                                onClick={e => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    this.handleSelectAll();
                                }}
                            >
                                <Pill color="s3">{this.props.selectAllText}</Pill>
                            </a>
                            <a
                                href=""
                                onClick={e => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    this.handleClear();
                                }}
                            >
                                <Pill color="s3">{this.props.deselectAllText}</Pill>
                            </a>
                        </div>
                    ) : null}
                    <MenuWithSearch
                        className={isMultiSelect && this.displaySelectAll(this.props) ? 'mt-40' : undefined}
                        onItemClick={this.handleOptionClick}
                        minHeight={minDropdownHeight || 'none'}
                        maxHeight={maxDropdownHeight || '222px'}
                        selectedItems={selectedItems}
                        showSelectedOnTop={showSelectedOnTop}
                        searchProvider={this.props.searchProvider}
                        searchPlaceholder={this.props.searchPlaceholder}
                        query={query}
                        onQueryChange={newQuery => this.setState({ query: newQuery })}
                        itemRenderer={item => {
                            if (!isMultiSelect || item.value === '--no-results--' || item.value === '--searching--') {
                                return item.label;
                            }

                            return (
                                <Checkbox
                                    value={item.value}
                                    disabled={itemIsDisabled(item.value)}
                                >
                                    {item.label}
                                </Checkbox>
                            );
                        }}
                        disableItemFocus={isMultiSelect}
                        noResultsPlaceholder={this.props.noSearchResultsPlaceholder}
                        searchInProgressPlaceholder={this.props.searchInProgressPlaceholder}
                        extraHeader={this.props.extraSearchHeader}
                        showLoadMore={this.props.showLoadMore}
                        loadMoreLabel={this.props.loadMoreLabel}
                    />
                </Card>
            );
        }
    }

    private handleOptionClick = async (
        event: React.MouseEvent | React.KeyboardEvent | React.TouchEvent,
        item: ISelectOption,
    ): Promise<void> => {
        if (this.isMultiSelect(this.props)) {
            return;
        }

        const { onChange } = this.props;

        if (onChange) {
            onChange(item.value, () => {
                this.triggerNativeChangeEvent();
                this.refreshDisplayedLabel(item.value);
            });
        } else {
            this.triggerNativeChangeEvent();
            this.refreshDisplayedLabel(item.value);
        }

        this.close();

        if ((event as React.KeyboardEvent).key !== undefined && this.header.current) {
            this.header.current.focus();
        }
    };

    private handleCheckboxListChange = async (newValue: string[]): Promise<void> => {
        if (!this.isMultiSelect(this.props)) {
            return;
        }

        const { onChange } = this.props;

        if (this.props.maxSelectedItems !== undefined && newValue.length > this.props.maxSelectedItems) {
            return;
        }

        if (onChange) {
            onChange(newValue, () => {
                this.triggerNativeChangeEvent();
                this.refreshDisplayedLabel(newValue);
            });
        } else {
            this.triggerNativeChangeEvent();
            this.refreshDisplayedLabel(newValue);
        }
    };

    private handleSelectAll = async () => {
        if (this.isWithSearch(this.props) && this.props.searchProvider) {
            const options = await this.props.searchProvider.search(this.state.query || '');
            const optionIds = options.map(option => option.value);
            await this.handleCheckboxListChange(optionIds);
            this.triggerNativeChangeEvent();
            this.refreshDisplayedLabel();
        } else if (!this.isWithSearch(this.props) && this.props.options) {
            const optionIds = this.props.options.map(option => option.value);
            await this.handleCheckboxListChange(optionIds);
            this.triggerNativeChangeEvent();
            this.refreshDisplayedLabel();
        }
    };

    private handleClear = () => {
        if (this.isMultiSelect(this.props)) {
            if (this.props.onChange) {
                this.props.onChange([], () => {
                    this.triggerNativeChangeEvent();
                    this.refreshDisplayedLabel([]);
                });
            } else {
                this.triggerNativeChangeEvent();
                this.refreshDisplayedLabel([]);
            }
        } else {
            if (this.props.onChange) {
                this.props.onChange('', () => {
                    this.triggerNativeChangeEvent();
                    this.refreshDisplayedLabel();
                });
            } else {
                this.triggerNativeChangeEvent();
                this.refreshDisplayedLabel();
            }
        }

        if (this.props.onClear) {
            this.props.onClear();
        }
    };

    private getBaseClassName(): string {
        return classNames(styles.SelectWrapper, this.props.smallHeader ? styles.SelectWithSmallHeader : null, this.props.className);
    }

    protected getClassName() {
        return classNames(super.getClassName(), inputStyles['with-icon-right'], this.displayClearAction() ? styles.WithClearAction : null);
    }

    private getHeaderClassName(hasSelectedOption: boolean): string {
        return classNames(inputStyles.Input, !hasSelectedOption ? styles.SelectHeaderWithPlaceholder : null);
    }

    // force option is needed because `key` does not change for localised labels. Labels are not always string either, so we can't compare them.
    private async refreshDisplayedLabel(value?: string | string[], force = false) {
        if (this.props.alwaysDisplayPlaceholder) {
            return;
        }

        if (typeof value === 'undefined') {
            value = this.props.value;
        }
        const { options } = this.props;
        let displayedLabel: React.ReactNode;
        let selectedOption;
        let selectedOptions: ISelectOption<any>[] = [];

        if (value !== undefined && value !== null) {
            if (!this.isMultiSelect(this.props)) {
                if (this.isWithSearch(this.props) && this.props.searchProvider) {
                    selectedOption = await this.props.searchProvider.byValue(value as string);
                } else if (options) {
                    selectedOption = options.find(option => value === option.value);
                }
            } else {
                if (this.isWithSearch(this.props) && this.props.searchProvider) {
                    const values = value as string[];
                    if (values.length > 0) {
                        selectedOptions = await this.props.searchProvider.byValues(values);
                    }
                } else if (options) {
                    selectedOptions = options.filter(option => value!.indexOf(option.value) > -1);
                }
            }
        }

        if (selectedOption) {
            displayedLabel = <React.Fragment key={selectedOption.value}>{selectedOption.label}</React.Fragment>;
        } else if (selectedOptions.length > 0) {
            displayedLabel = (
                <React.Fragment key={selectedOptions.map(option => option.value).join('-')}>
                    {selectedOptions.map((option, index) => (
                        <React.Fragment key={option.value}>
                            {option.label}
                            {this.isMultiSelect(this.props) && !this.props.noHeaderLabelItemSeparator && index < selectedOptions.length - 1
                                ? ', '
                                : null}
                        </React.Fragment>
                    ))}
                </React.Fragment>
            );
        }

        const currentLabelKey = this.state.displayedLabel ? (this.state.displayedLabel as any).key : undefined;
        const newLabelKey = displayedLabel ? (displayedLabel as any).key : undefined;

        if (force || currentLabelKey !== newLabelKey) {
            // this timeout fixes infinite recursion due to setState inside componentDidUpdate
            if (this.refreshTimeout) {
                clearTimeout(this.refreshTimeout);
            }

            this.refreshTimeout = setTimeout(() => {
                this.setState({
                    displayedLabel,
                });
            }, 100);
        }
    }

    private triggerNativeChangeEvent() {
        // we need to manually trigger a change event on the native select so that listeners
        //   know the value changed

        if (this.hiddenSelect) {
            this.hiddenSelect.dispatchEvent(
                new Event('change', {
                    bubbles: true,
                }),
            );
        }
    }

    private displayClearAction() {
        return (
            this.props.withClearAction &&
            ((this.props.multiple && this.props.value && this.props.value.length > 0) || (!this.props.multiple && this.props.value))
        );
    }

    private displaySelectAll(props: ControlledSelectProps): props is MultiSelectProps & (WithoutSearch | WithSearch) {
        return (props as MultiSelectProps).hasOwnProperty('withSelectAll') && !!(props as MultiSelectProps).withSelectAll;
    }

    private isMultiSelect(props: ControlledSelectProps): props is MultiSelectProps & (WithoutSearch | WithSearch) {
        return (props as MultiSelectProps).hasOwnProperty('multiple') && !!(props as MultiSelectProps).multiple;
    }

    private isWithSearch(props: ControlledSelectProps): props is WithSearch & (SingleSelectProps | MultiSelectProps) {
        return (props as WithSearch).hasOwnProperty('withSearch') && !!(props as WithSearch).withSearch;
    }

    public open = (): void => {
        this.dropdown.current!.open();
    };

    public close = (): void => {
        this.dropdown.current!.close();
    };
}

export default React.forwardRef<HTMLSelectElement, ControlledSelectPropsWithValue>((props, ref) => {
    return (
        <ControlledSelect
            forwardRef={ref}
            {...props}
        />
    );
});

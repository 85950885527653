import * as React from 'react';

import classNames from 'classnames';

import { Domain } from 'api';
import { TouchAndMouseDragHandler, TouchAndMouseMove } from 'ui-components';

import { store, Actions } from '../state';
import { PlacedProduct, ShelfWithMeta } from '../types';
import { findPlacedProductShelf, findPlacedProduct, computeProductSize, getSelectedImage } from '../utils';

import styles from './styles/PlacedProductResizer.scss';

const DRAG_THRESHOLD = 40;

interface IProps {
    className?: string;
    style?: React.CSSProperties;

    shelf: ShelfWithMeta;
    product: Domain.SlideshowProduct;
    placedProduct: PlacedProduct;
    bottomOffset: number;
    minX: number;
    maxX: number;
}

interface IState {
    sideMoved: string;
    isInitialMiddleMove: boolean;
    isMiddleDragging: boolean;
    isInitialMove: boolean;
    initialX: number;
    initialWidth: number;
    movementY: number;
}

export default class PlacedProductResizer extends React.PureComponent<IProps, IState> {
    static contextType = store;
    declare context: React.ContextType<typeof store>;
    private readonly moveHandler: TouchAndMouseDragHandler;

    constructor(props: IProps) {
        super(props);

        this.state = {
            sideMoved: '',
            isInitialMiddleMove: false,
            isMiddleDragging: false,
            isInitialMove: false,
            initialX: 0,
            initialWidth: 0,
            movementY: 0,
        };

        this.moveHandler = new TouchAndMouseDragHandler<string>(this.handleMove, this.handleMoveStart, this.handleMoveEnd);
    }

    render() {
        const { className, style, bottomOffset, placedProduct } = this.props;

        const { canvas, placedProductSettingsDialog, draggedProduct } = this.context.state;
        let { resizedProductId } = this.context.state;

        if (placedProductSettingsDialog) {
            resizedProductId = findPlacedProduct(canvas.shelves, placedProductSettingsDialog.placedProductId)?.id;
        }

        let resizedProductShelf;
        let thisProductShelf;

        if (resizedProductId) {
            resizedProductShelf = findPlacedProductShelf(canvas.shelves, resizedProductId);
            thisProductShelf = findPlacedProductShelf(canvas.shelves, placedProduct.id);
        }

        const resizingOtherProductOnShelf = resizedProductId !== placedProduct.id && resizedProductShelf === thisProductShelf;

        const resizingSelf = resizedProductId === placedProduct.id && resizedProductShelf === thisProductShelf;

        return (
            <span
                className={classNames(
                    styles.PlacedProductResizer,
                    className,
                    !draggedProduct && resizingSelf ? styles.PlacedProductResizerActive : null,
                    !draggedProduct && resizingOtherProductOnShelf ? styles.PlacedProductResizerEmphasize : null,
                    draggedProduct ? styles.PlacedProductResizerDisabled : null,
                )}
                style={{
                    bottom: bottomOffset + 'px',
                    ...style,
                }}
                onContextMenu={event => event.preventDefault()}
            >
                <span
                    className={styles.PlacedProductResizerTop}
                    onMouseDown={event => this.moveHandler.handleMouseDown(event, 'top')}
                    onTouchStart={event => this.moveHandler.handleTouchStart(event, 'top')}
                />
                <span
                    className={styles.PlacedProductResizerLeft}
                    onMouseDown={event => this.moveHandler.handleMouseDown(event, 'left')}
                    onTouchStart={event => this.moveHandler.handleTouchStart(event, 'left')}
                />
                <span
                    className={styles.PlacedProductResizerRight}
                    onMouseDown={event => this.moveHandler.handleMouseDown(event, 'right')}
                    onTouchStart={event => this.moveHandler.handleTouchStart(event, 'right')}
                />
                <span
                    className={styles.PlacedProductResizerTopLeft}
                    onMouseDown={event => this.moveHandler.handleMouseDown(event, 'top-left')}
                    onTouchStart={event => this.moveHandler.handleTouchStart(event, 'top-left')}
                />
                <span
                    className={styles.PlacedProductResizerTopRight}
                    onMouseDown={event => this.moveHandler.handleMouseDown(event, 'top-right')}
                    onTouchStart={event => this.moveHandler.handleTouchStart(event, 'top-right')}
                />
                <span
                    className={styles.PlacedProductResizerMiddle}
                    onMouseDown={event => this.moveHandler.handleMouseDown(event, 'middle')}
                    onTouchStart={event => this.moveHandler.handleTouchStart(event, 'middle')}
                />
            </span>
        );
    }

    private handleMoveStart = (_1: any, side: string) => {
        const { dispatch } = this.context;
        const { placedProduct } = this.props;

        this.setState(
            {
                isInitialMove: true,
                isInitialMiddleMove: true,
                isMiddleDragging: false,
                initialX: placedProduct.x,
                initialWidth: placedProduct.width,
                movementY: 0,
                sideMoved: side,
            },
            () => {
                dispatch({
                    type: Actions.SET_RESIZED_PRODUCT,
                    resizedProductId: placedProduct.id,
                });
            },
        );
    };

    private handleMoveEnd = () => {
        const { dispatch } = this.context;

        this.setState(
            {
                isInitialMove: false,
                isInitialMiddleMove: false,
                isMiddleDragging: false,
                initialX: 0,
                initialWidth: 0,
                movementY: 0,
                sideMoved: '',
            },
            () => {
                dispatch({
                    type: Actions.SET_DRAGGED_PRODUCT_DROPPED_FLAG,
                    dropped: true,
                });

                setTimeout(() => {
                    dispatch({
                        type: Actions.SET_DRAGGED_PLACED_PRODUCT,
                        draggedProduct: undefined,
                        placedProductId: undefined,
                    });

                    dispatch({
                        type: Actions.SET_DRAGGED_PRODUCT_DROPPED_FLAG,
                        dropped: false,
                    });
                }, 200); // allow some time for someone to handle the drop
            },
        );
    };

    private handleMove = (move: TouchAndMouseMove) => {
        const { state, dispatch } = this.context;
        const { sideMoved, isInitialMove, isInitialMiddleMove, isMiddleDragging, initialX, initialWidth, movementY } = this.state;
        const { shelf, product, placedProduct, bottomOffset, minX, maxX } = this.props;

        const selectedImage = getSelectedImage(placedProduct, product);
        if (!state.productImageSizes.hasOwnProperty(selectedImage.url)) {
            return;
        }

        const productSize = computeProductSize(state.screenResolution, product, state.productImageSizes[selectedImage.url]);

        if (isInitialMiddleMove && sideMoved === 'middle') {
            this.setState({
                movementY: movementY + move.movementY,
            });
        }

        if (isMiddleDragging && sideMoved === 'middle') {
            dispatch({
                type: Actions.SET_DRAGGED_PRODUCT_POSITION,
                x: move.x,
                y: move.y,
            });
            return;
        }

        if (Math.abs(movementY) >= DRAG_THRESHOLD) {
            if (isInitialMiddleMove) {
                this.setState({
                    isInitialMiddleMove: false,
                    isMiddleDragging: true,
                });

                dispatch({
                    type: Actions.SET_DRAGGED_PLACED_PRODUCT,
                    draggedProduct: this.props.product,
                    placedProductId: this.props.placedProduct.id,
                });

                dispatch({
                    type: Actions.SET_PLACED_PRODUCT_LEFT_OFFSET,
                    shelfId: shelf.shelf.id,
                    placedProductId: placedProduct.id,
                    offset: initialX - placedProduct.x,
                });

                dispatch({
                    type: Actions.SET_PLACED_PRODUCT_RIGHT_OFFSET,
                    shelfId: shelf.shelf.id,
                    placedProductId: placedProduct.id,
                    offset: initialWidth - placedProduct.width,
                });

                if (!this.state.isInitialMove) {
                    dispatch({
                        type: Actions.CanvasActions.HISTORY_POP,
                    });
                }
            }

            return;
        }

        if (sideMoved === 'top' || sideMoved === 'top-left' || sideMoved === 'top-right') {
            const offset = move.movementY;

            if (
                offset &&
                placedProduct.height - offset < shelf.shelf.height &&
                placedProduct.height - offset >= 0 &&
                placedProduct.height - offset >= productSize.height * (placedProduct.scale || 1) + bottomOffset
            ) {
                if (isInitialMove) {
                    dispatch({
                        type: Actions.CanvasActions.HISTORY_SAVE,
                    });

                    this.setState({
                        isInitialMove: false,
                    });
                }

                dispatch({
                    type: Actions.SET_PLACED_PRODUCT_TOP_OFFSET,
                    shelfId: shelf.shelf.id,
                    placedProductId: placedProduct.id,
                    offset,
                });
            }
        }

        if (sideMoved === 'left' || sideMoved === 'top-left') {
            const offset = move.movementX;

            if (
                offset &&
                placedProduct.x + offset >= minX &&
                placedProduct.x + offset <= maxX &&
                offset <= placedProduct.width &&
                placedProduct.width - offset >= productSize.width * (placedProduct.scale || 1)
            ) {
                if (isInitialMove) {
                    dispatch({
                        type: Actions.CanvasActions.HISTORY_SAVE,
                    });

                    this.setState({
                        isInitialMove: false,
                    });
                }

                dispatch({
                    type: Actions.SET_PLACED_PRODUCT_LEFT_OFFSET,
                    shelfId: shelf.shelf.id,
                    placedProductId: placedProduct.id,
                    offset,
                });
            }
        }

        if (sideMoved === 'right' || sideMoved === 'top-right') {
            const offset = move.movementX;

            if (
                offset &&
                placedProduct.x + placedProduct.width + offset >= minX &&
                placedProduct.x + placedProduct.width + offset <= maxX &&
                placedProduct.width + offset >= productSize.width * (placedProduct.scale || 1)
            ) {
                if (isInitialMove) {
                    dispatch({
                        type: Actions.CanvasActions.HISTORY_SAVE,
                    });

                    this.setState({
                        isInitialMove: false,
                    });
                }

                dispatch({
                    type: Actions.SET_PLACED_PRODUCT_RIGHT_OFFSET,
                    shelfId: shelf.shelf.id,
                    placedProductId: placedProduct.id,
                    offset,
                });
            }
        }

        if (sideMoved === 'middle') {
            const offset = move.movementX;

            if (
                offset &&
                offset <= placedProduct.width &&
                placedProduct.width + offset >= 0 &&
                placedProduct.x + offset >= minX &&
                placedProduct.x + offset <= maxX &&
                placedProduct.x + placedProduct.width + offset >= minX &&
                placedProduct.x + placedProduct.width + offset <= maxX
            ) {
                if (isInitialMove) {
                    dispatch({
                        type: Actions.CanvasActions.HISTORY_SAVE,
                    });

                    this.setState({
                        isInitialMove: false,
                    });
                }

                dispatch({
                    type: Actions.SET_PLACED_PRODUCT_LEFT_OFFSET,
                    shelfId: shelf.shelf.id,
                    placedProductId: placedProduct.id,
                    offset,
                });

                dispatch({
                    type: Actions.SET_PLACED_PRODUCT_RIGHT_OFFSET,
                    shelfId: shelf.shelf.id,
                    placedProductId: placedProduct.id,
                    offset,
                });
            }
        }
    };
}

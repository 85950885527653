import * as React from 'react';

import classNames from 'classnames';

import { InputBase, InputBaseProps } from '@/core';

import { RadioGroupContext } from './RadioGroupContext';

import styles from './RadioGroup.scss';

export type ControlledRadioGroupProps = InputBaseProps & {
    onChange?: (newValue: string) => void;
    forwardRef?: React.Ref<HTMLInputElement>;
};

export type ControlledRadioGroupPropsWithValue = ControlledRadioGroupProps & {
    value: null | string;
};

class ControlledRadioGroup extends InputBase<ControlledRadioGroupPropsWithValue> {
    protected renderInput(): React.ReactNode {
        const { forwardRef, disabled } = this.props;
        const name = this.getName();
        const value = this.getValue();

        const context = {
            name,
            onChange: this.handleRadioButtonChange,
            value,
            disabled,
        };

        return (
            <React.Fragment>
                <RadioGroupContext.Provider value={context}>
                    <input
                        ref={forwardRef}
                        type="hidden"
                        name={name}
                        disabled={disabled}
                        value={value}
                    />

                    {this.props.children}
                </RadioGroupContext.Provider>
            </React.Fragment>
        );
    }

    private handleRadioButtonChange = (checked: boolean, newValue: string): void => {
        const { onChange } = this.props;

        if (!checked) {
            return;
        }

        if (onChange) {
            onChange(newValue);
        }
    };

    private getValue(): string {
        return this.props.value || '';
    }

    private getName(): string {
        return this.props.name || 'radio-group-' + Math.random();
    }

    protected getClassName(): string {
        return classNames(super.getClassName(), styles.RadioGroup);
    }

    protected getStyle(): React.CSSProperties | undefined {
        return this.props.style;
    }

    protected getBaseWrapperComponent(): string {
        return 'div';
    }
}

export default React.forwardRef<HTMLInputElement, ControlledRadioGroupPropsWithValue>((props, ref) => {
    return (
        <ControlledRadioGroup
            forwardRef={ref}
            {...props}
        />
    );
});

import {
    Pagination,
    Sorting,
    Ownership,
    DeviceOrdersPage,
    BaseOrderDetails,
    CreateDeviceOrderPayload,
    UpdateDeviceOrderStatusPayload,
    SendOrderPaymentFailedEmailForDevicePayload,
    SendOrderDeliveryFailedEmailForDevicePayload,
    UpdateDeviceOrderConfirmationEmailAddressPayload,
    UpdateDeviceOrderCustomerPhoneNumberPayload,
    DeviceTransactionStatus,
    SendOrderDeliveryFailedPharmacistEmailForDevicePayload,
    CreateOrderTransactionPayload,
} from '@/Domain';

import Endpoint from './Endpoint';

export default class DeviceOrderEndpoint extends Endpoint {
    public async GetDeviceOrders(
        ownership: Ownership,
        pagination: Pagination,
        sorting: Sorting,
        filters?: {
            paymentMethod?: string;
            shippingMethod?: string;
            orderStatus?: string;
            deviceId?: string;
            createdFrom?: string;
            createTill?: string;
            deliveries?: string;
            transactions?: string;
            mustHaveAllPaymentStatuses?: string;
        },
        search?: string,
    ): Promise<DeviceOrdersPage> {
        let url = '/device/order/overview';
        if ((ownership.type === 'branch' || ownership.type === 'company') && filters && filters.deviceId !== 'all') {
            url = `/device/${filters.deviceId}/order/overview`;
        }

        const filtersObject = {
            ...filters,
            deliveryStatus: filters?.deliveries?.split(','),
            deliveries: null,
            paymentStatus: filters?.transactions ? filters?.transactions?.split(',') : null,
            transactions: null,
        };

        if (filters && filters.deviceId === 'all') {
            delete filtersObject.deviceId;
        }

        const response = await this.client.get({
            url,
            params: {
                pagination,
                sorting,
                filters: filtersObject,
                search,
            },
        });

        this.guardResponseSchema('#/definitions/DeviceOrdersPage', response);
        return response;
    }

    public async GetDeviceOrderDetails(deviceId: string, orderId: string): Promise<BaseOrderDetails> {
        const response = await this.client.get({
            url: `/device/${deviceId}/order/${orderId}/details`,
        });

        this.guardResponseSchema('#/definitions/BaseOrderDetails', response);
        return response;
    }

    public async CreateDeviceOrder(payload: CreateDeviceOrderPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/CreateDeviceOrder',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Order\\UpdateOrder\\UpdateOrder',
                payload,
            },
        });
    }

    public async UpdateDeviceOrderStatus(payload: UpdateDeviceOrderStatusPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateOrderStatus',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Order\\UpdateOrderStatus\\UpdateOrderStatus',
                payload,
            },
        });
    }

    public async UpdateOrderIsPickedUpSuccessfullyFromVendingMachine(
        orderId: string,
        isPickedUpSuccessfullyFromVendingMachine: boolean,
    ): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateOrderIsPickedUpSuccessfullyFromVendingMachine',
            body: {
                commandName:
                    'App\\Domain\\WriteModel\\Order\\UpdateOrderIsPickedUpSuccessfullyFromVendingMachine\\UpdateOrderIsPickedUpSuccessfullyFromVendingMachine',
                payload: {
                    orderId,
                    isPickedUpSuccessfullyFromVendingMachine,
                },
            },
        });
    }

    public async UpdateDeviceOrderConfirmationEmailAddress(payload: UpdateDeviceOrderConfirmationEmailAddressPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateDeviceOrderConfirmationEmailAddress',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Order\\UpdateOrderConfirmationEmailAddress\\UpdateOrderConfirmationEmailAddress',
                payload,
            },
        });
    }

    public async UpdateDeviceOrderCustomerPhoneNumber(payload: UpdateDeviceOrderCustomerPhoneNumberPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateDeviceOrderCustomerPhoneNumber',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Order\\UpdateOrderCustomerPhoneNumber\\UpdateOrderCustomerPhoneNumber',
                payload,
            },
        });
    }

    public async CreateOrderTransaction(payload: CreateOrderTransactionPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/CreateOrderTransaction',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Order\\CreateOrderTransaction\\CreateOrderTransaction',
                payload,
            },
        });
    }

    public async GetOrderTransactionStatus(orderId: string): Promise<DeviceTransactionStatus> {
        const response = await this.client.get({
            url: '/order/' + orderId + '/transaction-status',
        });
        this.guardResponseSchema('#/definitions/DeviceTransactionStatus', response);
        return Promise.resolve(response);
    }

    public async UpdateTestTransactionStatus(transactionId: string, paymentStatus: string): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateTestTransactionStatus',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Order\\UpdateTestTransactionStatus\\UpdateTestTransactionStatus',
                payload: {
                    transactionId,
                    paymentStatus,
                },
            },
        });
    }

    public async SendOrderPaymentFailedEmailForDevice(payload: SendOrderPaymentFailedEmailForDevicePayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/SendOrderPaymentFailedEmailForDevice',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Order\\SendOrderPaymentFailedEmailForDevice\\SendOrderPaymentFailedEmailForDevice',
                payload,
            },
        });
    }

    public async SendOrderDeliveryFailedEmailForDevice(payload: SendOrderDeliveryFailedEmailForDevicePayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/SendOrderDeliveryFailedEmailForDevice',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Order\\SendOrderDeliveryFailedEmailForDevice\\SendOrderDeliveryFailedEmailForDevice',
                payload,
            },
        });
    }

    public async SendOrderDeliveryFailedPharmacistEmailForDevice(
        payload: SendOrderDeliveryFailedPharmacistEmailForDevicePayload,
    ): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/SendOrderDeliveryFailedPharmacistEmailForDevice',
            body: {
                commandName:
                    'App\\Domain\\WriteModel\\Order\\SendOrderDeliveryFailedPharmacistEmailForDevice\\SendOrderDeliveryFailedPharmacistEmailForDevice',
                payload,
            },
        });
    }
}

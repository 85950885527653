import * as React from 'react';

import classNames from 'classnames';

import { Button } from '@/button';
import { Card } from '@/card';
import { InputBase, InputBaseProps, Dropdown } from '@/core';
import { Icon } from '@/icon';

import Board from './Board';
import Color from './Color';
import Params from './Params';
import Presets from './Presets';
import Ribbon from './Ribbon';

import styles from './ColorPicker.scss';

export type EyedropStartHandler = (
    changeStart: () => void,
    change: (r: number, g: number, b: number) => void,
    changeComplete: (r: number, g: number, b: number) => void,
) => void;

export type ControlledColorPickerProps = InputBaseProps & {
    onChangeStart?: () => void;
    onChange?: (newColorHex?: string, newColor?: Color) => void;
    onChangeComplete?: (newColorHex?: string, newColor?: Color) => void;
    forwardRef?: React.Ref<HTMLInputElement>;

    presetColors?: (string | undefined)[];
    width?: string;

    onEyedropStart?: EyedropStartHandler;
    onEyedropEnd?: () => void;
};

export type ControlledColorPickerPropsWithValue = ControlledColorPickerProps & {
    value: null | string | Color;
};

class ControlledColorPicker extends InputBase<
    ControlledColorPickerPropsWithValue,
    {
        eyedropperEnabled?: boolean;
    }
> {
    private readonly dropdown = React.createRef<Dropdown>();
    private readonly hexInput = React.createRef<HTMLInputElement>();

    private getColor(): Color | undefined {
        if (this.props.value instanceof Color) {
            return this.props.value;
        }

        return this.props.value ? new Color(this.props.value) : undefined;
    }

    protected renderInput(): React.ReactNode {
        const { name, disabled, forwardRef, onEyedropEnd } = this.props;

        const color = this.getColor();

        return (
            <React.Fragment>
                <input
                    ref={forwardRef}
                    type="hidden"
                    name={name}
                    value={color ? color.toHexString() : ''}
                    disabled={disabled}
                />

                <div
                    style={this.props.style}
                    className={classNames(styles.ColorPicker, disabled ? styles.ColorPickerDisabled : undefined)}
                >
                    <Dropdown
                        ref={this.dropdown}
                        headerRenderer={this.renderHeader}
                        headerArrow={true}
                        body={this.renderDropdownBody()}
                        bodyClassName={styles.ColorPickerDropdownBody}
                        disabled={disabled}
                        onOpen={(fromClick: boolean) => {
                            if (fromClick && this.hexInput.current) {
                                this.hexInput.current.focus();
                            }
                        }}
                        onClose={() => {
                            if (onEyedropEnd) {
                                onEyedropEnd();
                            }
                        }}
                    />
                </div>
            </React.Fragment>
        );
    }

    private renderHeader = () => {
        const { label, highlight } = this.props;
        const color = this.getColor();

        return (
            <label className={styles.ColorPickerHeader}>
                {label ? (
                    <span className={styles.InputBaseLabel}>
                        {label}
                        {highlight ? '*' : null}
                    </span>
                ) : null}
                <div
                    className={classNames(
                        styles.ColorPickerHeaderColorBox,
                        color === undefined ? styles.ColorPickerPresetsColorBoxTransparent : undefined,
                    )}
                    style={{
                        backgroundColor: color ? color.toHexString() : 'transparent',
                        borderColor: color ? color.clone().delimitFromWhiteBackground().toHexString() : 'transparent',
                    }}
                />
            </label>
        );
    };

    private renderDropdownBody() {
        const { width, presetColors, onEyedropStart } = this.props;
        const color = this.getColor();

        return (
            <Card
                className={styles.ColorPickerBody}
                elevated={true}
                style={{
                    width: width || 'auto',
                }}
            >
                <Board
                    color={color}
                    onChangeStart={this.handleChangeStart}
                    onChange={this.handleChange}
                    onChangeComplete={this.handleChangeComplete}
                />
                <Ribbon
                    color={color}
                    onChangeStart={this.handleChangeStart}
                    onChange={this.handleChange}
                    onChangeComplete={this.handleChangeComplete}
                />
                <Params
                    ref={this.hexInput}
                    color={color}
                    onChange={newColor => {
                        this.handleChangeStart();
                        this.handleChange(newColor);
                        this.handleChangeComplete(newColor);
                    }}
                    closeDropdown={(fromClick: boolean) => {
                        if (this.dropdown.current) {
                            this.dropdown.current.close(fromClick);
                        }
                    }}
                />
                {presetColors && presetColors.length > 0 ? (
                    <Presets
                        color={color}
                        onChange={newColor => {
                            this.handleChangeStart();
                            this.handleChange(newColor);
                            this.handleChangeComplete(newColor);
                        }}
                        presets={presetColors}
                        closeDropdown={(fromClick: boolean) => {
                            if (this.dropdown.current) {
                                this.dropdown.current.close(fromClick);
                            }
                        }}
                    />
                ) : null}
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <div className="p-7">
                        <Button
                            onClick={() => {
                                this.handleChangeStart();
                                this.handleChange(undefined);
                                this.handleChangeComplete(undefined);
                            }}
                            variant="tertiary"
                            variantSize="xxs"
                            className={styles.ColorPickerEyedropperButton}
                            startIcon={
                                <Icon
                                    type="action_reset"
                                    iconSize="s"
                                />
                            }
                        />
                    </div>
                    {onEyedropStart ? (
                        <div className="p-7">
                            <Button
                                onClick={() => {
                                    onEyedropStart(
                                        this.handleChangeStart,
                                        (r, g, b) => this.handleChange(new Color({ r, g, b })),
                                        (r, g, b) => this.handleChangeComplete(new Color({ r, g, b })),
                                    );
                                }}
                                variant="tertiary"
                                variantSize="xxs"
                                className={styles.ColorPickerEyedropperButton}
                                startIcon={
                                    <Icon
                                        type="action_eyedropper"
                                        iconSize="s"
                                    />
                                }
                            />
                        </div>
                    ) : null}
                </div>
            </Card>
        );
    }

    private handleChangeStart = (): void => {
        if (this.props.onChangeStart) {
            this.props.onChangeStart();
        }
    };

    private handleChange = (color?: Color): void => {
        const { onChange } = this.props;

        if (!onChange) {
            return;
        }

        onChange(color ? color.toHexString() : undefined, color);
    };

    private handleChangeComplete = (color?: Color): void => {
        const { onChangeComplete } = this.props;

        if (!onChangeComplete) {
            return;
        }

        onChangeComplete(color ? color.toHexString() : undefined, color);
    };

    protected getClassName(): string {
        return classNames(super.getClassName(), styles.ColorPickerWrap);
    }

    protected getBaseWrapperComponent(): string {
        return 'div';
    }

    protected renderLabel() {
        return null;
    }
}

export default React.forwardRef<HTMLInputElement, ControlledColorPickerPropsWithValue>((props, ref) => {
    return (
        <ControlledColorPicker
            forwardRef={ref}
            {...props}
        />
    );
});

import { setContextProperty, clearContextProperty, log } from './Logger';

export function setDeviceFingerprint(deviceFingerprint: string) {
    setContextProperty('deviceFingerprint', deviceFingerprint);
}

export function setDeviceMac(deviceMac: string) {
    setContextProperty('deviceMac', deviceMac);
}

export function clearDeviceMac() {
    clearContextProperty('deviceMac');
}

export function setCompanyId(companyId: string) {
    setContextProperty('companyId', companyId);
}

export function clearCompanyId() {
    clearContextProperty('companyId');
}

export function setBranchId(branchId: string) {
    setContextProperty('branchId', branchId);
}

export function clearBranchId() {
    clearContextProperty('branchId');
}

export function setDeviceId(deviceId: string) {
    setContextProperty('deviceId', deviceId);
}

export function setDeviceType(deviceType: string) {
    setContextProperty('deviceType', deviceType);
}

export function clearDeviceId() {
    clearContextProperty('deviceId');
}

let keepAliveInterval: ReturnType<typeof setInterval> | undefined;

export function tvAppLoaded() {
    log('info', 'DEVICE_APP_LOADED');

    clearInterval(keepAliveInterval);
    keepAliveInterval = setInterval(
        () => {
            log('info', 'DEVICE_I_AM_ALIVE');
        },
        1000 * 60 * 5,
    ); // every five minutes
}

export function slideshowPreloaded() {
    log('info', 'DEVICE_SLIDESHOW_PRELOADED');
}

export function slideshowRunning() {
    log('info', 'DEVICE_SLIDESHOW_RUNNING');
}

export function slideshowAbnormalSlideDuration(expectedDuration: number, actualDuration: number) {
    log('warn', 'DEVICE_SLIDESHOW_ABNORMAL_SLIDE_DURATION', { expectedDuration, actualDuration });
}

export function localcommConnected(deviceInfo: object) {
    log('info', 'DEVICE_LOCALCOMM_CONNECTED', { deviceInfo });
}

export function signalingConnected() {
    log('info', 'DEVICE_SIGNALING_CONNECTED');
}

export function signalingDisconnected() {
    log('warn', 'DEVICE_SIGNALING_DISCONNECTED');
}

export function signalingFailedToConnect() {
    log('error', 'DEVICE_SIGNALING_FAILED_TO_CONNECT');
}

export function signalingNoOrExpiredToken() {
    log('info', 'DEVICE_SIGNALING_NO_OR_EXPIRED_TOKEN');
}

export function signalingConnectionSignalReceived(signal: object) {
    log('info', 'DEVICE_SIGNALING_SIGNAL_RECEIVED', { signal });
}

export function barcodeScanned(barcode: string) {
    log('info', 'DEVICE_BARCODE_SCANNED', { barcode });
}

export function robotReadyToDeliverStatus(ready: boolean) {
    if (ready) {
        log('info', 'DEVICE_ROBOT_READY_TO_DELIVER');
    } else {
        log('warn', 'DEVICE_ROBOT_NOT_READY_TO_DELIVER');
    }
}

export function deviceBackendSystemUsage(usage: object) {
    log('info', 'DEVICE_BACKEND_SYSTEM_USAGE', { usage });
}

export function triggeredAutomaticRefresh() {
    log('info', 'DEVICE_TRIGGERED_AUTOMATIC_REFRESH');
}

export function cannotTriggerAutomaticRefresh(reason: string) {
    log('warn', 'DEVICE_CANNOT_TRIGGER_AUTOMATIC_REFRESH', { reason });
}

export function refreshedDueToError() {
    log('error', 'DEVICE_REFRESHED_DUE_TO_ERROR');
}

import { Domain } from 'api';

import getLocalizedImages from './getLocalizedImages';
import getPreferredProductImageURL from './getPreferredProductImageURL';

export default function mapProductToSlideshowProduct(
    product: Domain.ProductWall['products'][0] | Domain.CustomPage['products'][0],
    locale: Domain.Locale,
): Domain.SlideshowProduct {
    const images = getLocalizedImages(product.images, locale);
    let frontalImages = getLocalizedImages(product.frontals || [], locale);
    frontalImages = frontalImages.sort((a, b) => {
        return parseInt(a.id) - parseInt(b.id);
    });

    const productImage = images.find(image => image.type === 'packshot') || images[0];
    const frontalImage = frontalImages.length > 0 ? frontalImages[0] : undefined;

    if (!(product as any).details) {
        (product as any).details = {};
    }

    return {
        productId: product.productId,
        frontalImage: getPreferredProductImageURL(frontalImage, true) || '',
        allFrontals: (frontalImages || [])
            .map(image => {
                const url = getPreferredProductImageURL(image, true) || '';
                if (url) {
                    return {
                        id: image.id,
                        url,
                    };
                }
            })
            .filter(Boolean) as Domain.SlideshowProductWithFrontalImage['allFrontals'],
        productImage: productImage ? getPreferredProductImageURL(productImage, true) || '' : '',
        allPackshots: (images || [])
            .map(image => {
                const url = getPreferredProductImageURL(image, true) || '';
                if (url) {
                    return {
                        id: image.id,
                        url,
                    };
                }
            })
            .filter(Boolean) as Domain.SlideshowProductWithProductImage['allPackshots'],
        width: (product as any).details.length || 0,
        depth: (product as any).details.depth || 0,
        length: (product as any).details.length || 0,
        localizedNames: product.localizedNames,
        productCodes: product.productCodes,
        price: product.priceIncludingVat || undefined,
        promoPrice: product.promotionalPriceIncludingVat || undefined,
        robotStock: product.stock.find(stockItem => stockItem.stockType === 'robot')?.quantity,
    };
}

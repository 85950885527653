import { Domain } from 'api';
import { browserStorage } from 'utils';

import { ThunkAction } from '@/action';
import { deviceApi, scheduleApi, slideshowApi, timelineApi, branchApi, integrationApi } from '@/api';

import { DEVICE_CONTENT_HASH_KEY } from './constants';
import { reducerActions } from './deviceState';
import { selectSchedule } from './selectors';
import { getDeviceContentHash } from './utils';

export const loadDeviceDetails =
    (deviceId: string): ThunkAction<Promise<Domain.DeviceDetails>> =>
    async dispatch => {
        const device = await deviceApi.GetDeviceDetails(deviceId);
        const deviceContentHash = getDeviceContentHash(device);
        const cachedDeviceContentHash = browserStorage.getItem(DEVICE_CONTENT_HASH_KEY);

        // tslint:disable-next-line:no-console
        console.info('DEVICE', device.deviceId, '/', device.groupName, '/', device.name);

        if (!cachedDeviceContentHash || cachedDeviceContentHash.value !== deviceContentHash) {
            browserStorage.setItem(DEVICE_CONTENT_HASH_KEY, deviceContentHash, true);
        }

        dispatch(reducerActions.setDevice(device));

        return device;
    };

export const loadBranchDetails =
    (branchId: string): ThunkAction<Promise<Domain.BranchDetails>> =>
    async dispatch => {
        const branch = await branchApi.GetBranchDetails(branchId);

        dispatch(reducerActions.setBranch(branch));

        return branch;
    };

export const loadDeviceScheduleAndSlideshows = (): ThunkAction => async (dispatch, getState) => {
    const state = getState();
    let schedule = selectSchedule(state);

    if (!schedule) {
        schedule = await scheduleApi.GetScheduleForDevice();
        dispatch(reducerActions.setSchedule(schedule));
    }

    const promises: {
        [key: string]: Promise<void>;
    } = {};

    for (const slot of schedule) {
        if (slot.slideshowId && !promises.hasOwnProperty(slot.slideshowId)) {
            promises[slot.slideshowId] = (async () => {
                if (slot.slideshowId) {
                    const slideshow = await slideshowApi.GetSlideshowDetails(slot.slideshowId);
                    dispatch(reducerActions.addSlideshow(slideshow));
                }
            })();
        }

        if (slot.slideshowId && slot.timelineId && !promises.hasOwnProperty(slot.slideshowId + ':' + slot.timelineId)) {
            promises[slot.slideshowId + ':' + slot.timelineId] = (async () => {
                if (slot.slideshowId && slot.timelineId) {
                    const timeline = await timelineApi.GetTimelineDetails(slot.slideshowId, slot.timelineId);
                    dispatch(reducerActions.addTimeline(timeline));
                }
            })();
        }
    }

    await Promise.all(Object.values(promises));
};

export const loadConfiguredIntegrations = (): ThunkAction<Promise<Domain.ConfiguredIntegration[]>> => async dispatch => {
    const result = await integrationApi.GetConfiguredIntegrationsForDevice(
        {
            page: 1,
            size: 99,
        },
        {
            field: 'activeStatus',
            direction: 'ascending',
        },
    );

    dispatch(reducerActions.setConfiguredIntegrations(result.items));

    return result.items;
};
export const loadIntegrationPaymentMethods =
    (mtMaticPayConfiguredIntegrationId: string): ThunkAction<Promise<void>> =>
    async dispatch => {
        try {
            const paymentMethods = await integrationApi.GetIntegrationPaymentMethods(mtMaticPayConfiguredIntegrationId);
            dispatch(reducerActions.setIntegrationPaymentMethods(paymentMethods));
        } catch (error) {
            console.error(error);
        }
    };

import { Domain } from 'api';

import { getCountryCodeFromLocale } from '@/getCountryCodeFromLocale';

import { isCustomPageSlideContents, isProductWallSlideContents, Slide } from './types';

export function getSlideId(slide: Slide) {
    if (isProductWallSlideContents(slide)) {
        return slide.productWallId;
    } else if (isCustomPageSlideContents(slide)) {
        return slide.customPageId;
    }

    return slide.mediaItemId;
}

export function getDeviceContentHash(device: Domain.DeviceDetails): string {
    return `${device.contentVersion || 0}`;
}

export function getDeviceCountry(device?: Domain.DeviceDetails): Domain.Country {
    const deviceCountry: Domain.Country =
        !device || device.companyLocales.length === 0 ? 'BE' : getCountryCodeFromLocale(device.companyLocales[0]);

    return deviceCountry;
}

export function getDeviceHasPharmacyOnDutyInformation(deviceCountry: Domain.Country, pharmacyApb: string | null | undefined): boolean {
    return (deviceCountry === 'BE' && !!pharmacyApb) || deviceCountry === 'LU' || deviceCountry === 'DE';
}

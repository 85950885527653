import * as React from 'react';
import { EditorValue } from 'react-rte';

import { EditorState } from 'draft-js';

import { EyedropStartHandler } from '@/colorPicker/ControlledColorPicker';

import { HTMLEditorContext } from './HTMLEditorContext';
import {
    blockTypeControls,
    inlineStyleControls,
    textAlignControls,
    listControls,
    linkControls,
    fontFamilyControls,
    fontSizeControls,
    textColorControls,
} from './toolbarControls';

export default function HTMLEditorToolbar(
    props: {
        gettext: (text: string) => string;
        refocus: () => void;
        handleChange: (newValue: EditorValue) => void;
        visionToolbar?: boolean;
        presetColors?: (string | undefined)[];
        onEyedropStart?: EyedropStartHandler;
        onEyedropEnd?: () => void;
    } & ToolbarOptions,
) {
    const { gettext, refocus, handleChange, visionToolbar, presetColors, onEyedropStart, onEyedropEnd } = props;
    const context = React.useContext(HTMLEditorContext);
    const config = getToolbarConfig({
        gettext,
        refocus,
        handleChange: newValue => {
            context.set({
                ...context,
                editorState: newValue.getEditorState(),
            });
            context.editorValueSetter(newValue);
            handleChange(newValue);
        },
        visionToolbar,
        presetColors,
        onEyedropStart,
        onEyedropEnd,
        showColorControls: props.showColorControls,
    });

    return (
        <React.Fragment>
            {config.map(item => {
                return item(undefined, undefined, context.editorState);
            })}
        </React.Fragment>
    );
}

type ToolbarFunc = (_1: any, _2: any, state: EditorState) => React.ReactNode;

export interface ToolbarOptions {
    showColorControls?: boolean;
    hideLinkControls?: boolean;
}

export function getToolbarConfig(
    options: {
        gettext: (text: string) => string;
        refocus: () => void;
        handleChange: (newValue: EditorValue) => void;
        visionToolbar?: boolean;
        presetColors?: (string | undefined)[];
        onEyedropStart?: EyedropStartHandler;
        onEyedropEnd?: () => void;
    } & ToolbarOptions,
): ToolbarFunc[] {
    if (!options.visionToolbar) {
        const config: (ToolbarFunc | false)[] = [
            (_1, _2, state) => blockTypeControls(options.gettext, state, options.refocus, options.handleChange, 'c-1'),
            () => <em key="div-1" />,
            (_1, _2, state) => inlineStyleControls(state, options.refocus, options.handleChange, 'c-2'),
            () => (options.showColorControls ? <em key="div-2" /> : <span key="div-2" />),
            options.showColorControls
                ? (_1, _2, state) =>
                      textColorControls(state, options.refocus, options.handleChange, options.presetColors, 'textColorControls')
                : false,
            options.showColorControls ? () => <em key="div-3" /> : false,
            (_1, _2, state) => textAlignControls(state, options.refocus, options.handleChange, 'c-3'),
            () => <em key="div-4" />,
            !options.hideLinkControls ? (_1, _2, state) => listControls(state, options.refocus, options.handleChange, 'c-4') : false,
            !options.hideLinkControls ? () => <em key="div-5" /> : false,
            (_1, _2, state) => linkControls(options.gettext, state, options.refocus, options.handleChange, 'c-5'),
        ];

        return config.filter(Boolean) as ToolbarFunc[];
    }

    return [
        (_1, _2, state) => (
            <div
                className="VisionStyleToolbar"
                key="vision-style-toolbar"
            >
                <div>
                    {fontFamilyControls(options.gettext, state, options.refocus, options.handleChange)}
                    {fontSizeControls(state, options.refocus, options.handleChange)}
                    {textColorControls(
                        state,
                        options.refocus,
                        options.handleChange,
                        options.presetColors,
                        'textColorControls',
                        options.onEyedropStart,
                        options.onEyedropEnd,
                    )}
                </div>

                <div>
                    {inlineStyleControls(state, options.refocus, options.handleChange)}
                    {textAlignControls(state, options.refocus, options.handleChange)}
                    {listControls(state, options.refocus, options.handleChange)}
                </div>
            </div>
        ),
    ];
}

import * as React from 'react';
import { EditorValue } from 'react-rte';

import { RichUtils, Modifier, EditorState, EntityInstance, SelectionState } from 'draft-js';
import * as Immutable from 'immutable';
// @ts-ignore
import clearEntityForRange from 'react-rte/lib/lib/clearEntityForRange';
// @ts-ignore
import draftGetEntityAtCursor from 'react-rte/lib/lib/getEntityAtCursor';

import { fonts as baseFonts } from 'config';
import { preloadFont } from 'preloaders';
import { FuseSearchProvider } from 'utils';

import { Button } from '@/button';
import { Card } from '@/card';
import { EyedropStartHandler } from '@/colorPicker/ControlledColorPicker';
import { Dropdown } from '@/core';
import { Icon } from '@/icon';
import { Input } from '@/input';
import { Col, Container, Row } from '@/layout';
import StatefulColorPicker from '@/richText/StatefulColorPicker';
import { Select } from '@/select';
import { Slider } from '@/slider';
import { Toggle } from '@/toggle';
import { ToggleButton } from '@/toggleButton';

import { getBlockInlineStyleIfSameForEntireBlock, getSelectionCustomInlineStyle } from './draftjs-utils/inline';

import styles from '@/richText/RTControls.scss';

interface LinkControlValue {
    url: string;
    target: '_self' | '_blank';
}

const getActiveBlockType = (state: EditorState): string => {
    const selection = state.getSelection();
    return state.getCurrentContent().getBlockForKey(selection.getStartKey()).getType();
};

const getEntityAtCursor = (state: EditorState): EntityInstance | null => {
    const contentState = state.getCurrentContent();
    const entity = draftGetEntityAtCursor(state);
    return entity == null ? null : contentState.getEntity(entity.entityKey);
};

const handleBlockTypeChange = (blockType: string, state: EditorState, onChange?: (newValue: EditorValue) => void) => {
    if (onChange) {
        const selection = state.getSelection();
        let newState = RichUtils.toggleBlockType(state, blockType);

        const blocks = newState.getCurrentContent().getBlocksAsArray();
        for (const block of blocks) {
            let blockSelection = SelectionState.createEmpty(block.getKey());
            blockSelection = blockSelection.merge({
                anchorKey: block.getKey(),
                anchorOffset: 0,
                focusKey: block.getKey(),
                focusOffset: block.getLength(),
            });

            if (block.getType() === 'ordered-list-item' || block.getType() === 'unordered-list-item') {
                const blockStyles = getBlockInlineStyleIfSameForEntireBlock(newState, 'TEXT_COLOR', blockSelection);
                if (blockStyles.length === 1) {
                    newState = EditorState.push(
                        newState,
                        Modifier.mergeBlockData(
                            newState.getCurrentContent(),
                            blockSelection,
                            Immutable.Map({
                                bulletColor: blockStyles[0].replace('TEXT_COLOR:', ''),
                            }),
                        ),
                        'change-block-data',
                    );
                }
            } else {
                newState = EditorState.push(
                    newState,
                    Modifier.setBlockData(newState.getCurrentContent(), blockSelection, block.getData().remove('bulletColor')),
                    'change-block-data',
                );
            }
        }

        newState = EditorState.forceSelection(newState, selection);
        onChange(EditorValue.createFromState(newState));
    }
};

const handleSetLink = (state: EditorState, link: LinkControlValue, onChange?: (newValue: EditorValue) => void) => {
    let contentState = state.getCurrentContent();
    let selection = state.getSelection();
    const origSelection = selection;
    let canApplyLink = false;

    if (selection.isCollapsed()) {
        const entity = draftGetEntityAtCursor(state);
        if (entity) {
            canApplyLink = true;
            selection = selection.merge({
                anchorOffset: entity.startOffset,
                focusOffset: entity.endOffset,
                isBackward: false,
            });
        }
    } else {
        canApplyLink = true;
    }

    if (canApplyLink) {
        contentState = contentState.createEntity('LINK', 'MUTABLE', {
            url: link.url,
            target: link.target,
        });
        const entityKey = contentState.getLastCreatedEntityKey();

        // @ts-ignore
        let newState = EditorState.push(state, contentState);
        newState = RichUtils.toggleLink(newState, selection, entityKey);
        newState = EditorState.acceptSelection(newState, origSelection);

        if (onChange) {
            onChange(EditorValue.createFromState(newState));
        }
    }
};

const handleRemoveLink = (state: EditorState, onChange?: (newValue: EditorValue) => void) => {
    const entity = draftGetEntityAtCursor(state);
    if (entity !== null) {
        const newState = clearEntityForRange(state, entity.blockKey, entity.startOffset, entity.endOffset);

        if (onChange) {
            onChange(EditorValue.createFromState(newState));
        }
    }
};

interface IFontOption {
    label: string;
    value: string;
}

const fontOptions: IFontOption[] = baseFonts.map(font => {
    return {
        label: font.label,
        value: font.family,
    };
});

const fontsSearchProvider = new FuseSearchProvider(fontOptions, {
    includeMatches: false,
    includeScore: false,
    keys: ['label'],
    isCaseSensitive: false,
    threshold: 0.3,
    distance: 5,
});

function replaceCustomInlineStyle(state: EditorState, stylePrefix: string, styleValue: string): EditorState {
    const selection = state.getSelection();
    let contentState = state.getCurrentContent();

    getSelectionCustomInlineStyle(state, stylePrefix).forEach(style => {
        if (style && style.indexOf(stylePrefix + ':') === 0) {
            contentState = Modifier.removeInlineStyle(contentState, selection, style);
        }
    });

    contentState = Modifier.applyInlineStyle(contentState, selection, stylePrefix + ':' + styleValue);
    return EditorState.push(state, contentState, 'change-inline-style');
}

export function blockTypeControls(
    gettext: (text: string) => string,
    state: EditorState,
    refocus: () => void,
    onChange?: (newValue: EditorValue) => void,
    key?: string,
): React.ReactNode {
    return (
        <Select
            key={key}
            value={getActiveBlockType(state)}
            onChange={(newBlockType: string) => {
                handleBlockTypeChange(newBlockType, state, onChange);
                refocus();
            }}
            options={[
                { label: gettext('Body text'), value: 'unstyled' },
                { label: gettext('H1'), value: 'header-one' },
                { label: gettext('H2'), value: 'header-two' },
                { label: gettext('H3'), value: 'header-three' },
                { label: gettext('H4'), value: 'header-four' },
                { label: gettext('H5'), value: 'header-five' },
                { label: gettext('H6'), value: 'header-six' },
            ]}
            smallHeader={true}
        />
    );
}

export function listControls(
    state: EditorState,
    refocus: () => void,
    onChange?: (newValue: EditorValue) => void,
    key?: string,
): React.ReactNode {
    const activeBlockType = getActiveBlockType(state);

    return (
        <Container key={key}>
            <Row>
                <Col>
                    <ToggleButton
                        isOn={activeBlockType === 'unordered-list-item'}
                        onChange={() => {
                            handleBlockTypeChange('unordered-list-item', state, onChange);
                            refocus();
                        }}
                        onMouseDown={e => e.preventDefault()}
                        size="xs"
                        startIcon={<Icon type="text_list_bullet" />}
                    />
                </Col>

                <Col>
                    <ToggleButton
                        isOn={activeBlockType === 'ordered-list-item'}
                        onChange={() => {
                            handleBlockTypeChange('ordered-list-item', state, onChange);
                            refocus();
                        }}
                        onMouseDown={e => e.preventDefault()}
                        size="xs"
                        startIcon={<Icon type="text_list_numbered" />}
                    />
                </Col>
            </Row>
        </Container>
    );
}

export function inlineStyleControls(
    state: EditorState,
    refocus: () => void,
    onChange?: (newValue: EditorValue) => void,
    key?: string,
): React.ReactNode {
    const activeInlineStyles = state.getCurrentInlineStyle();
    const handleStyleToggle = (style: string) => {
        if (onChange) {
            onChange(EditorValue.createFromState(RichUtils.toggleInlineStyle(state, style)));
        }
    };

    return (
        <Container key={key}>
            <Row>
                <Col>
                    <ToggleButton
                        isOn={activeInlineStyles.has('BOLD')}
                        onChange={() => handleStyleToggle('BOLD')}
                        onMouseDown={e => {
                            e.preventDefault();
                            refocus();
                        }}
                        size="xs"
                        startIcon={<Icon type="text_bold" />}
                    />
                </Col>

                <Col>
                    <ToggleButton
                        isOn={activeInlineStyles.has('ITALIC')}
                        onChange={() => handleStyleToggle('ITALIC')}
                        onMouseDown={e => {
                            e.preventDefault();
                            refocus();
                        }}
                        size="xs"
                        startIcon={<Icon type="text_italic" />}
                    />
                </Col>

                <Col>
                    <ToggleButton
                        isOn={activeInlineStyles.has('UNDERLINE')}
                        onChange={() => handleStyleToggle('UNDERLINE')}
                        onMouseDown={e => {
                            e.preventDefault();
                            refocus();
                        }}
                        size="xs"
                        startIcon={<Icon type="text_underlined" />}
                    />
                </Col>

                <Col>
                    <ToggleButton
                        isOn={activeInlineStyles.has('STRIKETHROUGH')}
                        onChange={() => handleStyleToggle('STRIKETHROUGH')}
                        onMouseDown={e => {
                            e.preventDefault();
                            refocus();
                        }}
                        size="xs"
                        startIcon={<Icon type="text_strikethrough" />}
                    />
                </Col>
            </Row>
        </Container>
    );
}

export function fontFamilyControls(
    gettext: (text: string) => string,
    state: EditorState,
    refocus: () => void,
    onChange?: (newValue: EditorValue) => void,
    key?: string,
): React.ReactNode {
    const activeInlineStyles = state.getCurrentInlineStyle();
    let selectedFontFamily = 'Calibre, sans-serif';

    const fontFamilyStyle = activeInlineStyles.find(style => {
        if (style) {
            return style.indexOf('FONT_FAMILY:') === 0;
        }

        return false;
    });

    if (fontFamilyStyle) {
        selectedFontFamily = fontFamilyStyle.replace('FONT_FAMILY:', '');
    }

    const handleStyleToggle = (newFontFamily: string) => {
        preloadFont(newFontFamily);
        if (onChange) {
            onChange(EditorValue.createFromState(replaceCustomInlineStyle(state, 'FONT_FAMILY', newFontFamily)));
        }
    };

    return (
        <Container
            key={key}
            className={styles.RTControls}
        >
            <Row>
                <Col>
                    <Select
                        className={styles.Control + ' ' + styles.FontControl}
                        bodyClassName={styles.RTControlsFontControl}
                        smallHeader={true}
                        value={selectedFontFamily}
                        onChange={(newFontFamily: string) => {
                            handleStyleToggle(newFontFamily);
                            refocus();
                        }}
                        withSearch={true}
                        searchProvider={fontsSearchProvider}
                        searchPlaceholder={gettext('Font name')}
                        noSearchResultsPlaceholder={gettext('No matching font found')}
                        searchInProgressPlaceholder={gettext('Searching...')}
                    />
                </Col>
            </Row>
        </Container>
    );
}

export function fontSizeControls(
    state: EditorState,
    refocus: () => void,
    onChange?: (newValue: EditorValue) => void,
    key?: string,
): React.ReactNode {
    const fontSizeInput = React.createRef<HTMLInputElement>();
    const activeInlineStyles = state.getCurrentInlineStyle();
    let selectedFontSize = 18;

    const fontSizeStyle = activeInlineStyles.find(style => {
        if (style) {
            return style.indexOf('FONT_SIZE:') === 0;
        }

        return false;
    });

    if (fontSizeStyle) {
        selectedFontSize = parseInt(fontSizeStyle.replace('FONT_SIZE:', ''), 10);
    }

    const handleStyleToggle = (newFontSize: number) => {
        if (onChange) {
            onChange(EditorValue.createFromState(replaceCustomInlineStyle(state, 'FONT_SIZE', newFontSize.toString(10))));
        }
    };

    return (
        <Container
            key={key}
            className={styles.RTControls}
        >
            <Row>
                <Col>
                    <Dropdown
                        className={styles.Control + ' ' + styles.FontSizeControl}
                        bodyClassName={styles.RTControlsFontControl}
                        headerRenderer={() => (
                            <Input
                                ref={fontSizeInput}
                                type="number"
                                value={selectedFontSize as any}
                                min={1}
                                max={200}
                                onChange={newSize => {
                                    handleStyleToggle(parseInt(newSize, 10) || 18);
                                    refocus();
                                }}
                            />
                        )}
                        headerArrow={true}
                        body={
                            <Card elevated={true}>
                                <Slider
                                    defaultValue={selectedFontSize}
                                    min={1}
                                    max={200}
                                    step={1}
                                    onChangeComplete={newSize => {
                                        handleStyleToggle(newSize || 18);
                                        refocus();
                                    }}
                                    valueLabel={true}
                                />
                            </Card>
                        }
                        disableFocus={true}
                        onClose={(fromClick: boolean) => {
                            if (fromClick && fontSizeInput.current) {
                                fontSizeInput.current.focus();
                            }
                        }}
                    />
                </Col>
            </Row>
        </Container>
    );
}

export function textColorControls(
    state: EditorState,
    refocus: () => void,
    onChange?: (newValue: EditorValue) => void,
    presetColors?: (string | undefined)[],
    key?: string,
    onEyedropStart?: EyedropStartHandler,
    onEyedropEnd?: () => void,
): React.ReactNode {
    const activeInlineStyles = state.getCurrentInlineStyle();
    let color = '';

    const textColorStyle = activeInlineStyles.find(style => {
        if (style) {
            return style.indexOf('TEXT_COLOR:') === 0;
        }

        return false;
    });

    if (textColorStyle) {
        color = textColorStyle.replace('TEXT_COLOR:', '');
    }

    const handleStyleToggle = (newTextColor: string) => {
        if (onChange) {
            let newState = replaceCustomInlineStyle(state, 'TEXT_COLOR', newTextColor);
            const selection = state.getSelection();
            const blocks = state.getCurrentContent().getBlocksAsArray();

            let insideBlock = false;
            let isFirstBlock = false;
            const isSingleBlock = selection.getStartKey() === selection.getEndKey();
            let isLastBlock = false;
            for (const block of blocks) {
                if (block.getKey() === selection.getStartKey()) {
                    insideBlock = true;
                    isFirstBlock = true;
                }

                if (block.getKey() === selection.getEndKey()) {
                    isLastBlock = true;
                }

                if (insideBlock) {
                    let blockIsFullySelected = true;
                    if (isSingleBlock) {
                        if (selection.getStartOffset() > 0 || selection.getEndOffset() < block.getLength()) {
                            blockIsFullySelected = false;
                        }
                    } else if (isFirstBlock) {
                        if (selection.getStartOffset() > 0) {
                            blockIsFullySelected = false;
                        }
                    } else if (isLastBlock) {
                        if (selection.getEndOffset() < block.getLength()) {
                            blockIsFullySelected = false;
                        }
                    }

                    if (blockIsFullySelected) {
                        let blockSelection = SelectionState.createEmpty(block.getKey());
                        blockSelection = blockSelection.merge({
                            anchorKey: block.getKey(),
                            anchorOffset: 0,
                            focusKey: block.getKey(),
                            focusOffset: block.getLength(),
                        });

                        newState = EditorState.push(
                            newState,
                            Modifier.mergeBlockData(
                                newState.getCurrentContent(),
                                blockSelection,
                                Immutable.Map({
                                    bulletColor: newTextColor,
                                }),
                            ),
                            'change-block-data',
                        );
                    }
                }

                isFirstBlock = false;
                isLastBlock = false;
                if (block.getKey() === selection.getEndKey()) {
                    insideBlock = false;
                }
            }

            newState = EditorState.forceSelection(newState, selection);

            onChange(EditorValue.createFromState(newState));
        }
    };

    return (
        <Container
            key={key}
            className={styles.RTControls}
        >
            <Row>
                <Col>
                    <StatefulColorPicker
                        className={styles.Control + ' ' + styles.ColorControl}
                        value={color}
                        onChangeComplete={newColor => {
                            handleStyleToggle(newColor || '');
                            refocus();
                        }}
                        presetColors={presetColors}
                        onEyedropStart={onEyedropStart}
                        onEyedropEnd={onEyedropEnd}
                    />
                </Col>
            </Row>
        </Container>
    );
}

export function textAlignControls(
    state: EditorState,
    refocus: () => void,
    onChange?: (newValue: EditorValue) => void,
    key?: string,
): React.ReactNode {
    const content = state.getCurrentContent();
    const selection = state.getSelection();
    const blockKey = selection.getStartKey();
    const block = content.getBlockForKey(blockKey);
    const activeBlockAlignment = block.getData().get('textAlign');

    const handleAlignToggle = (textAlign: string) => {
        if (onChange) {
            const newSelection = state.getSelection();
            const existingContent = state.getCurrentContent();
            const newBlockKey = newSelection.getStartKey();
            const existingBlock = existingContent.getBlockForKey(newBlockKey);
            const existingBlockData = existingBlock.getData();

            const newBlockData = existingBlockData.remove('textAlign').set('textAlign', textAlign);
            const newBlock = existingBlock.set('data', newBlockData);
            const newContent = existingContent.merge({
                blockMap: existingContent.getBlockMap().set(newBlockKey, newBlock as any),
            });
            const newState = EditorState.push(state, newContent as any, 'change-block-data');

            onChange(EditorValue.createFromState(newState));
        }
    };

    return (
        <Container key={key}>
            <Row>
                <Col>
                    <ToggleButton
                        isOn={activeBlockAlignment === 'ALIGN_JUSTIFY'}
                        onChange={() => handleAlignToggle('ALIGN_JUSTIFY')}
                        onMouseDown={e => {
                            e.preventDefault();
                            refocus();
                        }}
                        size="xs"
                        startIcon={<Icon type="text_align_full" />}
                    />
                </Col>

                <Col>
                    <ToggleButton
                        isOn={activeBlockAlignment === 'ALIGN_CENTER'}
                        onChange={() => handleAlignToggle('ALIGN_CENTER')}
                        onMouseDown={e => {
                            e.preventDefault();
                            refocus();
                        }}
                        size="xs"
                        startIcon={<Icon type="text_align_center" />}
                    />
                </Col>

                <Col>
                    <ToggleButton
                        isOn={activeBlockAlignment === 'ALIGN_LEFT'}
                        onChange={() => handleAlignToggle('ALIGN_LEFT')}
                        onMouseDown={e => {
                            e.preventDefault();
                            refocus();
                        }}
                        size="xs"
                        startIcon={<Icon type="text_align_left" />}
                    />
                </Col>

                <Col>
                    <ToggleButton
                        isOn={activeBlockAlignment === 'ALIGN_RIGHT'}
                        onChange={() => handleAlignToggle('ALIGN_RIGHT')}
                        onMouseDown={e => {
                            e.preventDefault();
                            refocus();
                        }}
                        size="xs"
                        startIcon={<Icon type="text_align_right" />}
                    />
                </Col>
            </Row>
        </Container>
    );
}

export function linkControls(
    gettext: (text: string) => string,
    state: EditorState,
    refocus: () => void,
    onChange?: (newValue: EditorValue) => void,
    key?: string,
): React.ReactNode {
    const selection = state.getSelection();
    const entity = getEntityAtCursor(state);
    const hasSelection = !selection.isCollapsed();
    const isCursorOnLink = entity !== null && entity.getType() === 'LINK';
    const shouldShowLinkButton = hasSelection || isCursorOnLink;
    const url = entity && isCursorOnLink ? entity.getData().url : '';
    const target = entity && isCursorOnLink ? entity.getData().target : '_self';

    return (
        <Container key={key}>
            <Row>
                <Col>
                    <LinkControl
                        gettext={gettext}
                        isOn={isCursorOnLink}
                        disabled={!shouldShowLinkButton}
                        isActive={url}
                        refocus={refocus}
                        value={{
                            url,
                            target,
                        }}
                        onChange={newValue => handleSetLink(state, newValue, onChange)}
                        onClear={() => handleRemoveLink(state, onChange)}
                    />
                </Col>
            </Row>
        </Container>
    );
}

function LinkControl(props: {
    gettext: (text: string) => string;
    isOn: boolean;
    disabled: boolean;
    isActive: boolean;
    refocus: () => void;
    value: LinkControlValue;
    onChange: (newValue: LinkControlValue) => void;
    onClear: () => void;
}) {
    const dropdown = React.useRef<Dropdown>();
    const { gettext } = props;
    const [url, setUrl] = React.useState(props.value.url);
    const [target, setTarget] = React.useState(props.value.target || '_self');

    React.useEffect(() => {
        setUrl(props.value.url);
        setTarget(props.value.target || '_self');
    }, [props.value]);

    return (
        <Dropdown
            ref={dropdown as any}
            headerRenderer={() => {
                return (
                    <ToggleButton
                        isOn={props.isOn}
                        disabled={props.disabled}
                        active={props.isActive}
                        size="xs"
                        startIcon={<Icon type="text_insert_link" />}
                    />
                );
            }}
            body={
                <Card
                    elevated={true}
                    className="mt-11"
                >
                    <Container gutter={14}>
                        <Row>
                            <Col fullWidth={true}>
                                <Input
                                    label={gettext('URL')}
                                    value={url}
                                    onChange={setUrl}
                                    iconRight={
                                        url ? (
                                            <Button
                                                variant="plain"
                                                className="px-0"
                                                onClick={() => {
                                                    setUrl('');
                                                }}
                                                startIcon={<Icon type="action_remove" />}
                                            />
                                        ) : null
                                    }
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col fullWidth={true}>
                                <Toggle
                                    isOn={target === '_blank'}
                                    onChange={isOn => {
                                        setTarget(isOn ? '_blank' : '_self');
                                    }}
                                >
                                    {gettext('Open in new window/tab')}
                                </Toggle>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Button
                                    variant="primary"
                                    variantSize="xs"
                                    onClick={() => {
                                        if (!url) {
                                            props.onClear();
                                        } else {
                                            props.onChange({
                                                ...props.value,
                                                url,
                                                target,
                                            });
                                        }

                                        if (dropdown && dropdown.current) {
                                            dropdown.current.close();
                                        }
                                    }}
                                >
                                    {gettext('Save')}
                                </Button>
                            </Col>

                            {props.isOn ? (
                                <Col>
                                    <Button
                                        variant="secondary"
                                        variantSize="xs"
                                        onClick={() => {
                                            props.onClear();
                                            if (dropdown && dropdown.current) {
                                                dropdown.current.close();
                                            }
                                        }}
                                        startIcon={<Icon type="action_remove" />}
                                    >
                                        {gettext('Remove')}
                                    </Button>
                                </Col>
                            ) : null}

                            <Col>
                                <Button
                                    variant="secondary"
                                    variantSize="xs"
                                    onClick={() => {
                                        if (dropdown && dropdown.current) {
                                            dropdown.current.close();
                                        }
                                    }}
                                >
                                    {gettext('Cancel')}
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </Card>
            }
            onClose={() => {
                props.refocus();
            }}
        />
    );
}

import { Domain } from 'api';
import { reducer as canvasReducer, Actions as CanvasActions, getMaxFrameDepth } from 'editor-canvas';

import { Frame, Layout, PlacedProduct, Shelf, ShelfStyleParameters } from '../types';
import { computeProductSize, computeMaxUsableScale, getSelectedImage } from '../utils';
import * as Actions from './actions';
import { ProductWallState } from './state';

const defaults = {
    draggedProduct: undefined,
    draggedPlacedProductId: undefined,
    resizedProductId: undefined,
    placedProductSettingsDialog: undefined,
    undoStack: [],
    redoStack: [],
    draggedProductPosition: {
        x: 0,
        y: 0,
    },
    draggedProductDroppedFlag: false,
    draggedBlockType: undefined,
    draggedBlockPosition: {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
    },
    draggedBlockDroppedFlag: false,
    draggingShelf: false,
    generalSettingsVisible: false,
    eyedropperEnabled: false,
    eyedropperToolFrameInfo: {
        frameId: undefined,
        isDown: false,
        x: 0,
        y: 0,
        w: 1,
        h: 1,
    },
};

const initLayout: Layout = {
    width: 429,
    height: 764,
    style: 'style3D',
    shelves: [
        {
            id: 0,
            type: 'customShelf',
            y: 0,
            height: 88,
        },
        {
            id: 1,
            type: 'emptyShelf',
            y: 88,
            height: 764 - 88,
        },
    ],
    frames: [
        {
            frameId: '0',
            type: 'text',
            x: 10,
            y: 12,
            width: 409,
            height: 66,
            text: '',
            textStyle: {
                size: 20,
                font: 'Roboto, sans-serif',
                color: '#12a3d8',
                align: 'center',
                verticalAlign: 'center',
            },
        },
    ],
    backgroundType: 'color',
    backgroundColor: '#4d8294',
    backgroundMediaItemId: '',
    priceStyle: {
        shape: 'rect-top-left',
        text: {
            color: '#FFFFFF',
            align: 'center',
            font: 'Roboto, sans-serif',
            size: 11,
        },
        backgroundColor: '#FFA500',
        borderColor: '#DE8F00',
    },
    promoPriceStyle: {
        shape: 'circle',
        text: {
            color: '#0E3E78',
            align: 'center',
            font: 'Roboto, sans-serif',
            size: 11,
        },
        promoText: {
            color: '#FFFFFF',
            align: 'center',
            font: 'Roboto, sans-serif',
            size: 11,
        },
        backgroundColor: '#41AAEB',
        borderColor: '#41AAEB',
    },
    inStockStyle: {
        text: {
            color: '#FFFFFF',
            align: 'center',
            font: 'Roboto, sans-serif',
            size: 15,
        },
        backgroundColor: '#7bcbbc',
        borderColor: '#1FCCB3',
    },
    outOfStockStyle: {
        text: {
            color: '#FFFFFF',
            align: 'center',
            font: 'Roboto, sans-serif',
            size: 15,
        },
        backgroundColor: '#CC7D9E',
        borderColor: '#CC1F51',
    },
};

export const initialState: ProductWallState = {
    canvas: initLayout,
    availableProducts: [],
    stock: {},
    productImageSizes: {},
    screenResolution: '1080x1920',
    initialState: JSON.stringify(initLayout),
    ...defaults,
};

export function productWallReducer(state = initialState, action: Actions.ActionTypes): ProductWallState {
    switch (action.type) {
        case Actions.SET_AVAILABLE_PRODUCTS:
            return {
                ...state,
                availableProducts: action.availableProducts,
                canvas: {
                    ...state.canvas,
                    shelves: removeMissingProductsFromShelves(state.canvas.shelves, action.availableProducts),
                },
            };

        case Actions.SET_STOCK:
            return {
                ...state,
                stock: action.stock,
            };

        case Actions.UPDATE_AVAILABLE_PRODUCT:
            return {
                ...state,
                availableProducts: [
                    ...state.availableProducts.filter(availableProduct => availableProduct.productId !== action.availableProduct.productId),
                    action.availableProduct,
                ],
            };

        case Actions.SET_PRODUCT_IMAGE_SIZE:
            return ensureProductScalingIsCorrect({
                ...state,
                productImageSizes: {
                    ...state.productImageSizes,
                    [action.imageUrl]: {
                        width: action.width,
                        height: action.height,
                    },
                },
            });

        case Actions.SET_SHELVES:
            return ensureProductScalingIsCorrect({
                ...state,
                canvas: ensureLastShelf({
                    ...state.canvas,
                    shelves: ensureShelvesAreYSorted(action.shelves),
                }),
            });

        case Actions.SPLIT_SHELF:
            return deepMapShelves(shelves => {
                const shelfToSplit = shelves.find(shelf => shelf.id === action.shelfId);

                if (!shelfToSplit) {
                    throw new Error(`Shelf with id ${action.shelfId} does not exist.`);
                }

                const splitY = shelfToSplit.y;

                return [
                    ...shelves.map(shelf => {
                        if (shelf.id === action.shelfId) {
                            shelf.y += action.height;
                            shelf.height -= action.height;
                        }
                        return shelf;
                    }),

                    {
                        id: nextShelfId(shelves),
                        type: 'productsShelf',
                        y: splitY,
                        height: action.height,
                        products: [],
                    },
                ];
            }, state);

        case Actions.SET_SHELF_Y_OFFSET:
            return deepMapShelf(
                shelf => {
                    if (action.position === 'self') {
                        shelf.height += action.offset;
                    } else if (action.position === 'below') {
                        shelf.y += action.offset;
                        shelf.height -= action.offset;
                    } else if (action.position === 'above') {
                        shelf.height += action.offset;
                    }

                    if (shelf.type === 'productsShelf') {
                        shelf.products = [
                            ...shelf.products.map(product => {
                                if (product.height > shelf.height) {
                                    product.height = shelf.height;
                                }
                                return product;
                            }),
                        ];
                    }

                    return shelf;
                },
                state,
                action.shelfId,
            );

        case Actions.ADD_PRODUCTS_SHELF:
            return deepMapShelf(
                shelf => {
                    return {
                        ...shelf,
                        type: 'productsShelf',
                        products: [],
                    };
                },
                state,
                action.shelfId,
            );

        case Actions.ADD_CUSTOM_SHELF:
            return deepMapShelf(
                shelf => {
                    return {
                        ...shelf,
                        type: 'customShelf',
                    };
                },
                state,
                action.shelfId,
            );

        case Actions.CLEAR_SHELF:
            return deepMapShelf(
                shelf => {
                    return {
                        id: shelf.id,
                        type: 'emptyShelf',
                        y: shelf.y,
                        height: shelf.height,
                    };
                },
                state,
                action.shelfId,
            );

        case Actions.REMOVE_SHELF:
            return deepMapShelves(shelves => [...shelves.filter(shelf => shelf.id !== action.shelfId)], state);

        case Actions.SET_DRAGGING_SHELF:
            return {
                ...state,
                draggingShelf: action.draggingShelf,
            };

        case Actions.SET_DRAGGED_PRODUCT:
            return {
                ...state,
                draggedProduct: action.draggedProduct,
            };

        case Actions.SET_DRAGGED_PRODUCT_POSITION:
            return {
                ...state,
                draggedProductPosition: {
                    x: action.x,
                    y: action.y,
                },
            };

        case Actions.SET_DRAGGED_PRODUCT_DROPPED_FLAG:
            return {
                ...state,
                draggedProductDroppedFlag: action.dropped,
            };

        case Actions.ADD_PRODUCT_TO_SHELF:
            const newPlacedProductId = nextPlacedProductId(state.canvas.shelves);
            return ensureProductScalingIsCorrect(
                deepMapShelfProducts(
                    products => {
                        return [
                            ...products,
                            {
                                id: newPlacedProductId,
                                productId: action.product.productId,
                                x: action.area.x,
                                width: action.area.width,
                                height: action.area.height,
                                scale: action.scale || 1,
                                enableItemsLimit: action.itemsLimit !== undefined && action.itemsLimit < 900,
                                itemsLimit: action.itemsLimit,
                                alignItems: action.alignItems || 'center',
                                spacingX: action.spacingX || 0,
                                spacingY: action.spacingY || 0,
                                imageId: action.imageId,
                                showPrice: action.showPrice,
                                enableCustomPriceStyling: action.enableCustomPriceStyling,
                                customPriceStyle: action.customPriceStyle,
                                customPromoPriceStyle: action.customPromoPriceStyle,
                                hideOriginalPrice: action.hideOriginalPrice,
                            },
                        ];
                    },
                    {
                        ...state,
                        resizedProductId: newPlacedProductId,
                        placedProductSettingsDialog: {
                            placedProductId: newPlacedProductId,
                        },
                        resizedFrameId: undefined,
                        editedFrameId: undefined,
                    },
                    action.shelfId,
                ),
            );

        case Actions.REMOVE_PRODUCT_FROM_SHELF:
            return deepMapShelfProducts(
                products => products.filter(product => product.id !== action.placedProductId),
                state,
                action.shelfId,
            );

        case Actions.SET_RESIZED_PRODUCT:
            return {
                ...state,
                resizedProductId: action.resizedProductId,
                resizedFrameId: undefined,
            };

        case Actions.SET_PLACED_PRODUCT_TOP_OFFSET:
            return deepMapProducts(
                product => {
                    product.height -= action.offset;
                    return product;
                },
                state,
                action.placedProductId,
                action.shelfId,
            );

        case Actions.SET_PLACED_PRODUCT_LEFT_OFFSET:
            return deepMapProducts(
                product => {
                    product.x += action.offset;
                    product.width -= action.offset;
                    return product;
                },
                state,
                action.placedProductId,
                action.shelfId,
            );

        case Actions.SET_PLACED_PRODUCT_RIGHT_OFFSET:
            return deepMapProducts(
                product => {
                    product.width += action.offset;
                    return product;
                },
                state,
                action.placedProductId,
                action.shelfId,
            );

        case Actions.SET_PLACED_PRODUCT_SIZE:
            return deepMapProducts(
                product => {
                    product.x = action.x;
                    product.width = action.width;
                    product.height = action.height;
                    product.scale = action.scale;
                    return product;
                },
                state,
                action.placedProductId,
                action.shelfId,
            );

        case Actions.SHOW_PLACED_PRODUCT_SETTINGS_DIALOG:
            return {
                ...state,
                placedProductSettingsDialog: {
                    placedProductId: action.placedProductId,
                },
                editedFrameId: undefined,
            };

        case Actions.HIDE_PLACED_PRODUCT_SETTINGS_DIALOG:
            return {
                ...state,
                placedProductSettingsDialog: undefined,
            };

        case Actions.SHOW_GENERAL_SETTINGS:
            return {
                ...state,
                generalSettingsVisible: true,
            };

        case Actions.HIDE_GENERAL_SETTINGS:
            return {
                ...state,
                generalSettingsVisible: false,
            };

        case Actions.SET_PLACED_PRODUCT_ITEMS_LIMIT_ENABLED:
            return deepMapProducts(
                product => {
                    product.enableItemsLimit = action.enabled;
                    return product;
                },
                state,
                action.placedProductId,
                action.shelfId,
            );

        case Actions.SET_PLACED_PRODUCT_ITEMS_LIMIT:
            return deepMapProducts(
                product => {
                    product.itemsLimit = action.limit;
                    return product;
                },
                state,
                action.placedProductId,
                action.shelfId,
            );

        case Actions.SET_PLACED_PRODUCT_SCALE:
            return deepMapProducts(
                product => {
                    product.scale = action.scale;
                    return product;
                },
                state,
                action.placedProductId,
                action.shelfId,
            );

        case Actions.SET_PLACED_PRODUCT_ALIGN:
            return deepMapProducts(
                product => {
                    product.alignItems = action.align;
                    return product;
                },
                state,
                action.placedProductId,
                action.shelfId,
            );

        case Actions.SET_PLACED_PRODUCT_X_SPACING:
            return deepMapProducts(
                product => {
                    product.spacingX = action.spacing;
                    return product;
                },
                state,
                action.placedProductId,
                action.shelfId,
            );

        case Actions.SET_PLACED_PRODUCT_Y_SPACING:
            return deepMapProducts(
                product => {
                    product.spacingY = action.spacing;
                    return product;
                },
                state,
                action.placedProductId,
                action.shelfId,
            );

        case Actions.SET_PLACED_PRODUCT_IMAGE_ID:
            return deepMapProducts(
                product => {
                    product.imageId = action.imageId;
                    product.mediaItemId = undefined;
                    return product;
                },
                state,
                action.placedProductId,
                action.shelfId,
            );

        case Actions.SET_PLACED_PRODUCT_MEDIA_ITEM_ID:
            return deepMapProducts(
                product => {
                    product.mediaItemId = action.mediaItemId;
                    return product;
                },
                state,
                action.placedProductId,
                action.shelfId,
            );

        case Actions.SET_PLACED_PRODUCT_SHOW_PRICE:
            return deepMapProducts(
                product => {
                    product.showPrice = action.showPrice;
                    return product;
                },
                state,
                action.placedProductId,
                action.shelfId,
            );

        case Actions.SET_PLACED_PRODUCT_HIDE_ORIGINAL_PRICE:
            return deepMapProducts(
                product => {
                    product.hideOriginalPrice = action.hideOriginalPrice;
                    return product;
                },
                state,
                action.placedProductId,
                action.shelfId,
            );

        case Actions.TOGGLE_PLACED_PRODUCTS_SHOW_PRICE:
            return deepMapProducts(product => {
                product.showPrice = action.setAllTo;
                return product;
            }, state);

        case Actions.TOGGLE_FORCE_HIDE_STOCK_DISPLAY:
            return {
                ...state,
                canvas: ensureLastShelf({
                    ...state.canvas,
                    forceHideStockDisplay: !state.canvas.forceHideStockDisplay,
                }),
            };

        case Actions.SET_PLACED_PRODUCT_ENABLE_CUSTOM_PRICE_STYLE:
            return deepMapProducts(
                product => {
                    product.enableCustomPriceStyling = action.enableCustomPriceStyling;
                    if (!product.customPriceStyle) {
                        product.customPriceStyle = {
                            ...state.canvas.priceStyle,
                        };
                    }
                    if (!product.customPromoPriceStyle) {
                        product.customPromoPriceStyle = {
                            ...state.canvas.promoPriceStyle,
                        };
                    }
                    return product;
                },
                state,
                action.placedProductId,
                action.shelfId,
            );

        case Actions.SET_DRAGGED_PLACED_PRODUCT:
            return {
                ...state,
                draggedProduct: action.draggedProduct,
                draggedPlacedProductId: action.placedProductId,
            };

        case Actions.SET_SHELF_STYLE:
            return {
                ...state,
                canvas: ensureLastShelf({
                    ...state.canvas,
                    style: action.style,
                }),
            };

        case Actions.SET_SHELF_STYLE_HEADER_COLOR:
            return {
                ...state,
                canvas: ensureLastShelf({
                    ...state.canvas,
                    headerColor: action.headerColor,
                }),
            };

        case Actions.SET_SHELF_STYLE_BACKGROUND_TYPE:
            return {
                ...state,
                canvas: ensureLastShelf({
                    ...state.canvas,
                    backgroundType: action.backgroundType,
                    backgroundMediaItemId: '',
                }),
            };

        case Actions.SET_SHELF_STYLE_BACKGROUND_COLOR:
            return {
                ...state,
                canvas: ensureLastShelf({
                    ...state.canvas,
                    backgroundColor: action.backgroundColor,
                }),
            };

        case Actions.SET_SHELF_STYLE_BACKGROUND_MEDIA_ITEM_ID:
            return {
                ...state,
                canvas: ensureLastShelf({
                    ...state.canvas,
                    backgroundMediaItemId: action.backgroundMediaItemId,
                    backgroundVideoDuration: action.backgroundVideoDuration,
                }),
            };

        case Actions.SET_SHELF_STYLE_PRICE_LABEL_SHAPE:
            return deepSetPriceStyleProp(
                state,
                {
                    shape: action.shape,
                },
                action.promo,
            );

        case Actions.SET_SHELF_STYLE_PRICE_LABEL_TEXT:
            return deepSetPriceStyleProp(
                state,
                {
                    text: action.text,
                },
                action.promo,
            );

        case Actions.SET_SHELF_STYLE_PRICE_LABEL_PROMO_TEXT:
            return deepSetPriceStyleProp(
                state,
                {
                    promoText: action.promoText,
                },
                true,
            );

        case Actions.SET_SHELF_STYLE_PRICE_LABEL_BACKGROUND_COLOR:
            return deepSetPriceStyleProp(
                state,
                {
                    backgroundColor: action.backgroundColor,
                },
                action.promo,
            );

        case Actions.SET_SHELF_STYLE_PRICE_LABEL_BORDER_COLOR:
            return deepSetPriceStyleProp(
                state,
                {
                    borderColor: action.borderColor,
                },
                action.promo,
            );

        case Actions.SET_PLACED_PRODUCT_CUSTOM_PRICE_STYLE_LABEL_SHAPE:
            return deepSetProductPriceLayoutProp(
                state,
                action.placedProductId,
                action.shelfId,
                {
                    shape: action.shape,
                },
                action.promo,
            );

        case Actions.SET_PLACED_PRODUCT_CUSTOM_PRICE_STYLE_LABEL_TEXT:
            return deepSetProductPriceLayoutProp(
                state,
                action.placedProductId,
                action.shelfId,
                {
                    text: action.text,
                },
                action.promo,
            );

        case Actions.SET_PLACED_PRODUCT_CUSTOM_PRICE_STYLE_LABEL_PROMO_TEXT:
            return deepSetProductPriceLayoutProp(
                state,
                action.placedProductId,
                action.shelfId,
                {
                    promoText: action.promoText,
                },
                true,
            );

        case Actions.SET_PLACED_PRODUCT_CUSTOM_PRICE_STYLE_LABEL_BACKGROUND_COLOR:
            return deepSetProductPriceLayoutProp(
                state,
                action.placedProductId,
                action.shelfId,
                {
                    backgroundColor: action.backgroundColor,
                },
                action.promo,
            );

        case Actions.SET_PLACED_PRODUCT_CUSTOM_PRICE_STYLE_LABEL_BORDER_COLOR:
            return deepSetProductPriceLayoutProp(
                state,
                action.placedProductId,
                action.shelfId,
                {
                    borderColor: action.borderColor,
                },
                action.promo,
            );

        case CanvasActions.ADD_FRAME:
            if (!state.draggedBlockType) {
                throw new Error('Cannot add frame while not dragging block');
            }

            const newFrame: Frame = {
                type: state.draggedBlockType,
                depth: getMaxFrameDepth(state.canvas.frames) + 1,
                ...action.frame,
            } as Frame;

            return {
                ...state,
                canvas: {
                    ...state.canvas,
                    frames: [...state.canvas.frames, newFrame],
                },
                draggedBlockType: undefined,
            };

        case Actions.SET_FRAME_TYPE:
            return deepMapFrame(
                frame => {
                    if (frame.type === 'image') {
                        frame.prevImageMediaItemId = frame.mediaItemId;
                    } else if (frame.type === 'video') {
                        frame.prevVideoMediaItemId = frame.mediaItemId;
                    }

                    frame.type = action.frameType;

                    if (frame.type === 'text') {
                        frame.text = '';
                        frame.textStyle = {
                            size: 16,
                        };
                    } else if (frame.type === 'image') {
                        frame.mediaItemId = frame.prevImageMediaItemId;
                    } else if (frame.type === 'video') {
                        frame.mediaItemId = frame.prevVideoMediaItemId;
                    } else if (frame.type === 'hotSpot') {
                        frame.hotSpotType = 'productDetails';
                    }

                    return frame;
                },
                state,
                action.frameId,
            );

        case Actions.SET_FRAME_TEXT:
            return deepMapFrame(
                frame => {
                    if (frame.type === 'text') {
                        frame.text = action.text;
                        frame.textStyle = action.textStyle;
                    }
                    return frame;
                },
                state,
                action.frameId,
            );

        case Actions.SET_FRAME_HTML:
            return deepMapFrame(
                frame => {
                    if (frame.type === 'richText') {
                        frame.html = action.html;
                    }
                    return frame;
                },
                state,
                action.frameId,
            );

        case Actions.SET_FRAME_MEDIA_ITEM_ID:
            return deepMapFrame(
                frame => {
                    if (frame.type === 'image' || frame.type === 'video') {
                        frame.mediaItemId = action.mediaItemId;
                        if (frame.type === 'image') {
                            frame.prevImageMediaItemId = frame.mediaItemId;
                        } else if (frame.type === 'video') {
                            frame.prevVideoMediaItemId = frame.mediaItemId;
                        }
                    }
                    if (frame.type === 'video') {
                        frame.videoDuration = action.videoDuration;
                    } else {
                        delete (frame as any).videoDuration;
                    }
                    return frame;
                },
                state,
                action.frameId,
            );

        case Actions.SET_FRAME_IMAGE_CROP:
            return deepMapFrame(
                frame => {
                    if (frame.type === 'image') {
                        frame.imageCrop = action.imageCrop;
                    }
                    return frame;
                },
                state,
                action.frameId,
            );

        case Actions.SET_FRAME_HOTSPOT_PRODUCT_ID:
            return deepMapFrame(
                frame => {
                    if (frame.type === 'hotSpot' && frame.hotSpotType === 'productDetails') {
                        frame.productId = action.productId;
                    }
                    return frame;
                },
                state,
                action.frameId,
            );

        case Actions.SET_EYEDROPPER_ENABLED:
            return {
                ...state,
                eyedropperEnabled: action.eyedropperEnabled,
                eyedropperChangeHandlers: action.changeHandlers,
                eyedropperToolFrameInfo: {
                    frameId: undefined,
                    shelfId: undefined,
                    placedProductId: undefined,
                    isDown: false,
                    x: 0,
                    y: 0,
                    w: 1,
                    h: 1,
                },
            };

        case Actions.SET_EYEDROPPER_TOOL_FRAME_COORDINATES:
            return {
                ...state,
                eyedropperToolFrameInfo: {
                    ...state.eyedropperToolFrameInfo,
                    frameId: action.frameId,
                    shelfId: action.shelfId,
                    placedProductId: action.placedProductId,
                    x: action.x,
                    y: action.y,
                    w: action.w,
                    h: action.h,
                },
            };

        case Actions.SET_EYEDROPPER_TOOL_FRAME_MOUSE_IS_DOWN:
            return {
                ...state,
                eyedropperToolFrameInfo: {
                    ...state.eyedropperToolFrameInfo,
                    frameId: action.frameId,
                    shelfId: action.shelfId,
                    placedProductId: action.placedProductId,
                    isDown: action.isDown,
                },
            };

        case Actions.SET_SHELF_STYLE_IN_STOCK_LABEL_TEXT:
            return deepSetStockStyleProp(
                state,
                {
                    text: action.text,
                },
                true,
            );

        case Actions.SET_SHELF_STYLE_IN_STOCK_LABEL_BACKGROUND_COLOR:
            return deepSetStockStyleProp(
                state,
                {
                    backgroundColor: action.backgroundColor,
                },
                true,
            );

        case Actions.SET_SHELF_STYLE_IN_STOCK_LABEL_BORDER_COLOR:
            return deepSetStockStyleProp(
                state,
                {
                    borderColor: action.borderColor,
                },
                true,
            );

        case Actions.SET_SHELF_STYLE_OUT_OF_STOCK_LABEL_TEXT:
            return deepSetStockStyleProp(
                state,
                {
                    text: action.text,
                },
                false,
            );

        case Actions.SET_SHELF_STYLE_OUT_OF_STOCK_LABEL_BACKGROUND_COLOR:
            return deepSetStockStyleProp(
                state,
                {
                    backgroundColor: action.backgroundColor,
                },
                false,
            );

        case Actions.SET_SHELF_STYLE_OUT_OF_STOCK_LABEL_BORDER_COLOR:
            return deepSetStockStyleProp(
                state,
                {
                    borderColor: action.borderColor,
                },
                false,
            );

        default:
            return ensureDefaults(canvasReducer(state, action) as ProductWallState);
    }
}

function ensureDefaults(state: ProductWallState): ProductWallState {
    return {
        ...state,
        canvas: {
            ...initLayout,
            ...state.canvas,
        },
    };
}

function deepMapProducts(mapper: (product: PlacedProduct) => PlacedProduct, state: ProductWallState, productId?: number, shelfId?: number) {
    return deepMapShelfProducts(
        products => [
            ...products.map(product => {
                if ((productId !== undefined && product.id === productId) || productId === undefined) {
                    return mapper(product);
                }
                return product;
            }),
        ],
        state,
        shelfId,
    );
}

function deepMapShelfProducts(
    mapper: (products: PlacedProduct[], shelf: Shelf) => PlacedProduct[],
    state: ProductWallState,
    shelfId?: number,
) {
    return deepMapShelf(
        shelf => {
            if (shelf.type === 'productsShelf') {
                return {
                    ...shelf,
                    products: [...mapper(shelf.products, shelf)],
                };
            }

            return shelf;
        },
        state,
        shelfId,
    );
}

function deepMapFrame(mapper: (frame: Frame) => Frame, state: ProductWallState, frameId: string) {
    return deepMapFrames(
        frames => [
            ...frames.map(frame => {
                if (frame.frameId === frameId) {
                    return mapper(frame);
                }

                return frame;
            }),
        ],
        state,
    );
}

function deepMapFrames(mapper: (frames: Frame[]) => Frame[], state: ProductWallState) {
    return {
        ...state,
        canvas: ensureLastShelf({
            ...state.canvas,
            frames: [...mapper(state.canvas.frames)],
        }),
    };
}

function deepMapShelf(mapper: (shelf: Shelf) => Shelf, state: ProductWallState, shelfId?: number) {
    return deepMapShelves(
        shelves => [
            ...shelves.map(shelf => {
                if ((shelfId !== undefined && shelf.id === shelfId) || shelfId === undefined) {
                    return mapper(shelf);
                }
                return shelf;
            }),
        ],
        state,
    );
}

function deepMapShelves(mapper: (shelves: Shelf[]) => Shelf[], state: ProductWallState) {
    return {
        ...state,
        canvas: ensureLastShelf({
            ...state.canvas,
            shelves: ensureShelvesAreYSorted([...mapper(state.canvas.shelves)]),
        }),
    };
}

function deepSetPriceStyleProp(
    state: ProductWallState,
    prop: {
        [key: string]: any;
    },
    promo?: boolean,
) {
    const style = promo ? 'promoPriceStyle' : 'priceStyle';
    return {
        ...state,
        canvas: ensureLastShelf({
            ...state.canvas,
            [style]: {
                ...state.canvas[style],
                ...prop,
            },
        }),
    };
}

function deepSetStockStyleProp(
    state: ProductWallState,
    prop: {
        [key: string]: any;
    },
    inStock?: boolean,
) {
    const style = inStock ? 'inStockStyle' : 'outOfStockStyle';
    return {
        ...state,
        canvas: ensureLastShelf({
            ...state.canvas,
            [style]: {
                ...state.canvas[style],
                ...prop,
            },
        }),
    };
}

function deepSetProductPriceLayoutProp(
    state: ProductWallState,
    productId: number,
    shelfId: number,
    prop: {
        [key: string]: any;
    },
    promo?: boolean,
) {
    const style = promo ? 'customPromoPriceStyle' : 'customPriceStyle';
    return deepMapProducts(
        product => {
            product[style] = {
                ...product[style],
                ...(prop as any),
            };
            return product;
        },
        state,
        productId,
        shelfId,
    );
}

function ensureLastShelf(layout: Layout): Layout {
    const { shelves } = layout;

    if (shelves.length === 0 || shelves[shelves.length - 1].type !== 'emptyShelf') {
        return {
            ...layout,
            shelves: [
                ...shelves,
                {
                    id: nextShelfId(shelves),
                    type: 'emptyShelf',
                    y: layout.height,
                    height: 0,
                },
            ],
        };
    }

    return layout;
}

function ensureShelvesAreYSorted(shelves: Shelf[]): Shelf[] {
    return [...shelves].sort((a, b) => a.y - b.y);
}

function ensureProductScalingIsCorrect(state: ProductWallState): ProductWallState {
    const LayoutParams = ShelfStyleParameters[state.canvas.style];

    return {
        ...state,
        canvas: {
            ...state.canvas,
            shelves: state.canvas.shelves.map(shelf => {
                if (shelf.type !== 'productsShelf') {
                    return shelf;
                }

                return {
                    ...shelf,
                    products: shelf.products.map(placeProduct => {
                        const availableProduct = state.availableProducts.find(
                            searchedProduct => searchedProduct.productId === placeProduct.productId,
                        );

                        if (!availableProduct) {
                            return placeProduct;
                        }

                        const selectedImage = getSelectedImage(placeProduct, availableProduct);
                        if (!state.productImageSizes.hasOwnProperty(selectedImage.url)) {
                            return placeProduct;
                        }

                        const productSize = computeProductSize(
                            state.screenResolution,
                            availableProduct,
                            state.productImageSizes[selectedImage.url],
                        );

                        if (productSize.width === 0 || productSize.height === 0) {
                            return placeProduct;
                        }

                        const maxScale = computeMaxUsableScale(
                            {
                                width: placeProduct.width,
                                height: placeProduct.height - LayoutParams.shelfBottomOffset,
                            },
                            productSize,
                            placeProduct.scale || 2,
                        );

                        if (!placeProduct.scale || placeProduct.scale > maxScale) {
                            return {
                                ...placeProduct,
                                scale: maxScale,
                            };
                        }

                        return placeProduct;
                    }),
                };
            }),
        },
    };
}

function removeMissingProductsFromShelves(shelves: Shelf[], availableProducts: Domain.SlideshowProduct[]): Shelf[] {
    return shelves.map(shelf => {
        if (shelf.type !== 'productsShelf') {
            return shelf;
        }

        return {
            ...shelf,
            products: shelf.products.filter(product => {
                return !!availableProducts.find(availableProduct => availableProduct.productId === product.productId);
            }),
        };
    });
}

function nextShelfId(shelves: Shelf[]): number {
    let maxId = 0;
    for (const shelf of shelves) {
        maxId = Math.max(maxId, shelf.id);
    }

    return maxId + 1;
}

function nextPlacedProductId(shelves: Shelf[]): number {
    let maxId = 0;
    for (const shelf of shelves) {
        if (shelf.type === 'productsShelf') {
            const placedProducts = shelf.products;
            for (const placedProduct of placedProducts) {
                maxId = Math.max(maxId, placedProduct.id);
            }
        }
    }

    return maxId + 1;
}

import * as React from 'react';

export type RegistrationStatus = 'registering' | 'registered' | 'registration-failing';
export type CallStatus = 'none' | 'ringing' | 'in-progress';

export interface VideoCallContext {
    triggerElement: HTMLDivElement | undefined;
    setTriggerElement: (triggerElement: HTMLDivElement | undefined) => void;
    panelIsOpened: boolean;
    setPanelIsOpened: (panelIsOpened: boolean) => void;
    panelCanBeClosed: boolean;
    setPanelCanBeClosed: (panelCanBeClosed: boolean) => void;
    registrationStatus: RegistrationStatus;
    setRegistrationStatus: (registrationStatus: RegistrationStatus) => void;
    callStatus: CallStatus;
    setCallStatus: (callStatus: CallStatus) => void;
    shouldAutoAnswerNextCall: boolean;
    autoAnswerNextCall: (shouldAutoAnswerNextCall: boolean) => void;
    isMuted: boolean;
    setIsMuted: (isMuted: boolean) => void;
}

const videoCallContextStore = React.createContext<VideoCallContext>({
    triggerElement: undefined,
    setTriggerElement: () => undefined,
    panelIsOpened: false,
    setPanelIsOpened: () => undefined,
    panelCanBeClosed: true,
    setPanelCanBeClosed: () => undefined,
    registrationStatus: 'registering',
    setRegistrationStatus: () => undefined,
    callStatus: 'none',
    setCallStatus: () => undefined,
    shouldAutoAnswerNextCall: false,
    autoAnswerNextCall: () => undefined,
    isMuted: false,
    setIsMuted: () => undefined,
});

const { Provider } = videoCallContextStore;

export const VideoCallContextProvider = (props: { children: React.ReactNode }) => {
    const [triggerElement, setTriggerElement] = React.useState<VideoCallContext['triggerElement']>(undefined);
    const [panelIsOpened, setPanelIsOpened] = React.useState(false);
    const [panelCanBeClosed, setPanelCanBeClosed] = React.useState(true);
    const [registrationStatus, setRegistrationStatus] = React.useState<RegistrationStatus>('registering');
    const [callStatus, setCallStatus] = React.useState<CallStatus>('none');
    const [shouldAutoAnswerNextCall, autoAnswerNextCall] = React.useState(false);
    const [isMuted, setIsMuted] = React.useState(false);

    React.useEffect(() => {
        let timer: ReturnType<typeof setTimeout> | undefined = undefined;
        if (shouldAutoAnswerNextCall) {
            timer = setTimeout(() => {
                autoAnswerNextCall(false);
            }, 10000);

            return () => {
                if (timer) {
                    clearTimeout(timer);
                }
            };
        } else if (timer) {
            clearTimeout(timer);
        }
    }, [shouldAutoAnswerNextCall]);

    return (
        <Provider
            value={{
                triggerElement,
                setTriggerElement,
                panelIsOpened,
                setPanelIsOpened,
                panelCanBeClosed,
                setPanelCanBeClosed,
                registrationStatus,
                setRegistrationStatus,
                callStatus,
                setCallStatus,
                shouldAutoAnswerNextCall,
                autoAnswerNextCall,
                isMuted,
                setIsMuted,
            }}
        >
            {props.children}
        </Provider>
    );
};

export function useVideoCallContext() {
    return React.useContext(videoCallContextStore);
}

import * as React from 'react';

import { Domain } from 'api';
import { useGettext } from 'data-store';
import { Icon } from 'ui-components';
import { formatPrice } from 'utils';

import Button from '@/components/Button';
import InteractiveScreenHeader from '@/components/InteractiveScreenHeader';
import InteractiveScreenNavigation from '@/components/InteractiveScreenNavigation';
import Spinner from '@/components/Spinner';
import useProductDetails from '@/hooks/useProductDetails';

import styles from './ProductDetails.scss';
import screenStyles from '@/components/InteractiveScreen.scss';

export default function ProductDetails(props: {
    product: Domain.SelectedProduct;
    getProductTitle: () => React.ReactNode;
    renderProductImage: (image: Domain.ProductImage, key?: string) => React.ReactNode;
    getProductCodes: () => React.ReactNode;
    getProductDescription: (descriptionKey: string) => React.ReactNode;
    getLanguageLabel: (locale: Domain.Locale) => React.ReactNode;
}) {
    const { product, getProductTitle, renderProductImage, getProductCodes, getProductDescription, getLanguageLabel } = props;
    const { gettext } = useGettext();
    const {
        isFetchingStock,
        availableStock,
        isReadyForDelivery,
        handleEject,
        accessibilityModeEnabled,
        deviceScreenResolution,
        productImages,
        visibleImageIndex,
        setVisibleImageIndex,
        regularPrice,
        promoPrice,
        locale,
        quantityToEject,
        setQuantityToEject,
        contentTabs,
        activeContentTabIndex,
        setActiveContentTabIndex,
        disableEject,
    } = useProductDetails({
        product,
        getProductDescription,
    });

    return (
        <div className={`${screenStyles.InteractiveScreen} ${styles.TVScreenProductDetails}`}>
            {!accessibilityModeEnabled ? <InteractiveScreenHeader /> : null}

            <div className={`${styles.ProductDetailsContentSidesWrap} x-${deviceScreenResolution}`}>
                <div className={styles.ProductDetailsContentTop}>
                    {productImages.length > 0 ? (
                        <div className={styles.ProductDetailsImageGallery}>
                            <a
                                href=""
                                onClick={e => {
                                    e.preventDefault();

                                    setVisibleImageIndex(visibleImageIndex - 1);
                                }}
                                className={`${styles.ProductDetailsImageGalleryNav} ${styles.ProductDetailsImageGalleryNavPrev} ${visibleImageIndex === 0 ? styles.ProductDetailsImageGalleryNavDisabled : ''}`}
                            >
                                <Icon type="action_left" />
                            </a>

                            <div className={styles.ProductDetailsImageGalleryImage}>
                                {renderProductImage(
                                    productImages[visibleImageIndex],
                                    productImages[visibleImageIndex].id +
                                        '-' +
                                        (productImages[visibleImageIndex].type || visibleImageIndex),
                                )}
                            </div>

                            <a
                                href=""
                                onClick={e => {
                                    e.preventDefault();

                                    setVisibleImageIndex(visibleImageIndex + 1);
                                }}
                                className={`${styles.ProductDetailsImageGalleryNav} ${styles.ProductDetailsImageGalleryNavNext} ${visibleImageIndex === productImages.length - 1 ? styles.ProductDetailsImageGalleryNavDisabled : ''}`}
                            >
                                <Icon type="action_right" />
                            </a>
                        </div>
                    ) : null}

                    <h1 className={`${screenStyles.ScreenTitle} ${styles.ProductDetailsProductTitle}`}>{getProductTitle()}</h1>

                    <div className={styles.ProductDetailsIdentifiers}>{getProductCodes()}</div>
                </div>

                <div className={styles.ProductDetailsContentBottom}>
                    {regularPrice && promoPrice ? (
                        <React.Fragment>
                            <div className={styles.ProductDetailsPrice}>
                                <span>{formatPrice(promoPrice.amount / 100, locale, promoPrice.currency.name)}</span>
                            </div>
                            <div className={`${styles.ProductDetailsPrice} ${styles.ProductDetailsPriceOld}`}>
                                <span>{formatPrice(regularPrice.amount / 100, locale, regularPrice.currency.name)}</span>
                            </div>
                        </React.Fragment>
                    ) : regularPrice ? (
                        <div className={styles.ProductDetailsPrice}>
                            <span>{formatPrice(regularPrice.amount / 100, locale, regularPrice.currency.name)}</span>
                        </div>
                    ) : null}

                    <div className={styles.ProductDetailsStock}>
                        {isFetchingStock ? (
                            <div className={styles.ProductDetailsStockNumber}>
                                <Icon type="message_info" />
                                {gettext('Fetching stock...')}
                            </div>
                        ) : null}

                        {!isFetchingStock && availableStock !== undefined ? (
                            <div className={styles.ProductDetailsStockNumber}>
                                <Icon type="message_info" />
                                {availableStock > 0
                                    ? gettext(':num: in stock').replace(':num:', availableStock.toString(10))
                                    : gettext('Not in stock')}
                            </div>
                        ) : null}

                        {!isFetchingStock && availableStock !== undefined && availableStock > 0 && isReadyForDelivery ? (
                            <React.Fragment>
                                <Button
                                    variant="primary"
                                    onClick={handleEject}
                                    disabled={disableEject}
                                >
                                    {gettext('Eject')}
                                </Button>

                                {availableStock > 1 ? (
                                    <Spinner
                                        value={quantityToEject}
                                        onChange={setQuantityToEject}
                                        min={1}
                                        max={availableStock}
                                    />
                                ) : null}
                            </React.Fragment>
                        ) : null}
                    </div>

                    {contentTabs.length > 0 ? (
                        <div className={styles.ProductDetailsContentTabs}>
                            {contentTabs.length > 1 ? (
                                <ul>
                                    {contentTabs.map((tab, index) => {
                                        return (
                                            <li
                                                key={tab.label}
                                                className={`${styles.ProductDetailsContentTab} ${index === activeContentTabIndex ? styles.ProductDetailsContentActiveTab : ''}`}
                                            >
                                                <a
                                                    href=""
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        setActiveContentTabIndex(index);
                                                    }}
                                                >
                                                    <span>{tab.label}</span>
                                                </a>
                                            </li>
                                        );
                                    })}
                                </ul>
                            ) : null}

                            <div className={styles.ProductDetailsContentTabsContent}>
                                <h2>{contentTabs[activeContentTabIndex].label}</h2>
                                {contentTabs[activeContentTabIndex].value}
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>

            {accessibilityModeEnabled ? <InteractiveScreenHeader /> : null}

            <InteractiveScreenNavigation getLanguageLabel={getLanguageLabel} />
        </div>
    );
}

import * as React from 'react';

import classNames from 'classnames';

import { Button } from '@/button';
import { InputBase, InputBaseProps, TouchAndMouseDragHandler, TouchAndMouseMove, KeyboardHandler } from '@/core';
import { Icon } from '@/icon';
import { Tooltip } from '@/tooltip';
import { Tx } from '@/typography';

import getDecimalPrecision from './getDecimalPrecision';

import styles from './Slider.scss';

export type ControlledSliderProps = InputBaseProps & {
    min: number;
    max: number;
    selectableMin?: number;
    selectableMax?: number;
    step: number;
    onChange?: (newValue: number) => void;
    onChangeComplete?: (newValue: number) => void;
    onChangeStart?: () => void;
    forwardRef?: React.Ref<HTMLInputElement>;
    knobRef?: React.Ref<any>;
    hideProgress?: boolean;
    hideArrows?: boolean;
    marks?:
        | boolean
        | {
              label?: string;
              value: number;
          }[];
    markLabels?: boolean;
    valueLabel?: boolean;
    valueLabelFormatter?: (value: number) => string;
};

export type ControlledSliderPropsWithValue = ControlledSliderProps & {
    value: number;
};

interface IState {
    sliding: boolean;
}

class ControlledSlider extends InputBase<ControlledSliderPropsWithValue, IState> {
    private readonly slider = React.createRef<HTMLDivElement>();
    private readonly knob: React.RefObject<any>;
    private readonly dragHandler: TouchAndMouseDragHandler;
    private readonly keyHandler = new KeyboardHandler();
    private knobPosition: number;

    constructor(props: ControlledSliderPropsWithValue) {
        super(props);

        this.state = {
            sliding: false,
        };

        this.knobPosition = ControlledSlider.getKnobPosition(props, props.value);

        this.dragHandler = new TouchAndMouseDragHandler(this.handleKnobPositionChange, this.handleDragStart, this.handleDragEnd, true);

        this.knob = (this.props.knobRef || React.createRef<Tooltip<any>>()) as React.RefObject<any>;
    }

    componentDidMount(): void {
        if (this.slider.current) {
            this.slider.current.addEventListener('touchstart', this.dragHandler.handleTouchStart, { passive: false });
        }

        if (this.knob.current) {
            this.knob.current.addEventListener('touchstart', this.dragHandler.handleTouchStart, { passive: false });
        }
    }

    componentWillUnmount(): void {
        if (this.knob.current) {
            this.knob.current.removeEventListener('touchstart', this.dragHandler.handleTouchStart);
        }
    }

    protected renderInput(): React.ReactNode {
        const { value, name, disabled, forwardRef } = this.props;

        this.knobPosition = ControlledSlider.getKnobPosition(this.props, value);

        return (
            <React.Fragment>
                <input
                    ref={forwardRef}
                    type="hidden"
                    name={name}
                    value={value !== undefined ? value.toString(10) : undefined}
                    disabled={disabled}
                />

                {this.renderSlider()}
            </React.Fragment>
        );
    }

    private renderSlider(): React.ReactNode {
        const { sliding } = this.state;
        const { hideProgress, hideArrows, marks, markLabels, disabled, valueLabel, valueLabelFormatter } = this.props;

        return (
            <div className={`${styles.SliderWrapInner} ${!hideArrows ? styles.WithArrows : ''}`}>
                {!hideArrows ? (
                    <div className={styles.SliderArrows}>
                        <Button
                            variant="plain"
                            variantSize="xxs"
                            disabled={
                                disabled ||
                                (this.props.selectableMax === undefined && this.props.value >= this.props.max) ||
                                (this.props.selectableMax !== undefined && this.props.value >= this.props.selectableMax)
                            }
                            onClick={() => {
                                this.handleDragStart();

                                const { step, onChange, min, max } = this.props;

                                let actualStep = step;
                                let value = this.getValueFromPercentage(this.knobPosition);

                                if (value + actualStep > this.getSelectableMax()) {
                                    actualStep = this.getSelectableMax() - value;
                                }

                                value += actualStep;

                                value = this.getClosestStep(value);

                                this.knobPosition = ((value - min) / (max - min)) * 100;

                                if (onChange) {
                                    onChange(value);
                                }

                                this.handleChangeComplete();
                            }}
                            startIcon={<Icon type="action_arrow_up" />}
                            data-test-id="slider-increase"
                        />

                        <Button
                            variant="plain"
                            variantSize="xxs"
                            disabled={
                                disabled ||
                                (this.props.selectableMin === undefined && this.props.value <= this.props.min) ||
                                (this.props.selectableMin !== undefined && this.props.value <= this.props.selectableMin)
                            }
                            onClick={() => {
                                this.handleDragStart();

                                const { step, onChange, min, max } = this.props;

                                let actualStep = step;
                                let value = this.getValueFromPercentage(this.knobPosition);

                                if (value - actualStep < this.getSelectableMin()) {
                                    actualStep = value - this.getSelectableMin();
                                }

                                value -= actualStep;

                                value = this.getClosestStep(value);

                                this.knobPosition = ((value - min) / (max - min)) * 100;

                                if (onChange) {
                                    onChange(value);
                                }

                                this.handleChangeComplete();
                            }}
                            startIcon={<Icon type="action_arrow_down" />}
                            data-test-id="slider-decrease"
                        />
                    </div>
                ) : null}
                <div
                    ref={this.slider}
                    className={classNames(styles.Slider, markLabels ? styles.SliderWithMarkLabels : undefined)}
                    onClick={event => event.preventDefault()}
                    onMouseDown={!disabled ? this.dragHandler.handleMouseDown : undefined}
                >
                    {this.renderRail({
                        widthAsPercentage: 100,
                        className: styles.SliderRail,
                        classNameDisabled: styles.SliderRailDisabled,
                    })}

                    {!hideProgress
                        ? this.renderRail({
                              widthAsPercentage: this.knobPosition,
                              className: styles.SliderProgress,
                              classNameDisabled: styles.SliderProgressDisabled,
                          })
                        : null}

                    {marks ? this.renderMarks() : null}

                    <Tooltip
                        text={
                            valueLabelFormatter
                                ? valueLabelFormatter(this.getValueFromPercentage(this.knobPosition))
                                : this.getValueFromPercentage(this.knobPosition)
                        }
                        position="top-center"
                        disabled={!valueLabel}
                        visible={sliding}
                        canMove={sliding}
                        className={classNames(styles.SliderKnob, sliding ? styles.SliderKnobSliding : null)}
                        style={{
                            left: this.knobPosition + '%',
                        }}
                        component="a"
                        customProps={{
                            onMouseDown: this.dragHandler.handleMouseDown,
                            onClick: () => {
                                // do nothing
                            },
                            tabIndex: disabled ? -1 : 0,
                            onKeyDown: this.keyHandler.handleKey(
                                ['LEFT_ARROW', 'RIGHT_ARROW'],
                                (_1: any, key: string, meta: { shift: boolean }) => {
                                    this.handleDragStart();

                                    const { step, onChange, min, max } = this.props;

                                    let actualStep = step;
                                    if (meta.shift) {
                                        actualStep *= 10;
                                    }

                                    let value = this.getValueFromPercentage(this.knobPosition);

                                    if (key === 'LEFT_ARROW') {
                                        if (value - actualStep < this.getSelectableMin()) {
                                            actualStep = value - this.getSelectableMin();
                                        }

                                        value -= actualStep;
                                    } else if (key === 'RIGHT_ARROW') {
                                        if (value + actualStep > this.getSelectableMax()) {
                                            actualStep = this.getSelectableMax() - value;
                                        }

                                        value += actualStep;
                                    }

                                    value = this.getClosestStep(value);

                                    this.knobPosition = ((value - min) / (max - min)) * 100;

                                    if (onChange) {
                                        onChange(value);
                                    }
                                },
                            ),
                            onKeyUp: this.keyHandler.handleKey(['LEFT_ARROW', 'RIGHT_ARROW'], () => {
                                this.handleDragEnd();
                            }),
                        }}
                    />
                </div>
            </div>
        );
    }

    private renderRail(options: { widthAsPercentage: number; className: string; classNameDisabled: string }) {
        const { widthAsPercentage, className, classNameDisabled } = options;
        const { min, max, selectableMin, selectableMax } = this.props;
        const minPercentage = this.getMinPercentage();
        const maxPercentage = this.getMaxPercentage();

        const rails = [];
        let remainingWidth = widthAsPercentage;

        if (selectableMin !== undefined && selectableMin > min) {
            rails.push(
                <span
                    key="min"
                    className={classNames(className, classNameDisabled)}
                    style={{
                        width: Math.min(remainingWidth, minPercentage) + '%',
                    }}
                />,
            );
        }

        remainingWidth -= minPercentage;

        if (remainingWidth <= 0) {
            return rails;
        }

        rails.push(
            <span
                key="selectable"
                className={className}
                style={{
                    left: minPercentage + '%',
                    width: Math.min(remainingWidth, maxPercentage - minPercentage) + '%',
                }}
            />,
        );

        remainingWidth -= maxPercentage - minPercentage;

        if (remainingWidth <= 0) {
            return rails;
        }

        if (selectableMax !== undefined && selectableMax < max) {
            rails.push(
                <span
                    key="max"
                    className={classNames(className, classNameDisabled)}
                    style={{
                        left: maxPercentage + '%',
                        width: Math.min(remainingWidth, 100 - maxPercentage) + '%',
                    }}
                />,
            );
        }

        return rails;
    }

    private renderMarks() {
        const { value, min, max, step, marks, markLabels, hideProgress } = this.props;
        const selectableMin = this.getSelectableMin();
        const selectableMax = this.getSelectableMax();

        const items: {
            left: number;
            label: string;
            afterKnob: boolean;
            inSelectableRange: boolean;
        }[] = [];

        if (marks === true) {
            for (let i = min; i <= max; i += step) {
                items.push({
                    left: ControlledSlider.getKnobPosition(this.props, i),
                    label: i.toString(10),
                    afterKnob: hideProgress || (value !== undefined ? i > value : false),
                    inSelectableRange: i >= selectableMin && i <= selectableMax,
                });
            }
        } else if (marks instanceof Array) {
            for (const mark of marks) {
                items.push({
                    left: ControlledSlider.getKnobPosition(this.props, mark.value),
                    label: mark.label !== undefined ? mark.label! : mark.value.toString(10),
                    afterKnob: hideProgress || (value !== undefined ? mark.value > value : false),
                    inSelectableRange: mark.value >= selectableMin && mark.value <= selectableMax,
                });
            }
        }

        return (
            <span>
                {items.map(item => (
                    <Tx
                        key={item.left}
                        as="span"
                        className={classNames(
                            styles.SliderRailMark,
                            item.afterKnob ? styles.SliderRailMarkAfterKnob : undefined,
                            item.inSelectableRange ? undefined : styles.SliderRailMarkOutsideSelectableRange,
                        )}
                        style={{
                            left: item.left + '%',
                        }}
                    >
                        {markLabels ? <span>{item.label}</span> : null}
                    </Tx>
                ))}
            </span>
        );
    }

    protected getClassName(): string {
        return classNames(super.getClassName(), styles.SliderWrap);
    }

    private handleDragStart = () => {
        if (this.props.disabled) {
            return;
        }

        this.setState({
            sliding: true,
        });

        if (this.props.onChangeStart) {
            this.props.onChangeStart();
        }

        if (this.knob.current) {
            this.knob.current.focus();
        }
    };

    private handleDragEnd = () => {
        this.setState({
            sliding: false,
        });

        this.handleChangeComplete();
    };

    private handleChangeComplete = () => {
        if (this.props.onChangeComplete) {
            this.props.onChangeComplete(this.getValueFromPercentage(this.knobPosition));
        }
    };

    private handleKnobPositionChange = (move: TouchAndMouseMove): void => {
        const { disabled, onChange, min, max } = this.props;

        if (disabled) {
            return;
        }

        const minPercentage = this.getMinPercentage();
        const maxPercentage = this.getMaxPercentage();
        const slider = this.getSliderBoundingBox();
        const positionOnSlider = move.x - slider.left;
        let positionAsPercentage = (positionOnSlider / slider.width) * 100;

        if (positionAsPercentage < minPercentage) {
            positionAsPercentage = minPercentage;
        }

        if (positionAsPercentage > maxPercentage) {
            positionAsPercentage = maxPercentage;
        }

        const value = this.getClosestStep(this.getValueFromPercentage(positionAsPercentage));

        this.knobPosition = ((value - min) / (max - min)) * 100;
        if (onChange) {
            onChange(value);
        }
    };

    private getClosestStep(value: number): number {
        const { min, step } = this.props;
        const nearest = Math.round((value - min) / step) * step + min;
        return Number(nearest.toFixed(getDecimalPrecision(step)));
    }

    private static getKnobPosition(props: ControlledSliderProps, value?: number): number {
        if (value === undefined) {
            return 0;
        }
        const { min, max } = props;
        const width = max - min;

        return ((value - min) * 100) / width;
    }

    private getValueFromPercentage(positionAsPercentage: number): number {
        const { min, max, step } = this.props;
        const value = (max - min) * (positionAsPercentage / 100) + min;
        let percent = Number(value.toFixed(getDecimalPrecision(step)));
        if (Number.isNaN(percent)) {
            percent = 0;
        }

        return percent;
    }

    private getPercentageFromValue(value: number): number {
        const { min, max } = this.props;
        return ((value - min) / (max - min)) * 100;
    }

    private getSliderBoundingBox(): {
        left: number;
        width: number;
    } {
        if (!this.slider.current) {
            return {
                left: 0,
                width: 0,
            };
        }

        const area = this.slider.current.getBoundingClientRect();

        return {
            left: area.x,
            width: area.width,
        };
    }

    private getSelectableMin(): number {
        const { min, selectableMin } = this.props;

        if (selectableMin !== undefined) {
            return selectableMin;
        }

        return min;
    }

    private getSelectableMax(): number {
        const { max, selectableMax } = this.props;

        if (selectableMax !== undefined) {
            return selectableMax;
        }

        return max;
    }

    private getMinPercentage(): number {
        const { selectableMin } = this.props;

        if (selectableMin === undefined) {
            return 0;
        }

        return this.getPercentageFromValue(selectableMin);
    }

    private getMaxPercentage(): number {
        const { selectableMax } = this.props;

        if (selectableMax === undefined) {
            return 100;
        }

        return this.getPercentageFromValue(selectableMax);
    }
}

export default React.forwardRef<HTMLInputElement, ControlledSliderPropsWithValue>((props, ref) => {
    return (
        <ControlledSlider
            forwardRef={ref}
            {...props}
        />
    );
});

import Wwks2CommClientException from './Wwks2CommClientException';
import * as Wwks2Types from '../../Domain/Wwks2';
import { xml2Json, parse2XML } from './Wwks2/Wwks2Parsers';
import {
    WWKS2_TYPE,
    WWKS2_MANUFACTURER,
    WWKS2_PRODUCTINFO,
    WWKS2_VERSIONINFO
} from './Wwks2/Wwks2DefaultValues';
import Wwks2Hello from './Wwks2/Dialogs/Wwks2Hello';
import Wwks2KeepAlive from './Wwks2/Dialogs/Wwks2KeepAlive';
import Wwks2ArticlePrice from './Wwks2/Dialogs/Wwks2ArticlePrice';
import Wwks2ArticleInfo from './Wwks2/Dialogs/Wwks2ArticleInfo';
import Wwks2StockInfo from './Wwks2/Dialogs/Wwks2StockInfo';
import Wwks2Output from './Wwks2/Dialogs/Wwks2Output';
import Wwks2Status from './Wwks2/Dialogs/Wwks2Status';
import Wwks2StockLocation from './Wwks2/Dialogs/Wwks2StockLocation';
import Wwks2ShoppingCart from './Wwks2/Dialogs/Wwks2ShoppingCart';
import Wwks2MTRemoteLogs from './Wwks2/Dialogs/Wwks2MTRemoteLogs';


//need to look

export default class Wwks2CommClient {
    private ws: WebSocket;
    private robotExit: number;
    private stockLocation: Array<string>;
    private zone: string | undefined;
    private doReconnectWhenConnectionBroke: boolean;
    private ownCompatibilities: string[] = Wwks2Types.SOURCECOMPATIBILITIES;
    private clientCompatibilities: string[] = Wwks2Types.CLIENTCOMPATIBILITIES;
    private sourceId: number;
    private clientId: number | string;
    private type: string = WWKS2_TYPE;
    private manufacturer: string = WWKS2_MANUFACTURER;
    private productInfo: string = WWKS2_PRODUCTINFO;
    private versionInfo: string = WWKS2_VERSIONINFO;
    //public processStatusDialog: Wwks2Types.Wwks2ProcessStatusDialogs = {};
    public processStatusDialog: Wwks2Types.Wwks2ProcessStatusDialogs = {};
    private handShakeIsDone: boolean = false;
    

    public Wwks2Hello: Wwks2Hello;
    public Wwks2KeepAlive: Wwks2KeepAlive;
    public Wwks2ArticlePrice: Wwks2ArticlePrice;
    public Wwks2ArticleInfo: Wwks2ArticleInfo;
    public Wwks2StockInfo: Wwks2StockInfo;
    public Wwks2Output: Wwks2Output;
    public Wwks2Status: Wwks2Status;
    public Wwks2StockLocation: Wwks2StockLocation;
    public Wwks2ShoppingCart: Wwks2ShoppingCart;
    public Wwks2MTRemoteLogs: Wwks2MTRemoteLogs;
    public checkWwks2Compatibilities = false;
    public checkWwks2SourceDestinationIdInRequestMessage = false;

    public onConnectionOpen: () => void;
    public onConnectionClose: (event: CloseEvent) => void;

    public constructor() {
        this.handShakeIsDone = false;
        this.Wwks2Hello = new Wwks2Hello(this);
        this.Wwks2KeepAlive = new Wwks2KeepAlive(this);
        this.Wwks2ArticlePrice = new Wwks2ArticlePrice(this);
        this.Wwks2ArticleInfo = new Wwks2ArticleInfo(this);
        this.Wwks2StockInfo = new Wwks2StockInfo(this);
        this.Wwks2Output = new Wwks2Output(this);
        this.Wwks2Status = new Wwks2Status(this);
        this.Wwks2StockLocation = new Wwks2StockLocation(this);
        this.Wwks2ShoppingCart = new Wwks2ShoppingCart(this);
        this.Wwks2MTRemoteLogs = new Wwks2MTRemoteLogs(this);
    }

    public GetWwks2DialogId(){

        const type = `wwks2DialogId${this.sourceId}`;
        let wwks2DialogId = localStorage.getItem(type);
    
        if (wwks2DialogId === null) {
            wwks2DialogId = '1';
        }
        this.SetWwks2DialogId((parseInt(wwks2DialogId)+1).toString());
    
        return parseInt(wwks2DialogId);
    }
    
    public SetWwks2DialogId(value: string){
        const type = `wwks2DialogId${this.sourceId}`;
        localStorage.setItem(type, value);
    }

    public GetStockLocation(): Array<string> {
        return this.stockLocation;
    }

    public GetZone(): string | undefined {
        return this.zone;
    }

    public SetStockLocation(stockLocation: Array<string>){
        this.stockLocation = stockLocation;
    }

    public SetZone(zone: string | undefined) {
        this.zone = zone;
    }

    public GetOwnCompatibilities(): string[] {
        return this.ownCompatibilities;
    }

    public SetOwnCompatibilities(Compatibilities: string[]){
        this.ownCompatibilities = Compatibilities;
    }

    public GetSourceId(): number {
        return this.sourceId;
    }

    public SetSourceId(sourceId: number){
        this.sourceId = sourceId;
    }

    public GetClientId(): number | string {
        return this.clientId;
    }

    public SetClientId(clientId: number | string) {
        this.clientId = clientId
    }

    public GetType(): string {
        return this.type;
    }

    public GetManufacturer(): string {
        return this.manufacturer;
    }

    public SetManufacturer(manufacturer: string) {
        this.manufacturer = manufacturer;
    }

    public GetProductInfo(): string {
        return this.productInfo;
    }

    public SetProductInfo(productInfo: string) {
        this.productInfo = productInfo;
    }

    public GetVersionInfo(): string {
        return this.versionInfo;
    }

    public SetVersionInfo(versionInfo: string) {
        this.versionInfo = versionInfo;
    }

    public GetClientCompatibilities(): string[] {
        return this.clientCompatibilities;
    }

    public SetClientCompatibilities(clientCompatibilities: string[]) {
        this.clientCompatibilities = clientCompatibilities;
    }

    public SetRobotExit(robotExit: number) {
        this.robotExit = robotExit;
    }

    public GetRobotExit() {
        return this.robotExit;
    }

    public Listen(
        url: string,
        errorHandler: (error: Error | Event) => void,
        reconnect: boolean = false,
        waitUntilConnected: boolean = false
    ): Promise<{status: boolean, error: string, connectionStatus: string}> {
        return new Promise( (resolve) => {

            if(this.sourceId && typeof this.robotExit !== 'undefined' && this.stockLocation && url) {
                if (undefined != this.ws && null != this.ws) {
                    this.ws.close();
                }           

                this.ws = new WebSocket(url);
                this.doReconnectWhenConnectionBroke = reconnect;
    
                this.ws.onmessage = (event: MessageEvent) => {
                    try {
                        if (event.data) {
                            const messageXML = (new DOMParser()).parseFromString(event.data, 'text/xml');
                            const jsonXML = xml2Json(messageXML);
                            this.parseInternalyMessage(jsonXML);
                        }
                    } catch (error) {
                        if (error instanceof Wwks2CommClientException) {
                            //errorHandler(error);
                            //return;
                        }
    
                        if (error instanceof SyntaxError) {
                            error.message = 'Syntax error: ' + error.message;
                        } else {
                            (error as Error).message = event.type;
                        }

                        console.log(error);
    
                        //errorHandler(error);
                    }
                };
    
                this.ws.onopen = () => {
                    this.Wwks2Hello.sendAndProcessHelloRequest().then(status => {
                        if (!status.status) {
                            this.closeConnection(true);
                        } else {
                            this.handShakeIsDone = true;
                            if(!this.checkWwks2Compatibilities){
                                this.clientCompatibilities = [...Wwks2Types.SOURCECOMPATIBILITIES];
                            }
                            if(this.clientCompatibilities.indexOf('KeepAlive')){
                                this.Wwks2KeepAlive.initKeepAlive();
                            }
                            if (typeof this.onConnectionOpen !== 'undefined') {
                                this.onConnectionOpen();
                            }
                        }
                    });
                };
    
                /*this.ws.onerror = (error: Event) => {
                    errorHandler(error);
                };*/
    
                this.ws.onclose = (event: CloseEvent) => {
    
                    if (this.doReconnectWhenConnectionBroke) {
                        setTimeout(() => {
                            this.Listen(url, errorHandler, reconnect);
                        }, 2000);
                    }

                    if (typeof this.onConnectionClose !== 'undefined') {
                        this.onConnectionClose(event);
                    }
                };
    
                const checkReadyStateInterval = setInterval(() => {
                    if (this.ws.readyState === WebSocket.OPEN) {
                        if (this.handShakeIsDone) {
                            clearInterval(checkReadyStateInterval);
                            resolve({status: true, error: '', connectionStatus: 'connected'});
                        }
                    } else if (this.ws.readyState === WebSocket.CLOSED) {
                        clearInterval(checkReadyStateInterval);
                        this.ws.close();
                        if(!waitUntilConnected){
                            resolve({status: true, error: '', connectionStatus: 'reconnecting'});
                        }
                    }
                }, 100);
            }else{
                /*errorHandler(new Wwks2CommClientException(
                    'sourceId and/or robotExit and/or stockLocation not filled'
                ));*/
                resolve({status: false, error: 'sourceId and/or stockLocation and/or robotexit not defined', connectionStatus: 'failed'});
            }
        });
    }

    public waitUnitConnectionIsClosed(): Promise<boolean>{
        return new Promise( resolve => {
            let waitInterval: ReturnType<typeof setInterval>;
            let waitTimeout = setTimeout( () => {
                clearTimeout(waitTimeout);
                clearInterval(waitInterval);
                resolve(false);
            },10000);

            waitInterval = setInterval( () => {
                if(this.ws){
                    if (this.ws.readyState === WebSocket.CLOSED) {
                        clearTimeout(waitTimeout);
                        clearInterval(waitInterval);
                        resolve(true);
                    }
                }else{
                    clearTimeout(waitTimeout);
                    clearInterval(waitInterval);
                    resolve(true);
                }
            },500);
        });
    }

    public async closeConnection(reconnecting: boolean = false){
        if (this.ws && (this.ws.readyState === WebSocket.OPEN || this.ws.readyState === WebSocket.CONNECTING)) {
            this.doReconnectWhenConnectionBroke = reconnecting;
            this.ws.close();
            return await this.waitUnitConnectionIsClosed();
        }else{
            return false;
        }
    }

    public isHandShakeDone(): boolean {
        return this.handShakeIsDone;
    }

    public GetProcessedDialogData(dialogId: string | number): boolean | Wwks2Types.Wwks2ProcessStatus {
        if (this.processStatusDialog.hasOwnProperty(`status_${dialogId}`)) {
            return this.processStatusDialog[`status_${dialogId}`];
        } else {
            return false;
        }
    }

    public SetProcessedDialogData(dialogId: string | number, data: Wwks2Types.Wwks2ProcessStatus): boolean {
        if (true) {
            this.processStatusDialog[`status_${dialogId}`] = data;
            return true;
        } else {
            return false;
        }
    }

    public DeleteProcessedDialogData(dialogId: string | number) {
        if (this.processStatusDialog.hasOwnProperty(`status_${dialogId}`)) {
            delete this.processStatusDialog[`status_${dialogId}`];
            return true;
        }
        return false;
    }

    public getResponse(dialogId: string | number, dialogType: string): Promise<Wwks2Types.Wwks2StatusDialog> {
        return new Promise((resolve) => {

            const statusDialog: Wwks2Types.Wwks2StatusDialog = {
                status: false,
                msg: '',
                dialogId,
                dialogType,
                isDialogType: 'response',
                canceled: false,
                errorType: 'none'
            }

            if (this.processStatusDialog.hasOwnProperty(`status_${dialogId}`)) {
                if (this.processStatusDialog[`status_${dialogId}`].typeDialog === dialogType) {

                    if (this.processStatusDialog[`status_${dialogId}`].status.cancel) {
                        statusDialog.status = false;
                        statusDialog.msg = 'canceled for statusDialog (key = \'status_' + dialogId + '\')';
                        statusDialog.canceled = true;
                        statusDialog.errorType = 'checkStatusDialogCanceled';
                        resolve(statusDialog);
                    } else if (this.processStatusDialog[`status_${dialogId}`].status.response) {
                        statusDialog.status = true;
                        statusDialog.msg = dialogType + ' Response received and processed';
                        resolve(statusDialog);
                    }  else {
                        setTimeout(() => {
                            resolve(this.getResponse(dialogId, dialogType));
                        }, 500)
                    }
                } else {

                    statusDialog.status = false;
                    statusDialog.msg = 'statusDialog (key = \'status_' + dialogId + '\') is no ' + dialogType + ' type';
                    statusDialog.errorType = 'wrongStatusDialogType';
                    resolve(statusDialog);
                }
            } else {
                statusDialog.status = false;
                statusDialog.msg = 'key \'status_' + dialogId + '\' not exist in statusDialog';
                statusDialog.errorType = 'noStatusDialog';
                resolve(statusDialog);
            }

        });
    }

    public getMessage(dialogId: string | number, dialogType: string): Promise<Wwks2Types.Wwks2StatusDialog> {
        return new Promise((resolve) => {

            const statusDialog: Wwks2Types.Wwks2StatusDialog = {
                status: false,
                msg: '',
                dialogId,
                dialogType,
                isDialogType: 'message',
                canceled: false,
                errorType: 'none'
            }

            if (this.processStatusDialog.hasOwnProperty(`status_${dialogId}`)) {
                if (this.processStatusDialog[`status_${dialogId}`].typeDialog === dialogType) {

                    if (this.processStatusDialog[`status_${dialogId}`].status.cancel) {
                        statusDialog.status = false;
                        statusDialog.msg = 'canceled for statusDialog (key = \'status_' + dialogId + '\')';
                        statusDialog.canceled = true;
                        statusDialog.errorType = 'checkStatusDialogCanceled';
                        resolve(statusDialog);
                    } else if (this.processStatusDialog[`status_${dialogId}`].status.message) {
                        statusDialog.status = true;
                        statusDialog.msg = dialogType + ' Message received and processed';
                        resolve(statusDialog);
                    } else {
                        setTimeout(() => {
                            resolve(this.getMessage(dialogId, dialogType));
                        }, 500)
                    }
                } else {

                    statusDialog.status = false;
                    statusDialog.msg = 'statusDialog (key = \'status_' + dialogId + '\') is no ' + dialogType + ' type';
                    statusDialog.errorType = 'wrongStatusDialogType';
                    resolve(statusDialog);
                }
            } else {
                statusDialog.status = false;
                statusDialog.msg = 'key \'status_' + dialogId + '\' not exist in statusDialog';
                statusDialog.errorType = 'noStatusDialog';
                resolve(statusDialog);
            }

        });
    }

    private parseInternalyMessage(data: any) {

        if (data.WWKS) {
            if (data.WWKS.HelloRequest || data.WWKS.HelloResponse) {
                this.Wwks2Hello.handleMessage(data.WWKS);
            } else if (data.WWKS.KeepAliveRequest || data.WWKS.KeepAliveResponse) {
                this.Wwks2KeepAlive.handleMessage(data.WWKS);
            } else if (data.WWKS.StockLocationInfoRequest) {
                this.Wwks2StockLocation.handleMessage(data.WWKS);
            } else if (data.WWKS.ArticlePriceResponse) {
                this.Wwks2ArticlePrice.handleMessage(data.WWKS);
            } else if (data.WWKS.ArticleInfoResponse) {
                this.Wwks2ArticleInfo.handleMessage(data.WWKS);
            } else if (data.WWKS.StockInfoResponse || data.WWKS.StockInfoMessage) {
                this.Wwks2StockInfo.handleMessage(data.WWKS);
            } else if (data.WWKS.OutputMessage || data.WWKS.OutputResponse) {
                this.Wwks2Output.handleMessage(data.WWKS);
            } else if (data.WWKS.ShoppingCartResponse || data.WWKS.ShoppingCartUpdateResponse || data.WWKS.ShoppingCartUpdateMessage) {
                this.Wwks2ShoppingCart.handleMessage(data.WWKS);
            } else if (data.WWKS.StatusResponse || data.WWKS.StatusRequest) {
                this.Wwks2Status.handleMessage(data.WWKS);
            }
        }

    }

    private send(data: string, dialogType: string, dialogId: string | number, isDialogType: 'request' | 'response' | 'message' = 'request'): Promise<Wwks2Types.Wwks2StatusDialog> {

        return new Promise((resolve) => {

            const statusDialog: Wwks2Types.Wwks2StatusDialog = {
                'status': false,
                'msg': 'dialog is send',
                'dialogId': dialogId,
                'dialogType': dialogType,
                'isDialogType': isDialogType,
                'errorType': 'none',
                'canceled': false
            }
            if (null != this.ws && this.ws.readyState === WebSocket.OPEN) {
                if (this.clientCompatibilities.indexOf(dialogType) > -1) {
                    this.ws.send(data);

                    if (!this.processStatusDialog.hasOwnProperty(`status_${dialogId}`)) {
                        //this.processStatusDialog[`status_${dialogId}`] = new Wwks2Types.Wwks2ProcessStatusDialog();
                        this.processStatusDialog[`status_${dialogId}`] = {
                            dialogId: 0,
                            clientId: 0,
                            typeDialog: '',
                            clearStatus: false,
                            isDialogType: 'request',
                            status: {
                                request: false,
                                response: false,
                                message: false,
                                cancel: false,
                            },
                            data: {
                                request: undefined,
                                response: undefined,
                                message: undefined,
                            },
                            timestamps: {
                                request: 0,
                                response: 0,
                                message: 0,
                            },
                            createdTimestamp: Date.now()
                        };
                    }
                    this.processStatusDialog[`status_${dialogId}`].clearStatus = false;
                    this.processStatusDialog[`status_${dialogId}`].typeDialog = dialogType;
                    this.processStatusDialog[`status_${dialogId}`].dialogId = dialogId;
                    this.processStatusDialog[`status_${dialogId}`].isDialogType = isDialogType;
                    if (isDialogType === 'request') {
                        this.processStatusDialog[`status_${dialogId}`].timestamps.request = Date.now();
                        this.processStatusDialog[`status_${dialogId}`].status.request = true;
                        this.processStatusDialog[`status_${dialogId}`].data.request = data;
                    } else if (isDialogType === 'response') {
                        this.processStatusDialog[`status_${dialogId}`].timestamps.response = Date.now();
                        this.processStatusDialog[`status_${dialogId}`].status.response = true;
                        this.processStatusDialog[`status_${dialogId}`].data.response = data;
                    } else if (isDialogType === 'message') {
                        this.processStatusDialog[`status_${dialogId}`].timestamps.message = Date.now();
                        this.processStatusDialog[`status_${dialogId}`].status.message = true;
                        this.processStatusDialog[`status_${dialogId}`].data.message = data;
                    }
                    this.processStatusDialog[`status_${dialogId}`].status.cancel = false;
                    statusDialog.status = true;
                    statusDialog.msg = 'dialog is send';
                    resolve(statusDialog);
                } else {
                    statusDialog.status = false;
                    statusDialog.msg = 'client does not support this dialog';
                    statusDialog.errorType = 'clientDoNotSupportDialog';
                    resolve(statusDialog);
                }
            } else {
                statusDialog.status = false;
                statusDialog.msg = 'no wwks2 websocket connection';
                statusDialog.errorType = 'noWebSocketConnection';
                resolve(statusDialog);
            }
        });
    }

    private createDialog(data: object): Promise<string> {

        return new Promise(resolve => {
            const wwksDialog = {
                'WWKS': {
                    '@': {
                        'Version': '2.0',
                        'TimeStamp': new Date().toISOString()
                    },
                    '#': data
                }
            }
            resolve(parse2XML(wwksDialog));
        });
    }

    public createAndSendDialog(wwks: object, dialogType: string, dialogId: string | number, isDialogType: 'request' | 'response' | 'message' = 'request'): Promise<Wwks2Types.Wwks2StatusDialog> {
        return new Promise(resolve => {
            this.createDialog(wwks).then((stringDialog: string) => {
                this.send(stringDialog, dialogType, dialogId, isDialogType).then((status: Wwks2Types.Wwks2StatusDialog) => {
                    resolve(status);
                })
            })
        })
    }

    public SetOnConnectionOpen(callback: () => void){
        this.onConnectionOpen = callback;
    }

    public SetOnConnectionClose(callback: (event: CloseEvent) => void){
        this.onConnectionClose = callback;
    }
}
import * as React from 'react';
import { useSelector } from 'react-redux';

import { Domain } from 'api';
import { I18n, useGettext, TVScreen, useThunkDispatch } from 'data-store';
import { Icon, Dropdown, Card, Menu } from 'ui-components';

import Button from './Button';

import styles from './InteractiveScreenNavigation.scss';

export default function InteractiveScreenNavigation(props: {
    getLanguageLabel: (locale: Domain.Locale) => React.ReactNode;
    withBack?: boolean;
    withClose?: boolean;
}) {
    const { getLanguageLabel } = props;
    const dispatch = useThunkDispatch();
    const { gettext } = useGettext();
    const locale = useSelector(I18n.selectCurrentLocale);
    const device = useSelector(TVScreen.selectors.selectDevice);
    const currentHistory = useSelector(TVScreen.interactionState.selectCurrentHistory);
    const accessibilityModeEnabled = useSelector(TVScreen.interactionState.selectAccessibilityModeEnabled);
    const dropdown = React.createRef<Dropdown>();

    const deviceCountry = TVScreen.getDeviceCountry(device);

    return (
        <div className={`${styles.InteractiveScreenNavigation} ${accessibilityModeEnabled ? styles.AccessibilityMode : ''}`}>
            {props.withBack ? (
                <Button
                    variant="plain"
                    onClick={() => dispatch(TVScreen.interactionState.reducerActions.goBackInHistory())}
                    startIcon={<Icon type="action_left" />}
                >
                    <span>{gettext('Back')}</span>
                </Button>
            ) : null}
            {props.withClose ? (
                <Button
                    variant="plain"
                    onClick={() => dispatch(TVScreen.interactionState.reducerActions.clearHistory())}
                    startIcon={<Icon type="action_remove" />}
                >
                    <span>{gettext('Close')}</span>
                </Button>
            ) : null}
            <Button
                variant="plain"
                onClick={() => {
                    if (currentHistory && currentHistory.type !== 'slidesOverview') {
                        dispatch(
                            TVScreen.interactionState.reducerActions.pushToHistory({
                                type: 'slidesOverview',
                                query: '',
                            }),
                        );
                    }
                }}
                startIcon={<Icon type="action_table" />}
            >
                <span>{gettext('Overview')}</span>
            </Button>
            <Button
                variant="plain"
                onClick={() => {
                    if (currentHistory && currentHistory.type !== 'search') {
                        dispatch(
                            TVScreen.interactionState.reducerActions.pushToHistory({
                                type: 'search',
                                query: '',
                                results: [],
                            }),
                        );
                    }
                }}
                startIcon={<Icon type="action_search" />}
            >
                <span>{gettext('Search')}</span>
            </Button>
            <Button
                variant="plain"
                onClick={() => {
                    window.location.reload();
                }}
                startIcon={<Icon type="action_reset" />}
            >
                <span>{gettext('Reset')}</span>
            </Button>
            <Dropdown
                ref={dropdown}
                anchorRight={true}
                headerRenderer={() => (
                    <Button
                        variant="plain"
                        startIcon={<Icon type="nav_language" />}
                    >
                        {getLanguageLabel(locale)}
                    </Button>
                )}
                body={
                    <Card
                        hSpacing="none"
                        vSpacing="none"
                        elevated={true}
                        className="mt-7"
                    >
                        <Menu
                            selectedItems={[locale]}
                            items={
                                deviceCountry === 'BE'
                                    ? [
                                          { label: gettext('Dutch'), value: 'nl_BE' },
                                          { label: gettext('French'), value: 'fr_BE' },
                                          { label: gettext('English'), value: 'en_BE' },
                                      ]
                                    : deviceCountry === 'FR'
                                      ? [
                                            { label: gettext('French'), value: 'fr_FR' },
                                            { label: gettext('English'), value: 'en_FR' },
                                        ]
                                      : deviceCountry === 'AU'
                                        ? [{ label: gettext('English'), value: 'en_AU' }]
                                        : deviceCountry === 'ES'
                                          ? [
                                                { label: gettext('Spanish'), value: 'es_ES' },
                                                { label: gettext('English'), value: 'en_ES' },
                                            ]
                                          : deviceCountry === 'CZ'
                                            ? [
                                                  { label: gettext('Czech'), value: 'cs_CZ' },
                                                  { label: gettext('English'), value: 'en_CZ' },
                                              ]
                                            : deviceCountry === 'AT'
                                              ? [
                                                    { label: gettext('German'), value: 'de_AT' },
                                                    { label: gettext('English'), value: 'en_AT' },
                                                ]
                                              : deviceCountry === 'SG'
                                                ? [{ label: gettext('English'), value: 'en_SG' }]
                                                : deviceCountry === 'PT'
                                                  ? [
                                                        { label: gettext('Portuguese'), value: 'pt_PT' },
                                                        { label: gettext('English'), value: 'en_PT' },
                                                    ]
                                                  : deviceCountry === 'GB'
                                                    ? [{ label: gettext('English'), value: 'en_GB' }]
                                                    : deviceCountry === 'LU'
                                                      ? [
                                                            { label: gettext('English'), value: 'en_LU' },
                                                            { label: gettext('French'), value: 'fr_LU' },
                                                            { label: gettext('German'), value: 'de_LU' },
                                                        ]
                                                      : [
                                                            { label: gettext('German'), value: 'de_DE' },
                                                            { label: gettext('English'), value: 'en_DE' },
                                                        ]
                            }
                            onItemClick={(_1: any, item) => {
                                if (dropdown.current) {
                                    dropdown.current.close(true);
                                }

                                dispatch(I18n.SetLocale(item.value as Domain.Locale));
                            }}
                        />
                    </Card>
                }
            />
            <Button
                variant="plain"
                className="mt-15"
                onClick={() => dispatch(TVScreen.interactionState.reducerActions.setAccessibilityModeEnabled(!accessibilityModeEnabled))}
                startIcon={<Icon type="nav_accessibility" />}
            />
        </div>
    );
}

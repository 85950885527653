import * as React from 'react';

import { createPortal } from 'ui-components';

import { store } from '../state';

import styles from './styles/PlacedProductDragLayer.scss';

export const PlacedProductDragLayer = React.memo((props: { previewImageUrl: string }) => {
    const { state } = React.useContext(store);

    return createPortal(
        <span
            className={styles.PlacedProductDragLayer}
            style={{
                left: state.draggedProductPosition.x + 'px',
                top: state.draggedProductPosition.y + 'px',
            }}
        >
            <span
                style={{
                    backgroundImage: 'url(' + props.previewImageUrl + ')',
                }}
            />
        </span>,
    );
});

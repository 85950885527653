import * as React from 'react';
import { useSelector } from 'react-redux';

import { Domain } from 'api';
import { I18n, meditechDeviceApi, getLocalizedImages, TVScreen, useGettext, useThunkDispatch } from 'data-store';
import * as DeviceInterface from 'meditech-device-interface';

export default function useProductDetails({
    product,
    getProductDescription,
}: {
    product: Domain.SelectedProduct;
    getProductDescription: (descriptionKey: string) => React.ReactNode;
}) {
    const dispatch = useThunkDispatch();
    const { gettext } = useGettext();
    const device = useSelector(TVScreen.selectors.selectDevice);
    const locale = useSelector(I18n.selectCurrentLocale);
    const accessibilityModeEnabled = useSelector(TVScreen.interactionState.selectAccessibilityModeEnabled);
    const stock = useSelector(TVScreen.selectors.selectStock);
    const [visibleImageIndex, setVisibleImageIndex] = React.useState(0);
    const [activeContentTabIndex, setActiveContentTabIndex] = React.useState(0);
    const [isFetchingStock, setIsFetchingStock] = React.useState(false);
    const [availableStock, setAvailableStock] = React.useState<number | undefined>();
    const [availableStockBarcode, setAvailableStockBarcode] = React.useState<string | undefined>();
    const [isReadyForDelivery, setIsReadyForDelivery] = React.useState(false);
    const [quantityToEject, setQuantityToEject] = React.useState(1);
    const [disableEject, setDisableEject] = React.useState(false);

    const productImages = getLocalizedImages(product.images.concat(product.frontals), locale);

    React.useEffect(() => {
        setVisibleImageIndex(0);
        setActiveContentTabIndex(0);
    }, [product, locale]);

    React.useEffect(() => {
        if (device?.configuration.general?.simulateStock) {
            setQuantityToEject(1);
            setAvailableStock(stock[product.productId] || 0);
            setAvailableStockBarcode('123456');
        }

        if (device?.configuration.general?.simulateProductDelivery) {
            setIsReadyForDelivery(true);
        }
    }, [stock]);

    const resetStock = () => {
        setQuantityToEject(1);
        setAvailableStock(undefined);
        setAvailableStockBarcode(undefined);
    };

    const checkStock = async () => {
        let codesToCheck: string[] = [];
        for (const codes of Object.values(product.productCodes)) {
            if (codes) {
                codesToCheck = codesToCheck.concat(codes);
            }
        }

        if (codesToCheck.length > 0) {
            setIsFetchingStock(true);
            const stockInfo = await meditechDeviceApi.GetStockInformation(codesToCheck);
            setIsFetchingStock(false);

            if (stockInfo.Articles.length > 0) {
                setQuantityToEject(1);
                setAvailableStock(parseInt(stockInfo.Articles[0].Quantity));
                setAvailableStockBarcode(stockInfo.Articles[0].ArticleCode);
            } else {
                setAvailableStock(0);
            }
        }
    };

    const checkIsReadyForDelivery = async () => {
        if (device?.robotDeliveryIsAvailable && !device?.configuration.general?.simulateProductDelivery) {
            const deviceState = (await meditechDeviceApi.GetDeviceInformation()).DeviceState;
            setIsReadyForDelivery(DeviceInterface.IStateType.READY === deviceState);
        }
    };

    React.useEffect(() => {
        if (!device?.robotStockIsAvailable || device?.configuration.general?.simulateStock) {
            return;
        }

        resetStock();
        setIsReadyForDelivery(false);
        checkStock();
        checkIsReadyForDelivery();

        const checkStockInterval = setInterval(() => {
            checkStock();
            checkIsReadyForDelivery();
        }, 30 * 1000);

        return () => {
            clearInterval(checkStockInterval);
        };
    }, [product.productId]);

    const contentTabs: {
        label: string;
        value: React.ReactNode;
    }[] = [];

    const DESCRIPTIONS: {
        [key: string]: string;
    } = {
        full_description: gettext('Description'),
        properties: gettext('Properties'),
        usage: gettext('Usage'),
        indication: gettext('Indication'),
        contra_indication: gettext('Contra indication'),
        composition: gettext('Composition'),
    };

    for (const descriptionKey in DESCRIPTIONS) {
        if (product.localizedDescriptions[descriptionKey]) {
            const localizedValue = getProductDescription(descriptionKey);

            if (localizedValue) {
                contentTabs.push({
                    label: DESCRIPTIONS[descriptionKey],
                    value: localizedValue,
                });
            }
        }
    }

    let regularPrice: Domain.Price | undefined;
    if (product.priceIncludingVat) {
        regularPrice = product.priceIncludingVat;
    }
    if (product.priceIncludingVatOverride) {
        regularPrice = product.priceIncludingVatOverride;
    }
    let promoPrice: Domain.Price | undefined;
    if (product.promotionalPriceIncludingVat) {
        promoPrice = product.promotionalPriceIncludingVat;
    }
    if (product.promotionalPriceIncludingVatOverride) {
        promoPrice = product.promotionalPriceIncludingVatOverride;
    }

    const deviceScreenResolution = device ? device.screenResolution : '';

    const handleEject = async () => {
        if (availableStockBarcode === undefined) {
            return;
        }

        setDisableEject(true);
        dispatch(
            TVScreen.interactionState.ejectProducts(availableStockBarcode, quantityToEject, product.localizedNames, () => {
                if (device?.configuration.general?.simulateStock) {
                    const currentStock = stock[product.productId];
                    if (currentStock !== undefined) {
                        dispatch(
                            TVScreen.deviceState.reducerActions.setStock({
                                ...stock,
                                [product.productId]: Math.max(0, currentStock - quantityToEject),
                            }),
                        );
                    }
                } else {
                    checkStock();
                }
            }),
        );

        setTimeout(() => {
            setDisableEject(false);
            setQuantityToEject(1);
        }, 2000);
    };

    return {
        isFetchingStock,
        availableStock,
        isReadyForDelivery,
        handleEject,
        accessibilityModeEnabled,
        deviceScreenResolution,
        productImages,
        visibleImageIndex,
        setVisibleImageIndex,
        regularPrice,
        promoPrice,
        locale,
        quantityToEject,
        setQuantityToEject,
        contentTabs,
        activeContentTabIndex,
        setActiveContentTabIndex,
        disableEject,
    };
}

import * as React from 'react';

import classNames from 'classnames';

import { Domain } from 'api';
import { CanvasFrame, EditorCanvas } from 'editor-canvas';
import { Icon } from 'ui-components';

import { store, Actions } from '../state';
import { MediaItemPickerRenderer, Frame, hasEyedropCapableFrameMedia } from '../types';
import { computeShelfMetas, computeShelfSnapPoints, getProductImages } from '../utils';
import EyedropperTool from './EyedropperTool';
import FrameBody from './FrameBody';
import { PlacedProductDragLayer } from './PlacedProductDragLayer';
import ShelfBody from './ShelfBody';
import ShelfGFX from './ShelfGFX';
import SplitShelfAction from './SplitShelfAction';

import styles from './styles/Canvas.scss';
import customFrameStyles from './styles/CustomFrameDragLayer.scss';

export default function Canvas(props: {
    className?: string;
    style?: React.CSSProperties;
    gettext: (text: string) => string;
    localizeProductName: (name: Domain.LocalizedValue) => string;
    proxyImageURL: (url: string) => string;
    getProxyURL: (url: string) => string;
    getMediaItemURL: (mediaItemId: string) => string;
    getVideoDataURL?: (videoURL: string) => string | undefined;
    getVideoMediaItemPreviewURL: (mediaItemId: string) => string;
    renderMediaItemPicker: MediaItemPickerRenderer;
    disableEditing?: boolean;
    scaleTo?: {
        width: number;
        height: number;
    };
    autoPlayVideos: boolean;
    disableVideoPosters: boolean;
    loopVideos: boolean;
    renderVideo: (videoProps: React.VideoHTMLAttributes<any>) => React.ReactElement;
    locale: string;
    enableStockDisplay: boolean;
}) {
    const {
        gettext,
        proxyImageURL,
        getProxyURL,
        getMediaItemURL,
        getVideoDataURL,
        getVideoMediaItemPreviewURL,
        renderMediaItemPicker,
        disableEditing,
        scaleTo,
        localizeProductName,
        autoPlayVideos,
        disableVideoPosters,
        loopVideos,
        renderVideo,
        locale,
        enableStockDisplay,
    } = props;
    const { state, dispatch } = React.useContext(store);
    const shelves = computeShelfMetas(state.canvas, state.screenResolution, state.availableProducts, state.productImageSizes);
    const scaleFactor = scaleTo ? scaleTo.width / state.canvas.width : 1;
    const wallBodyStyle: React.CSSProperties = {};

    if (state.canvas.backgroundType === 'color') {
        wallBodyStyle.backgroundColor = state.canvas.backgroundColor;
    } else if (state.canvas.backgroundType === 'image') {
        wallBodyStyle.backgroundImage = 'url(' + getMediaItemURL(state.canvas.backgroundMediaItemId) + ')';
        wallBodyStyle.backgroundPosition = '0 0';
        wallBodyStyle.backgroundSize = 'cover';
        wallBodyStyle.backgroundRepeat = 'no-repeat';
    }

    const snapPoints = computeShelfSnapPoints(state.canvas.shelves, state.canvas.height);

    let video: React.ReactNode;
    if (state.canvas.backgroundType === 'video') {
        const videoURL = getMediaItemURL(state.canvas.backgroundMediaItemId);
        let src = videoURL;
        if (getVideoDataURL) {
            src = getVideoDataURL(videoURL) || '';
        }

        video = renderVideo({
            width: '100%',
            height: '100%',
            autoPlay: autoPlayVideos,
            loop: true,
            src,
            poster: !disableVideoPosters ? getVideoMediaItemPreviewURL(state.canvas.backgroundMediaItemId) : undefined,
        });
    }

    return (
        <div
            className={classNames(props.className, styles.Canvas, state.canvas.style)}
            style={{
                ...props.style,
                transform: 'scale(' + scaleFactor + ')',
                width: (scaleTo ? scaleTo.width : state.canvas.width) + 'px',
                height: (scaleTo ? scaleTo.height : state.canvas.height) + 'px',
            }}
        >
            {!disableEditing && state.draggedProduct ? (
                <PlacedProductDragLayer previewImageUrl={getProductImages(state.draggedProduct).frontalImage} />
            ) : null}

            <div
                className={classNames(styles.Body, state.screenResolution)}
                style={{
                    width: state.canvas.width + 'px',
                    height: state.canvas.height + 'px',
                    ...wallBodyStyle,
                }}
            >
                {state.canvas.backgroundType === 'video' ? video : null}

                {shelves.map((item, index) => {
                    const { shelf } = item;
                    const prevShelf = index > 0 ? shelves[index - 1] : undefined;
                    const nextShelf = index < shelves.length - 1 ? shelves[index + 1] : undefined;

                    return (
                        <div
                            key={shelf.id}
                            className={classNames(
                                styles.BodyArea,
                                index === shelves.length - 1 ? styles.LastBodyArea : undefined,
                                shelf.type,
                            )}
                            style={{
                                top: shelf.y + 'px',
                                height: shelf.height + 'px',
                            }}
                        >
                            {!disableEditing && !state.draggingShelf && (shelf.type === 'productsShelf' || shelf.type === 'emptyShelf') ? (
                                <SplitShelfAction shelf={item} />
                            ) : null}

                            {!disableEditing && !state.draggingShelf && shelf.type === 'emptyShelf' ? (
                                <SplitShelfAction
                                    shelf={item}
                                    lastShelf={true}
                                />
                            ) : null}

                            <ShelfBody
                                shelf={item}
                                locale={locale}
                                proxyImageURL={proxyImageURL}
                                prevShelf={prevShelf}
                                nextShelf={nextShelf}
                                hideMoverHandle={index === shelves.length - 1}
                                disableEditing={disableEditing}
                                displayStock={enableStockDisplay}
                                onMouseMove={(shelfId: number, placedProductId: number, x, y, w, h) => {
                                    if (state.eyedropperEnabled) {
                                        dispatch({
                                            type: Actions.SET_EYEDROPPER_TOOL_FRAME_COORDINATES,
                                            frameId: undefined,
                                            shelfId,
                                            placedProductId,
                                            x,
                                            y,
                                            w,
                                            h,
                                        });
                                    }
                                }}
                                onMouseOut={() => {
                                    dispatch({
                                        type: Actions.SET_EYEDROPPER_TOOL_FRAME_COORDINATES,
                                        frameId: undefined,
                                        shelfId: undefined,
                                        placedProductId: undefined,
                                        x: 0,
                                        y: 0,
                                        w: 1,
                                        h: 1,
                                    });
                                }}
                                onMouseDown={(shelfId: number, placedProductId) => {
                                    if (state.eyedropperEnabled) {
                                        dispatch({
                                            type: Actions.SET_EYEDROPPER_TOOL_FRAME_MOUSE_IS_DOWN,
                                            shelfId,
                                            placedProductId,
                                            frameId: undefined,
                                            isDown: true,
                                        });
                                    }
                                }}
                                onMouseUp={(shelfId: number, placedProductId) => {
                                    if (state.eyedropperEnabled) {
                                        dispatch({
                                            type: Actions.SET_EYEDROPPER_TOOL_FRAME_MOUSE_IS_DOWN,
                                            shelfId,
                                            placedProductId,
                                            frameId: undefined,
                                            isDown: false,
                                        });
                                    }
                                }}
                            />
                        </div>
                    );
                })}

                <ShelfGFX />

                <EditorCanvas
                    disableEditing={disableEditing}
                    frameRenderer={(frame: CanvasFrame) => {
                        return (
                            <FrameBody
                                gettext={gettext}
                                frame={frame as Frame}
                                getMediaItemURL={getMediaItemURL}
                                getVideoDataURL={getVideoDataURL}
                                getVideoMediaItemPreviewURL={getVideoMediaItemPreviewURL}
                                disableEditing={disableEditing}
                                autoPlayVideos={autoPlayVideos}
                                disableVideoPosters={disableVideoPosters}
                                loopVideos={loopVideos}
                                renderVideo={renderVideo}
                                renderMediaItemPicker={renderMediaItemPicker}
                                localizeProductName={localizeProductName}
                                onMouseMove={(x, y, w, h) => {
                                    if (state.eyedropperEnabled && hasEyedropCapableFrameMedia(frame as Frame)) {
                                        dispatch({
                                            type: Actions.SET_EYEDROPPER_TOOL_FRAME_COORDINATES,
                                            frameId: frame.frameId,
                                            x,
                                            y,
                                            w,
                                            h,
                                        });
                                    }
                                }}
                                onMouseOut={() => {
                                    dispatch({
                                        type: Actions.SET_EYEDROPPER_TOOL_FRAME_COORDINATES,
                                        frameId: undefined,
                                        x: 0,
                                        y: 0,
                                        w: 1,
                                        h: 1,
                                    });
                                }}
                                onMouseDown={() => {
                                    if (state.eyedropperEnabled && hasEyedropCapableFrameMedia(frame as Frame)) {
                                        dispatch({
                                            type: Actions.SET_EYEDROPPER_TOOL_FRAME_MOUSE_IS_DOWN,
                                            frameId: frame.frameId,
                                            isDown: true,
                                        });
                                    }
                                }}
                                onMouseUp={() => {
                                    if (state.eyedropperEnabled && hasEyedropCapableFrameMedia(frame as Frame)) {
                                        dispatch({
                                            type: Actions.SET_EYEDROPPER_TOOL_FRAME_MOUSE_IS_DOWN,
                                            frameId: frame.frameId,
                                            isDown: false,
                                        });
                                    }
                                }}
                            />
                        );
                    }}
                    getFrameClassName={(frame: CanvasFrame) => (frame as Frame).type}
                    draggedBlockRenderer={() => {
                        return (
                            <div className={customFrameStyles.CustomFrameDragLayer}>
                                <a href="">
                                    <Icon type="action_text" />
                                    <span />
                                    <Icon type="action_photo" />
                                </a>
                            </div>
                        );
                    }}
                    resizeMiddleIsDisabledWhenEditingFrame={(frame: Frame) => frame.type === 'text' || frame.type === 'richText'}
                >
                    {state.eyedropperEnabled ? (
                        <EyedropperTool
                            getProxyURL={getProxyURL}
                            getMediaItemURL={getMediaItemURL}
                            shelves={shelves}
                        />
                    ) : null}
                </EditorCanvas>

                {!disableEditing && state.draggingShelf
                    ? snapPoints.vertical.map(snapPoint => {
                          return (
                              <span
                                  key={snapPoint}
                                  className={styles.SnapGuide}
                                  style={{
                                      top: snapPoint + 'px',
                                  }}
                              />
                          );
                      })
                    : null}
            </div>
        </div>
    );
}

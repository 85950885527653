import { Domain } from 'api';

export interface VideoSlide {
    mediaItemId: Domain.MediaItem['mediaItemId'];
    name: Domain.MediaItem['name'];
}

export type SlideContents = Domain.CustomPage | Domain.ProductWall | VideoSlide;

export type Slide = {
    id: string;
} & SlideContents;

export function isProductWallSlideContents(slide: SlideContents): slide is Domain.ProductWall {
    return (slide as Domain.ProductWall).hasOwnProperty('productWallId');
}

export function isCustomPageSlideContents(slide: SlideContents): slide is Domain.CustomPage {
    return (slide as Domain.CustomPage).hasOwnProperty('customPageId');
}

export function isMediaItemSlideContents(slide: SlideContents): slide is VideoSlide {
    return (slide as Domain.MediaItem).hasOwnProperty('mediaItemId');
}

export type HistoryItem =
    | {
          type: 'search';
          query: string;
          results: undefined | Domain.SelectedProduct[];
      }
    | {
          type: 'productDetails';
          productDetails: Domain.SelectedProduct;
      }
    | {
          type: 'slidesOverview';
          query: string;
      }
    | {
          type: 'hiddenSlide';
          slideId: string;
      }
    | {
          type: 'forcedSlide';
          slideOffset: number;
      }
    | {
          type: 'vendingMachine';
          barcode?: {
              code: string;
              ts: number;
          };
      }
    | {
          type: 'manageScreen';
          query: string;
      }
    | {
          type: 'nightHatch';
          barcode?: {
              code: string;
              ts: number;
          };
      };

export type DuplicatedSlide = Domain.SlideshowSlide & {
    originalSlideId: string;
};

export interface ProductEjectJob {
    jobId: number;
    quantity: number;
    articleCode: string;
    articleTitle: Domain.LocalizedValue;
    status: undefined | 'in-progress' | 'completed' | 'failed';
}

export type Stock = {
    [key: string]: number | undefined;
};

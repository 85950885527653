import * as React from 'react';

import classNames from 'classnames';

import { fonts as baseFonts } from 'config';
import { preloadFont } from 'preloaders';
import { FuseSearchProvider } from 'utils';

import { Card } from '@/card';
import { EyedropStartHandler } from '@/colorPicker/ControlledColorPicker';
import { Dropdown } from '@/core';
import { Icon } from '@/icon';
import { Textarea, Input } from '@/input';
import { Container, Row, Col } from '@/layout';
import { Select } from '@/select';
import { Slider } from '@/slider';
import { ToggleButton } from '@/toggleButton';

import RTValue from './RTValue';
import StatefulColorPicker from './StatefulColorPicker';

import styles from './RTControls.scss';

interface IFontOption {
    label: string;
    value: string;
}

interface IProps {
    value?: RTValue;
    onTextChangeStart?: () => void;
    onOptionsChangeStart?: () => void;
    onChange?: (newValue: RTValue) => void;
    strikethrough?: boolean;
    verticalAlign?: boolean;
    textEditor?: boolean;
    fonts?: IFontOption[];
    extraFonts?: IFontOption[];
    minSize?: number;
    maxSize?: number;
    fontLabel?: string;
    fontSearchPlaceholder?: string;
    fontNoSearchResultsPlaceholder?: string;
    fontSearchInProgressPlaceholder?: string;
    presetColors?: (string | undefined)[];
    onEyedropStart?: EyedropStartHandler;
    onEyedropEnd?: () => void;
}

type Props = IProps & Omit<React.HTMLProps<HTMLDivElement>, 'value' | 'onChange' | 'css'>;

export default class RTControls extends React.PureComponent<Props> {
    private fontSizeInput = React.createRef<HTMLInputElement>();

    private fontsSearchProvider = new FuseSearchProvider(this.getFonts(), {
        includeMatches: false,
        includeScore: false,
        keys: ['label'],
        isCaseSensitive: false,
        threshold: 0.3,
        ignoreLocation: true,
        useExtendedSearch: true,
    });

    render() {
        const {
            value,
            onTextChangeStart,
            onOptionsChangeStart,
            onChange,
            strikethrough,
            verticalAlign,
            textEditor,
            fonts,
            extraFonts,
            minSize,
            maxSize,
            className,
            fontLabel,
            fontSearchPlaceholder,
            fontNoSearchResultsPlaceholder,
            fontSearchInProgressPlaceholder,
            onEyedropStart,
            onEyedropEnd,
            presetColors,
            ...rest
        } = this.props;
        const actualValue = this.getValue();

        return (
            <div
                {...rest}
                className={classNames(styles.RTControls, className)}
            >
                <Container gutter={30}>
                    <Row
                        wrap={true}
                        vAlign="start"
                    >
                        <Col>{this.renderFontSelect()}</Col>

                        <Col>{this.renderFontSizeSelect()}</Col>
                        <Col>{this.renderColorSelect()}</Col>
                    </Row>
                </Container>

                <Container
                    gutter={14}
                    className="mt-12"
                >
                    <Row wrap={true}>
                        <Col>{this.renderBoldToggle()}</Col>
                        <Col>{this.renderItalicToggle()}</Col>
                        <Col>{this.renderUnderlineToggle()}</Col>
                        <Col>{this.props.strikethrough ? this.renderStrikethroughToggle() : null}</Col>

                        <Col>{this.renderAlignJustifyToggle()}</Col>
                        <Col>{this.renderAlignCenterToggle()}</Col>
                        <Col>{this.renderAlignLeftToggle()}</Col>
                        <Col>{this.renderAlignRightToggle()}</Col>

                        {this.props.verticalAlign ? (
                            <React.Fragment>
                                <Col>{this.renderVAlignStartToggle()}</Col>
                                <Col>{this.renderVAlignEndToggle()}</Col>
                                <Col>{this.renderVAlignCenterToggle()}</Col>
                            </React.Fragment>
                        ) : null}
                    </Row>
                </Container>
                {this.props.textEditor ? (
                    <Container className="mt-10">
                        <Row>
                            <Col fullWidth={true}>
                                <Textarea
                                    className={styles.Control + ' ' + styles.TextControl}
                                    value={actualValue ? actualValue.text.replace('&nbsp;', ' ') : undefined}
                                    placeholder="Text"
                                    onChangeStart={onTextChangeStart}
                                    onChange={this.handleTextChange}
                                    disabled={!actualValue}
                                />
                            </Col>
                        </Row>
                    </Container>
                ) : null}
            </div>
        );
    }

    protected renderFontSelect() {
        const value = this.getValue();
        const font = value && value.options ? (value.options as any).font || 'inherit' : 'inherit';

        return (
            <Select
                className={styles.Control + ' ' + styles.FontControl}
                bodyClassName={styles.RTControlsFontControl}
                placeholder={this.props.fontLabel || 'Font'}
                smallHeader={true}
                value={font}
                onChange={this.handleOptionChange('font')}
                disabled={!value}
                withSearch={true}
                searchProvider={this.fontsSearchProvider}
                searchPlaceholder={this.props.fontSearchPlaceholder || 'Font name'}
                noSearchResultsPlaceholder={this.props.fontNoSearchResultsPlaceholder || 'No matching font found'}
                searchInProgressPlaceholder={this.props.fontSearchInProgressPlaceholder || 'Searching...'}
            />
        );
    }

    protected renderFontSizeSelect() {
        const { minSize, maxSize } = this.props;
        const value = this.getValue();
        const size = value && value.options ? (value.options as any).size || 16 : 16;

        return (
            <Dropdown
                className={styles.Control + ' ' + styles.FontSizeControl}
                bodyClassName={styles.RTControlsFontControl}
                headerRenderer={() => (
                    <Input
                        ref={this.fontSizeInput}
                        type="number"
                        value={size}
                        min={minSize || 1}
                        max={maxSize || 200}
                        onChange={this.handleOptionChange('size', newValue => parseInt(newValue, 10) || 16)}
                        disabled={!value}
                    />
                )}
                headerArrow={true}
                body={
                    <Card elevated={true}>
                        <Slider
                            defaultValue={size}
                            min={minSize || 1}
                            max={maxSize || 200}
                            step={1}
                            onChangeComplete={this.handleOptionChange('size')}
                            valueLabel={true}
                        />
                    </Card>
                }
                disabled={!value}
                disableFocus={true}
                onClose={(fromClick: boolean) => {
                    if (fromClick && this.fontSizeInput.current) {
                        this.fontSizeInput.current.focus();
                    }
                }}
            />
        );
    }

    protected renderColorSelect() {
        const value = this.getValue();
        const color = value && value.options ? (value.options as any).color || '#000000' : '#000000';

        return (
            <StatefulColorPicker
                className={styles.Control + ' ' + styles.ColorControl}
                value={color}
                onChangeComplete={this.handleOptionChange('color')}
                onEyedropStart={this.props.onEyedropStart}
                onEyedropEnd={this.props.onEyedropEnd}
                presetColors={this.props.presetColors}
            />
        );
    }

    protected renderBoldToggle() {
        const value = this.getValue();
        return (
            <RTOptionToggleButton
                option="bold"
                optionValue={true}
                value={value}
                onChange={this.handleOptionChange('bold')}
                startIcon={<Icon type="text_bold" />}
            />
        );
    }

    protected renderItalicToggle() {
        const value = this.getValue();
        return (
            <RTOptionToggleButton
                option="italic"
                optionValue={true}
                value={value}
                onChange={this.handleOptionChange('italic')}
                startIcon={<Icon type="text_italic" />}
            />
        );
    }

    protected renderUnderlineToggle() {
        const value = this.getValue();
        return (
            <RTOptionToggleButton
                option="underline"
                optionValue={true}
                value={value}
                onChange={this.handleOptionChange('underline')}
                startIcon={<Icon type="text_underlined" />}
            />
        );
    }

    protected renderStrikethroughToggle() {
        const value = this.getValue();
        return (
            <RTOptionToggleButton
                option="strikethrough"
                optionValue={true}
                value={value}
                onChange={this.handleOptionChange('strikethrough')}
                startIcon={<Icon type="text_strikethrough" />}
            />
        );
    }

    protected renderAlignJustifyToggle() {
        const value = this.getValue();
        return (
            <RTOptionToggleButton
                option="align"
                optionValue="justify"
                value={value}
                onChange={this.handleOptionChange('align')}
                startIcon={<Icon type="text_align_full" />}
            />
        );
    }

    protected renderAlignCenterToggle() {
        const value = this.getValue();
        return (
            <RTOptionToggleButton
                option="align"
                optionValue="center"
                value={value}
                onChange={this.handleOptionChange('align')}
                startIcon={<Icon type="text_align_center" />}
            />
        );
    }

    protected renderAlignLeftToggle() {
        const value = this.getValue();
        return (
            <RTOptionToggleButton
                option="align"
                optionValue="left"
                value={value}
                onChange={this.handleOptionChange('align')}
                startIcon={<Icon type="text_align_left" />}
            />
        );
    }

    protected renderAlignRightToggle() {
        const value = this.getValue();
        return (
            <RTOptionToggleButton
                option="align"
                optionValue="right"
                value={value}
                onChange={this.handleOptionChange('align')}
                startIcon={<Icon type="text_align_right" />}
            />
        );
    }

    protected renderVAlignStartToggle() {
        const value = this.getValue();
        return (
            <RTOptionToggleButton
                option="verticalAlign"
                optionValue="flex-start"
                value={value}
                onChange={this.handleOptionChange('verticalAlign')}
                startIcon={<Icon type="text_align_top" />}
            />
        );
    }

    protected renderVAlignEndToggle() {
        const value = this.getValue();
        return (
            <RTOptionToggleButton
                option="verticalAlign"
                optionValue="flex-end"
                value={value}
                onChange={this.handleOptionChange('verticalAlign')}
                startIcon={<Icon type="text_align_bottom" />}
            />
        );
    }

    protected renderVAlignCenterToggle() {
        const value = this.getValue();
        return (
            <RTOptionToggleButton
                option="verticalAlign"
                optionValue="center"
                value={value}
                onChange={this.handleOptionChange('verticalAlign')}
                startIcon={<Icon type="text_vertical_align_center" />}
            />
        );
    }

    protected getValue(): RTValue | undefined {
        return this.props.value;
    }

    protected handleTextChange = (newValue: string): void => {
        const { onChange, value } = this.props;

        if (onChange) {
            if (value) {
                onChange(value.setText(newValue));
            } else {
                onChange(new RTValue(newValue));
            }
        }
    };

    protected handleOptionChange = (option: string, valueMapper?: (newValue: any) => any) => {
        return (optionValue: any) => {
            const { onChange, onOptionsChangeStart, value } = this.props;

            if (valueMapper) {
                optionValue = valueMapper(optionValue);
            }

            if (option === 'font') {
                preloadFont(optionValue);
            }

            if (onChange) {
                if (onOptionsChangeStart) {
                    onOptionsChangeStart();
                }
                if (value) {
                    onChange(value.setOption(option, optionValue));
                } else {
                    onChange(
                        new RTValue('', {
                            [option]: optionValue,
                        }),
                    );
                }
            }
        };
    };

    protected getFonts(): IFontOption[] {
        const { fonts, extraFonts } = this.props;

        let options: IFontOption[] = baseFonts.map(font => {
            return {
                label: font.label,
                value: font.family,
            };
        });

        if (fonts) {
            options = fonts;
        }

        if (extraFonts) {
            options = [...options, ...extraFonts];
        }

        return options;
    }
}

const RTOptionToggleButton = React.memo(
    (props: {
        value: RTValue | undefined;
        onChange: (newValue: RTValue) => void;
        option: string;
        optionValue: any;
        startIcon?: React.ReactNode;
    }) => {
        const isOn = (props.value && props.value.options && (props.value.options as any)[props.option] === props.optionValue) || false;
        return (
            <ToggleButton
                isOn={isOn}
                onMouseDown={event => event.preventDefault()}
                onChange={newIsOn => {
                    props.onChange(newIsOn ? props.optionValue : undefined);
                }}
                size="xs"
                disabled={!props.value}
                startIcon={props.startIcon}
            />
        );
    },
);

import {
    Pagination,
    Sorting,
    AppointmentTypesPage,
    AppointmentTypeDetails,
    CreateAppointmentTypePayload,
    UpdateAppointmentTypePayload,
    RemoveAppointmentTypePayload,
    AppointmentTypeSlotsList,
} from '@/Domain';

import Endpoint from './Endpoint';

export default class AppointmentTypeEndpoint extends Endpoint {
    public async GetAppointmentTypes(pagination?: Pagination, sorting?: Sorting, search?: string): Promise<AppointmentTypesPage> {
        const response = await this.client.get({
            url: '/appointment/type/overview',
            params: {
                pagination,
                sorting,
                search,
            },
        });
        this.guardResponseSchema('#/definitions/AppointmentTypesPage', response);
        return response;
    }
    public async GetAppointmentTypeSlots(appointmentTypeId: string, fromTo: string): Promise<AppointmentTypeSlotsList> {
        const response = await this.client.get({
            url: `/appointment/type/${appointmentTypeId}/slots`,
            params: {
                fromTo,
            },
        });
        this.guardResponseSchema('#/definitions/AppointmentTypeSlotsList', response);
        return response;
    }

    public async GetAppointmentTypeDetails(appointmentTypeId: string): Promise<AppointmentTypeDetails> {
        const response = await this.client.get({
            url: `/appointment/type/details/${appointmentTypeId}`,
        });

        this.guardResponseSchema('#/definitions/AppointmentTypeDetails', response);

        return response;
    }

    public async CreateAppointmentType(payload: CreateAppointmentTypePayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/CreateAppointmentType',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Appointment\\AppointmentType\\UpdateAppointmentType\\UpdateAppointmentType',
                payload,
            },
        });
    }

    public async UpdateAppointmentType(payload: UpdateAppointmentTypePayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateAppointmentType',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Appointment\\AppointmentType\\UpdateAppointmentType\\UpdateAppointmentType',
                payload,
            },
        });
    }

    public async RemoveAppointment(payload: RemoveAppointmentTypePayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/RemoveAppointmentType',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Appointment\\AppointmentType\\RemoveAppointmentType\\RemoveAppointmentType',
                payload,
            },
        });
    }
}

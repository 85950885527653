import { Domain } from 'api';

import { FrameWithMeta, Frame, ProductImageSizes } from '../types';
import computeProductSize from './computeProductSize';
import fillProductArea from './fillProductArea';
import getProductImages from './getProductImages';
import getSelectedImage from './getSelectedImage';

export default function computeFrameMetas(
    screenResolution: Domain.DeviceScreenResolution,
    frame: Frame,
    availableProducts: Domain.SlideshowProduct[],
    productImageSizes: ProductImageSizes,
): FrameWithMeta {
    if (frame.type === 'product') {
        const product = availableProducts.find(searchedProduct => searchedProduct.productId === frame.productId);

        if (product) {
            const productImages = getProductImages(product);
            const selectedImage = getSelectedImage(productImages, frame.productMediaType, frame.imageId);

            if (productImageSizes.hasOwnProperty(selectedImage.url)) {
                const productSize = computeProductSize(screenResolution, product, productImageSizes[selectedImage.url]);

                const boxes = fillProductArea({
                    areaWidth: frame.width,
                    areaHeight: frame.height,
                    productWidth: productSize.width,
                    productHeight: productSize.height,
                    scale: frame.placedProduct.scale || 1,
                    itemsLimit: frame.placedProduct.enableItemsLimit ? frame.placedProduct.itemsLimit : undefined,
                    alignItems: frame.placedProduct.alignItems || 'center',
                    spacingX: frame.placedProduct.spacingX || 0,
                    spacingY: frame.placedProduct.spacingY || 0,
                    bottomOffset: 0,
                });

                return {
                    ...frame,
                    boxes,
                    product,
                };
            }
        }

        return {
            ...frame,
            boxes: [],
        };
    }

    return frame;
}

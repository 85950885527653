import * as React from 'react';

import ownerDocument from './ownerDocument';
import useEventCallback from './useEventCallback';
import useForkRef from './useForkRef';

function mapEventPropToEvent(eventProp: string) {
    return eventProp.substring(2).toLowerCase();
}

function clickedRootScrollbar(event: MouseEvent, doc: Document) {
    return doc.documentElement.clientWidth < event.clientX || doc.documentElement.clientHeight < event.clientY;
}

export default function ClickOutsideListener(props: {
    children: React.ReactNode;
    disableReactTree?: boolean;
    mouseEvent?: 'onClick' | 'onMouseDown' | 'onMouseUp' | false;
    onClickOutside: (event: React.MouseEvent<Document>) => void;
    touchEvent?: 'onTouchStart' | 'onTouchEnd' | false;
}) {
    const { children, disableReactTree = false, mouseEvent = 'onClick', onClickOutside, touchEvent = 'onTouchEnd' } = props;
    const movedRef = React.useRef(false);
    const nodeRef = React.useRef(null);
    const activatedRef = React.useRef(false);
    const syntheticEventRef = React.useRef(false);

    React.useEffect(() => {
        // Ensure that this component is not "activated" synchronously.
        // https://github.com/facebook/react/issues/20074
        setTimeout(() => {
            activatedRef.current = true;
        }, 0);
        return () => {
            activatedRef.current = false;
        };
    }, []);

    const handleRef = useForkRef((children as any).ref, nodeRef);

    // The handler doesn't take event.defaultPrevented into account:
    //
    // event.preventDefault() is meant to stop default behaviors like
    // clicking a checkbox to check it, hitting a button to submit a form,
    // and hitting left arrow to move the cursor in a text input etc.
    // Only special HTML elements have these default behaviors.
    const handleClickAway = useEventCallback((event: any) => {
        // Given developers can stop the propagation of the synthetic event,
        // we can only be confident with a positive value.
        const insideReactTree = syntheticEventRef.current;
        syntheticEventRef.current = false;

        const doc = ownerDocument(nodeRef.current);

        // 1. IE11 support, which trigger the handleClickAway even after the unbind
        // 2. The child might render null.
        // 3. Behave like a blur listener.
        if (!activatedRef.current || !nodeRef.current || clickedRootScrollbar(event, doc)) {
            return;
        }

        // Do not act if user performed touchmove
        if (movedRef.current) {
            movedRef.current = false;
            return;
        }

        let insideDOM;

        // If not enough, can use https://github.com/DieterHolvoet/event-propagation-path/blob/master/propagationPath.js
        if (event.composedPath) {
            insideDOM = event.composedPath().indexOf(nodeRef.current) > -1;
        } else {
            insideDOM = !doc.documentElement.contains(event.target) || (nodeRef.current && (nodeRef.current as any).contains(event.target));
        }

        if (!insideDOM && (disableReactTree || !insideReactTree)) {
            onClickOutside(event);
        }
    });

    // Keep track of mouse/touch events that bubbled up through the portal.
    const createHandleSynthetic = (handlerName: string) => (event: any) => {
        syntheticEventRef.current = true;

        const childrenPropsHandler = (children as any).props[handlerName];
        if (childrenPropsHandler) {
            childrenPropsHandler(event);
        }
    };

    const childrenProps: any = { ref: handleRef };

    if (touchEvent !== false) {
        childrenProps[touchEvent] = createHandleSynthetic(touchEvent);
    }

    React.useEffect(() => {
        if (touchEvent !== false) {
            const mappedTouchEvent = mapEventPropToEvent(touchEvent);
            const doc = ownerDocument(nodeRef.current);

            const handleTouchMove = () => {
                movedRef.current = true;
            };

            doc.addEventListener(mappedTouchEvent, handleClickAway);
            doc.addEventListener('touchmove', handleTouchMove);

            return () => {
                doc.removeEventListener(mappedTouchEvent, handleClickAway);
                doc.removeEventListener('touchmove', handleTouchMove);
            };
        }

        return undefined;
    }, [handleClickAway, touchEvent]);

    if (mouseEvent !== false) {
        childrenProps[mouseEvent] = createHandleSynthetic(mouseEvent);
    }

    React.useEffect(() => {
        if (mouseEvent !== false) {
            const mappedMouseEvent = mapEventPropToEvent(mouseEvent);
            const doc = ownerDocument(nodeRef.current);

            doc.addEventListener(mappedMouseEvent, handleClickAway);

            return () => {
                doc.removeEventListener(mappedMouseEvent, handleClickAway);
            };
        }

        return undefined;
    }, [handleClickAway, mouseEvent]);

    return <React.Fragment>{React.cloneElement(children as any, childrenProps)}</React.Fragment>;
}

import * as React from 'react';

import { useGettext } from 'data-store';
import { Button, Notification, Card, CardItem, Tx } from 'ui-components';

import useVideoCall from './useVideoCall';

import styles from './VideoCall.scss';

export function VideoCall() {
    const { gettext } = useGettext();
    const call = useVideoCall();

    if (!call.isEnabled) {
        return null;
    }

    return (
        <div
            className={styles.VideoCallPanel}
            style={{
                display: call.panel.isOpened ? 'block' : 'none',
                top: call.panel.position.top + 'px',
                right: call.panel.position.right + 'px',
            }}
            onClick={call.panel.handleClickInsidePanel}
        >
            <audio
                src="/static/matic/phone-ringing.mp3"
                ref={element => call.setAlertAudioElement(element || undefined)}
                autoPlay={false}
                loop={true}
            ></audio>

            <Card
                hSpacing="none"
                vSpacing="none"
                elevated={true}
                className="mt-7"
            >
                <div
                    style={{
                        display: call.context.callStatus === 'in-progress' ? 'block' : 'none',
                    }}
                >
                    <audio
                        ref={element => call.setAudioElement(element || undefined)}
                        autoPlay
                    ></audio>
                    <video
                        ref={element => call.setVideoElement(element || undefined)}
                        autoPlay
                    ></video>
                </div>

                {call.context.callStatus === 'in-progress' ? (
                    <React.Fragment>
                        <Button
                            variant="secondary"
                            variantSize="s"
                            className="mt-16"
                            onClick={() => call.rejectIncomingCall()}
                        >
                            {gettext('Hang up')}
                        </Button>

                        {call.context.isMuted ? (
                            <Button
                                variant="secondary"
                                variantSize="s"
                                className="mt-16 ml-16"
                                onClick={() => call.unmute()}
                            >
                                {gettext('Unmute')}
                            </Button>
                        ) : (
                            <Button
                                variant="secondary"
                                variantSize="s"
                                className="mt-16 ml-16"
                                onClick={() => call.mute()}
                            >
                                {gettext('Mute')}
                            </Button>
                        )}
                    </React.Fragment>
                ) : call.context.callStatus === 'ringing' ? (
                    <React.Fragment>
                        <Button
                            variant="primary"
                            onClick={() => call.answerIncomingCall()}
                            className={styles.ShakeButton}
                        >
                            {gettext('Answer')}
                        </Button>

                        <Button
                            variant="secondary"
                            onClick={() => call.rejectIncomingCall()}
                            className="ml-16"
                        >
                            {gettext('Reject')}
                        </Button>
                    </React.Fragment>
                ) : call.context.registrationStatus === 'registration-failing' ? (
                    <Notification
                        type="info"
                        noCloseAction={true}
                    >
                        {gettext('We are having trouble connecting you to the MT.Matic video call service.')}
                    </Notification>
                ) : (
                    <React.Fragment>
                        <Tx as="p">{gettext('You are connected to the MT.Matic video call service.')}</Tx>
                        <Tx as="p">{gettext('Incoming calls will be shown here automatically.')}</Tx>
                    </React.Fragment>
                )}

                {call.showAudioPermissionsMessage ? (
                    <CardItem>
                        <a
                            href="https://championcr.com/topic/enable-auto-play"
                            target="_blank"
                        >
                            {gettext(
                                'We have detected that your browser is blocking the alert sound. Please click here and follow the guide to enable sounds in your browser.',
                            )}
                        </a>
                    </CardItem>
                ) : null}
            </Card>
        </div>
    );
}

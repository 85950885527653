import * as React from 'react';

import classNames from 'classnames';

import { Card } from '@/card';
import { BaseProps } from '@/core';
import { Icon } from '@/icon';
import { Tx } from '@/typography';

import styles from './Notification.scss';

interface IProps {
    type: 'info' | 'error' | 'warning' | 'success' | 'comment' | 'custom';
    onClose?: (event: React.MouseEvent) => void;
    noCloseAction?: boolean;
    elevated?: boolean;
    noOutline?: boolean;
    position?: 'top' | 'bottom' | 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
    inline?: boolean;
    small?: boolean;
    noFixedHeight?: boolean;
    fill?: boolean;
}

export default class Notification extends React.PureComponent<React.HTMLProps<HTMLDivElement> & BaseProps & IProps> {
    static defaultProps = {
        elevated: true,
    };

    render() {
        const {
            className,
            type,
            position,
            elevated,
            noOutline,
            noCloseAction,
            noFixedHeight,
            onClose,
            inline,
            children,
            small,
            ref,
            fill,
            ...rest
        } = this.props;
        return (
            <Card
                {...rest}
                ref={ref as any}
                className={classNames(
                    styles.Notification,
                    className,
                    type === 'custom' ? styles.CustomNotification : null,
                    position ? 'positioned position-' + position : null,
                    inline ? styles.InlineNotification : null,
                    small ? styles.small : null,
                    type,
                    fill ? 'fill' : null,
                )}
                elevated={elevated}
                noOutline={noOutline}
            >
                {this.renderIcon()}

                <Tx
                    className={noFixedHeight ? styles.NotificationText + ' ' + styles.NoFixedHeight : styles.NotificationText}
                    level="body-md"
                >
                    {children}
                </Tx>

                {noCloseAction ? null : (
                    <a
                        href=""
                        className={styles.NotificationAction}
                        onClick={event => {
                            event.preventDefault();

                            if (onClose) {
                                onClose(event);
                            }
                        }}
                        data-test-id="close-notification"
                    >
                        <Icon
                            type="action_remove"
                            iconSize="m"
                        />
                    </a>
                )}
            </Card>
        );
    }

    private renderIcon() {
        const { type } = this.props;

        switch (type) {
            case 'info':
                return (
                    <Icon
                        type="message_info"
                        iconSize="m"
                        className={classNames(styles.NotificationIcon)}
                    />
                );

            case 'error':
                return (
                    <Icon
                        type="message_error"
                        iconSize="m"
                        className={classNames(styles.NotificationIcon)}
                    />
                );

            case 'warning':
                return (
                    <Icon
                        type="message_warning"
                        iconSize="m"
                        className={classNames(styles.NotificationIcon)}
                    />
                );

            case 'success':
                return (
                    <Icon
                        type="message_success"
                        iconSize="m"
                        className={classNames(styles.NotificationIcon)}
                    />
                );
            case 'comment':
                return (
                    <Icon
                        type="message_comment"
                        iconSize="m"
                        className={classNames(styles.NotificationIcon)}
                    />
                );
        }

        return null;
    }
}

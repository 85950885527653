import * as React from 'react';

import styles from './styles/Canvas.scss';

export default function ShelfGFX() {
    return (
        <React.Fragment>
            <span className={styles.GFXTopLeft} />
            <span className={styles.GFXTop} />
            <span className={styles.GFXTopRight} />
            <span className={styles.GFXLeft} />
            <span className={styles.GFXRight} />
            <span className={styles.GFXBottomLeft} />
            <span className={styles.GFXBottom} />
            <span className={styles.GFXBottomRight} />
        </React.Fragment>
    );
}

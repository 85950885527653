import * as DeviceInterface from 'meditech-device-interface';

import { LocalCommClient } from './index';
import {
    DeviceInfo,
    PrinterStatus,
    PlcHandleValue,
    PlcStatus,
    PlcJobStatus,
    PrintscreenStatus, LockerConfigurationResponseMessage, LockerStatusResponseMessage, ThermoValuesResponseMessage
} from '../../Domain';
import { Signal } from '../Signal';


export default class LocalComm {
    ThermoValuesReceived = new Signal<LocalCommClient, ThermoValuesResponseMessage>();
    LockerStatusReceived = new Signal<LocalCommClient, LockerStatusResponseMessage>();

    private url: string;
    private client: LocalCommClient;

    private screenOnOffStatus: 'on' | 'off' | 'unknown' = 'unknown';

    public constructor(url: string) {
        this.url = url;
        this.client = new LocalCommClient();

        window.addEventListener('beforeunload', () => {
            this.SendWatchdogStop();
        });

        this.client.ThermoValuesReceived.on(
            (localComClient, message) =>
                this.ThermoValuesReceived.trigger(localComClient, message));

        this.client.LockerStatusReceived.on(
            (localComClient, message) =>
                this.LockerStatusReceived.trigger(localComClient, message));
    }

    public async Listen(
        errorHandler: (error: Error | Event) => void
    ): Promise<boolean> {
        return await this.client.listen(this.url, errorHandler);
    }

    public OnFirstConnect(callback: () => void, id: string) {
        if (this.client.isConnected()) {
            callback();
        }
        else {
            this.client.SetOnConnectionOpen(() => {
                this.client.UnSetOnConnectionOpen(id);
                callback();
            }, id);
        }
    }

    public async SendGetInfoAndReceive(): Promise<boolean | DeviceInfo> {
        let tvid: undefined | number;
        if (window && window.location && window.location.hash) {
            const hash = parseInt(window.location.hash.replace('#', ''));
            if (Number.isFinite(hash)) {
                tvid = hash;
            }
        }
        return await this.client.SendGetInfoAndProcess(tvid);
    }

    public GetLastInfo() {
        return this.client.getLastInfo();
    }

    public ScreenIsOn() {
        return this.screenOnOffStatus === 'on';
    }

    public ScreenIsOnUnknown() {
        return this.screenOnOffStatus === 'unknown';
    }

    public SendScreenEnable() {
        if (!this.ScreenIsOn() || this.ScreenIsOnUnknown()) {
            // tslint:disable-next-line:no-console
            console.info('TURN SCREEN ON', new Date());

            this.client.send({
                action: 'command',
                command: {
                    screen: 'enable'
                }
            });

            this.screenOnOffStatus = 'on';
        }
    }

    public SendScreenDisable() {
        if (this.ScreenIsOn() || this.ScreenIsOnUnknown()) {
            // tslint:disable-next-line:no-console
            console.info('TURN SCREEN OFF', new Date());

            this.client.send({
                action: 'command',
                command: {
                    screen: 'hibernate'
                }
            });

            this.screenOnOffStatus = 'off';
        }
    }

    public SendWatchdogStop() {
        this.client.stopWatchdog();
    }

    public SendWatchdogStart() {
        this.client.startWatchdog();
    }

    public SendStartVideoCall(videoPhoneIpAddress: string) {
        this.client.send({
            action: 'command',
            command: {
                notification: videoPhoneIpAddress
            }
        });
    }

    public SendPcReboot() {
        this.client.send({
            action: 'command',
            command: {
                pc: 'reboot'
            }
        });
    }

    public SendPcShutdown() {
        this.client.send({
            action: 'command',
            command: {
                pc: 'shutdown'
            }
        });
    }

    public SendPcSoftwareReboot() {
        this.client.send({
            action: 'command',
            command: {
                pc: 'restartsw'
            }
        });
    }

    public TakePictureOfDeliveryBox(ip: string) {
        this.client.send({
            action: 'command',
            command: {
                notification: ip
            }
        });
    }

    public StartCalling(ip: string) {
        this.client.send({
            action: 'command',
            command: {
                notification: ip
            }
        });
    }

    public SendTransactionBusy() {
        this.client.send({
            action: 'plc',
            command: {
                variable: 'bTransactionBusy',
                value: true
            }
        });
    }

    public SendTransactionNotBusy() {
        this.client.send({
            action: 'plc',
            command: {
                variable: 'bTransactionBusy',
                value: false
            }
        });
    }

    public SendDeliveryBoxLEDOn() {
        this.client.send({
            action: 'plc',
            command: {
                variable: 'bLightOn',
                value: true
            }
        });
    }

    public SendDeliveryBoxLEDOff() {
        this.client.send({
            action: 'plc',
            command: {
                variable: 'bLightOn',
                value: false
            }
        });
    }

    public SendFunctionButtonLed(value: 'off' | 'continue' | 'slow' | 'fast') {

        let iValue = 0;
        switch(value){
            case 'off':
                iValue = 0;
                break;
            case 'continue':
                iValue = 1;
                break;
            case 'slow':
                iValue = 2;
                break;
            case 'fast':
                iValue = 3;
                break;
        }

        this.client.send({
            action: 'plc',
            command: {
                variable: 'iLedFunctionButton',
                value: iValue
            }
        });
    }

    public GetLastBarcodeInfo() {
        return this.client.getLastBarcodeInfo();
    }

    public SendBarcodeScannerEnable() {
        this.client.send({
            action: 'scanner',
            scanner_task: 'enable'
        });
    }

    public SendBarcodeScannerDisable() {
        this.client.send({
            action: 'scanner',
            scanner_task: 'disable'
        });
    }

    public SendOpenTechnicalFrontDoor() {
        this.client.send({
            action: 'plc',
            command: {
                variable: 'bOpenFront',
                value: true
            }
        });
    }

    public ReadBoxDetectCounter() {
        if(this.client.maticPLC){
            let plcInfo = this.client.maticPLC.getLastPLCStatus();
            if(plcInfo.status.prgVersion >= 115){
                this.client.send({
                    action: 'plc',
                    command: {
                        variable: 'iBoxDetectCounter',
                    }
                });
            }
        }
    }

    public ReadMachineType() {
        if(this.client.maticPLC){
            let plcInfo = this.client.maticPLC.getLastPLCStatus();
            if(plcInfo.status.prgVersion >= 115){
                this.client.send({
                    action: 'plc',
                    command: {
                        variable: 'iMachineType',
                    }
                });
            }
        }
    }

    public ReadStatusTechnicalFrontDoor() {
        if(this.client.maticPLC){
            let plcInfo = this.client.maticPLC.getLastPLCStatus();
            if(plcInfo.status.prgVersion >= 115){
                this.client.send({
                    action: 'plc',
                    command: {
                        variable: 'bOpenFront',
                    }
                });
            }
        }
    }

    public ReadPLCProgramVersion() {
        if(this.client.maticPLC){
            let plcInfo = this.client.maticPLC.getLastPLCStatus();
            if(plcInfo.status.prgVersion >= 115){
                this.client.send({
                    action: 'plc',
                    command: {
                        variable: 'iPrgVersion',
                    }
                });
            }
        }
    }

    public ReadPLCUpTime() {
        if(this.client.maticPLC){
            let plcInfo = this.client.maticPLC.getLastPLCStatus();
            if(plcInfo.status.prgVersion >= 115){
                this.client.send({
                    action: 'plc',
                    command: {
                        variable: 'tSystemUpTime',
                    }
                });
            }
        }
    }

    public ReadStatusLedOfDeliberyBox() {
        if(this.client.maticPLC){
            let plcInfo = this.client.maticPLC.getLastPLCStatus();
            if(plcInfo.status.prgVersion >= 115){
                this.client.send({
                    action: 'plc',
                    command: {
                        variable: 'bLightOn',
                    }
                });
            }
        }
    }

    public ReadStatusOfPlcTransaction() {
        if(this.client.maticPLC){
            let plcInfo = this.client.maticPLC.getLastPLCStatus();
            if(plcInfo.status.prgVersion >= 115){
                this.client.send({
                    action: 'plc',
                    command: {
                        variable: 'bTransactionBusy',
                    }
                });
            }
        }
    }

    public ResetBoxDetectCounter() {
        if(this.client.maticPLC){
            let plcInfo = this.client.maticPLC.getLastPLCStatus();
            if(plcInfo.status.prgVersion >= 115){
                this.client.send({
                    action: 'plc',
                    command: {
                        variable: 'bBoxCounterReset',
                        value: true
                    }
                });
            }
            this.client.maticPLC.resetBoxDetectCounter('all');
        }
    }

    public SendStartRejectOfDeliveryBox(): Promise<boolean | PlcJobStatus> {
        return new Promise((resolve) => {
            if (this.client.maticPLC) {
                this.client.maticPLC.sendStartAndProcessTheJob(201, Date.now()).then((plcJobHandleStatus) => {
                    if (plcJobHandleStatus.status) {
                        resolve(this.client.maticPLC.getPlcJobProcessStatus(plcJobHandleStatus.statusId));
                    } else {
                        resolve(false);
                    }
                });
            } else {
                resolve(false);
            }
        });
    }

    public SendStartCloseDeliveryDoorAndRejectOfDeliveryBox(): Promise<boolean | PlcJobStatus> {
        return new Promise((resolve) => {
            if (this.client.maticPLC) {
                this.client.maticPLC.sendStartAndProcessTheJob(204, Date.now()).then((plcJobHandleStatus) => {
                    if (plcJobHandleStatus.status) {
                        resolve(this.client.maticPLC.getPlcJobProcessStatus(plcJobHandleStatus.statusId));
                    } else {
                        resolve(false);
                    }
                });
            } else {
                resolve(false);
            }
        });
    }

    public SendStartOpenDeliveryDoor(): Promise<boolean | PlcJobStatus> {
        return new Promise((resolve) => {
            if (this.client.maticPLC) {
                this.client.maticPLC.sendStartAndProcessTheJob(202, Date.now()).then((plcJobHandleStatus) => {
                    if (plcJobHandleStatus.status) {
                        resolve(this.client.maticPLC.getPlcJobProcessStatus(plcJobHandleStatus.statusId));
                    } else {
                        resolve(false);
                    }
                });
            } else {
                resolve(false);
            }
        });
    }

    public SendStartCloseDeliveryDoor(): Promise<boolean | PlcJobStatus> {
        return new Promise((resolve) => {
            if (this.client.maticPLC) {
                this.client.maticPLC.sendStartAndProcessTheJob(203, Date.now()).then((plcJobHandleStatus) => {
                    if (plcJobHandleStatus.status) {
                        resolve(this.client.maticPLC.getPlcJobProcessStatus(plcJobHandleStatus.statusId));
                    } else {
                        resolve(false);
                    }
                });
            } else {
                resolve(false);
            }
        });
    }

    public SendStartOpenRejectDoor(): Promise<boolean | PlcJobStatus> {
        return new Promise((resolve) => {
            if (this.client.maticPLC) {
                this.client.maticPLC.sendStartAndProcessTheJob(205, Date.now()).then((plcJobHandleStatus) => {
                    if (plcJobHandleStatus.status) {
                        resolve(this.client.maticPLC.getPlcJobProcessStatus(plcJobHandleStatus.statusId));
                    } else {
                        resolve(false);
                    }
                });
            } else {
                resolve(false);
            }
        });
    }

    public SendStartForwardCVAndCloseRearDoor(): Promise<boolean | PlcJobStatus> {
        //forward + open frontdoor or is forward + close rear door > to check with Djes
        return new Promise((resolve) => {
            if (this.client.maticPLC) {
                this.client.maticPLC.sendStartAndProcessTheJob(206, Date.now()).then((plcJobHandleStatus) => {
                    if (plcJobHandleStatus.status) {
                        resolve(this.client.maticPLC.getPlcJobProcessStatus(plcJobHandleStatus.statusId));
                    } else {
                        resolve(false);
                    }
                });
            } else {
                resolve(false);
            }
        });
    }

    public SendStartCloseRejectDoor(): Promise<boolean | PlcJobStatus> {
        return new Promise((resolve) => {

            if (this.client.maticPLC) {
                this.client.maticPLC.sendStartAndProcessTheJob(207, Date.now()).then((plcJobHandleStatus) => {
                    if (plcJobHandleStatus.status) {
                        resolve(this.client.maticPLC.getPlcJobProcessStatus(plcJobHandleStatus.statusId));
                    } else {
                        resolve(false);
                    }
                });
            } else {
                resolve(false);
            }
        });
    }

    public async SendTakePrintscreen(endPoint: string, filename: string): Promise<boolean | PrintscreenStatus> {
        return await this.client.SendTakePrintscreenAndProcess(endPoint, filename);
    }

    public GetBoxDetectCounterPlc(): Promise<number> {
        return new Promise(resolve => {
            if (this.client.maticPLC) {
                resolve(this.client.maticPLC.getBoxDetectCounterPlc());
            } else {
                resolve(0);
            }
        });
    }

    public GetBoxDetectCounterSoftware(): Promise<number> {
        return new Promise(resolve => {
            if (this.client.maticPLC) {
                resolve(this.client.maticPLC.getBoxDetectCounterSoftware());
            } else {
                resolve(0);
            }
        })
    }

    public ResetPlcSoftware(): Promise<boolean> {

        if (this.client.maticPLC) {
            return this.client.maticPLC.plcChangeRunningMode('reset');
        } else {
            return Promise.resolve(false);
        }
    }

    public StopPlcSoftware(): Promise<boolean> {
        if (this.client.maticPLC) {
            return this.client.maticPLC.plcChangeRunningMode('stop');
        } else {
            return Promise.resolve(false);
        }
    }

    public PlcSoftware2Auto(): Promise<boolean> {
        if (this.client.maticPLC) {
            return this.client.maticPLC.plcChangeRunningMode('auto');
        } else {
            return Promise.resolve(false);
        }
    }

    public PlcSoftware2Man(): Promise<boolean> {
        if (this.client.maticPLC) {
            return this.client.maticPLC.plcChangeRunningMode('man');
        } else {
            return Promise.resolve(false);
        }
    }

    public ResetPlcSoftwareErrors(): Promise<boolean> {
        if (this.client.maticPLC) {
            return this.client.maticPLC.plcChangeRunningMode('resetPlcError');
        } else {
            return Promise.resolve(false);
        }
    }

    public GetPlcStatus(): Promise < boolean | PlcStatus>{
        return new Promise( resolve => {
            if(this.client.maticPLC){
                resolve(this.client.maticPLC.plcStatus)
            }else{
                resolve(false);
            }
        });
    }

    public GetPrinterStatus(): Promise < PrinterStatus>{
        return new Promise( resolve => {
            resolve(this.client.getLastPrinterStatus());
        });
    }

    public SendEjectTicketOnPrinter(): Promise < boolean>{
       return new Promise( resolve => {
            this.client.send({
                action: 'printer',
                printer_task: 'eject'
            });
            resolve(true);
       });
    }

    public GetMessage(): Promise<any> {
        return this.client.getMessage();
    }

    public SetOnGetInfoMessageCallback(callback: (data: DeviceInfo) => void, id: string = 'default') {
        this.client.setOnGetInfoMessageCallback(callback, id);
    }

    public SetOnPrinterStatusMessageCallback(callback: (data: PrinterStatus) => void, id: string = 'default') {
        this.client.setOnPrinterStatusMessageCallback(callback, id);
    }

    public SetOnBarcodeMessageCallback(callback: (data: string) => void, id: string = 'default') {
        this.client.setOnBarcodeMessageCallback(callback, id);
    }

    public SetOnPLCMessageCallback(callback: (plcHandleValue: PlcHandleValue, plcStatus: PlcStatus) => void, id: string = 'default') {
        if(this.client.maticPLC){
            this.client.maticPLC.setOnPLCMessageCallback(callback, id);
        }
    }

    public SetOnPLCEmergencyCallback(callback: (plcEmergencyValue: any, plcStatus: PlcStatus) => void, id: string = 'default') {
        if(this.client.maticPLC){
            this.client.maticPLC.SetOnPLCEmergencyCallback(callback, id);
        }
    }

    public SetOnPLCHasBeenReleasedCallback(callback: (mode: string, afterEmergency: boolean) => void, id: string = 'default') {
        if(this.client.maticPLC){
            this.client.maticPLC.SetOnPLCHasBeenReleasedCallback(callback, id);
        }
    }

    public SetOnOpenConnectionCallback(callback: () => void, id: string = 'default') {
        this.client.SetOnConnectionOpen(callback, id);
    }

    public UnsetOnOpenConnectionCallback(id: string = 'default') {
        this.client.UnSetOnConnectionOpen(id);
    }

    public SetOnCloseConnectionCallback(callback: (event: CloseEvent) => void, id: string = 'default') {
        this.client.SetOnConnectionClose(callback, id);
    }

    public IsLocalCommConnected() {
        return this.client.localCommStatus.connected;
    }

    public deletePlcProcess(processId: string | number){
        if(this.client.maticPLC){
            this.client.maticPLC.deletePlcProcessStatus(processId);
        }
    }

    public ResetPlcMessages() {
        if(this.client.maticPLC){
            this.client.maticPLC.plcResetPlcMessages();
        }
    }


    // --- --- ---
    public async GetHatchPhoto(): Promise<false | string> {
        return await this.client.GetHatchPhoto();
    }

    public async EnsureSipConfigured(sipAccount: DeviceInterface.ISipAccount) {
        return await this.client.EnsureSipConfigured(sipAccount);
    }

    public async Call(sipCall: DeviceInterface.ISipCall) {
        return await this.client.Call(sipCall);
        
    }
    public async GetCallStatus(callId?: string) {
        return await this.client.GetCallStatus(callId);
    }

    public async TerminateCall(callId: string) {
        return await this.client.TerminateCall(callId);
    }

    public async GetLockerConfiguration():
        Promise<LockerConfigurationResponseMessage>  {

        const command =
        {
            messagetype: 'get_locker_configuration'
        }

        this.client.send(command);
        const responseMessage = await this.client.GetResponseMessage(
            'locker_configuration', 100, 3000);

        return Promise.resolve(responseMessage as LockerConfigurationResponseMessage);
    }

    public async GetLockerStatus(
        moduleNumber?: number,
        lockerNumber?: number):
        Promise<LockerStatusResponseMessage> {

        let command: any;
        command =
        {
            messagetype: 'get_locker_status'
        }

        if (moduleNumber && lockerNumber) {
            command =
            {
                messagetype: 'get_locker_status',
                criteria:
                {
                    lockermodule: moduleNumber!,
                    lockernr: lockerNumber!
                }
            }
        }

        this.client.send(command);
        const responseMessage = await this.client.GetResponseMessage(
            'locker_status', 100, 3000);

        return Promise.resolve(responseMessage as LockerStatusResponseMessage);
    }

    public async OpenLocker(
        moduleReference: number,
        sequenceNumber: number):
        Promise<LockerStatusResponseMessage> {

        const command =
        {
            messagetype: 'open_locker',
            modulenr: moduleReference,
            lockernr: sequenceNumber
        }

        this.client.send(command);
        const responseMessage = await this.client.GetResponseMessage(
            'locker_status', 100, 3000);

        return Promise.resolve(responseMessage as LockerStatusResponseMessage);
    }

    public async GetThermoValues(
        moduleReference: number):
        Promise<ThermoValuesResponseMessage> {

        const command =
        {
            messagetype: 'get_thermo_values',
            criteria:
            {
                lockermodule: moduleReference
            }
        }

        this.client.send(command);
        const responseMessage = await this.client.GetResponseMessage(
            'thermo_values', 100, 3000);

        return Promise.resolve(responseMessage as ThermoValuesResponseMessage);
    }
}

/* eslint-disable local-rules/force-use-gettext */
import * as React from 'react';
import { useSelector } from 'react-redux';

import { Domain } from 'api';
import { useThunkDispatch, authenticationApi, meditechDeviceApi, TVScreen, Authentication } from 'data-store';
import { Input } from 'ui-components';

import Button from '@/components/Button';
import InteractiveScreenNavigation from '@/components/InteractiveScreenNavigation';
import OnScreenKeyboard from '@/components/OnScreenKeyboard';

import styles from './ManageScreen.scss';
import screenStyles from '@/components/InteractiveScreen.scss';

export default function ManageScreen(props: { getLanguageLabel: (locale: Domain.Locale) => React.ReactNode }) {
    const inputRef = React.useRef<HTMLInputElement>();
    const dispatch = useThunkDispatch();
    const currentHistory = useSelector(TVScreen.interactionState.selectCurrentHistory);
    const [attemptingImpersonate, setAttemptingImpersonate] = React.useState(false);
    const [impersonateError, setImpersonateError] = React.useState(false);
    const alreadyImpersonatingMacAddress = Authentication.getImpersonatingDeviceWithMacAddress();

    if (!currentHistory || currentHistory.type !== 'manageScreen') {
        return null;
    }

    return (
        <div className={`${screenStyles.InteractiveScreen} ${styles.ManageScreen}`}>
            <div>
                <div className={styles.ManageScreenButton}>
                    <Button
                        variant="primary"
                        variantSize="xl"
                        className="mb-70"
                        onClick={() => {
                            meditechDeviceApi.RebootPC();
                        }}
                    >
                        Reboot hardware
                    </Button>
                </div>
                <div className={styles.ManageScreenButton}>
                    <Button
                        variant="primary"
                        variantSize="xl"
                        onClick={() => {
                            meditechDeviceApi.RestartSoftware();
                        }}
                    >
                        Reboot software
                    </Button>
                </div>

                {alreadyImpersonatingMacAddress ? (
                    <div className={styles.ManageScreenSection}>
                        <p>Impersonating device with MAC address {alreadyImpersonatingMacAddress}</p>
                        <div className={styles.ManageScreenButton}>
                            <Button
                                variant="primary"
                                variantSize="xl"
                                onClick={() => {
                                    Authentication.setImpersonatingDeviceWithMacAddress('');
                                    Authentication.clearJsonWebToken();
                                    window.location.reload();
                                }}
                            >
                                End impersonation
                            </Button>
                        </div>
                    </div>
                ) : (
                    <React.Fragment>
                        {impersonateError ? (
                            <div className={styles.ManageScreenSection}>Could not impersonate using the provided MAC address.</div>
                        ) : null}

                        <div className={styles.ManageScreenSection}>
                            <Input
                                ref={inputRef as any}
                                value={currentHistory.query}
                                onChange={newQuery => dispatch(TVScreen.interactionState.reducerActions.setSearchQuery(newQuery))}
                                placeholder="MAC Address to impersonate"
                                buttonRight={
                                    <Button
                                        variant="primary"
                                        disabled={!currentHistory.query || attemptingImpersonate}
                                        onClick={async () => {
                                            setImpersonateError(false);
                                            setAttemptingImpersonate(true);
                                            try {
                                                await authenticationApi.GetDeviceToken({
                                                    macAddress: currentHistory.query,
                                                });
                                                Authentication.setImpersonatingDeviceWithMacAddress(currentHistory.query);
                                                Authentication.clearJsonWebToken();
                                                window.location.reload();
                                            } catch (e) {
                                                setImpersonateError(true);
                                            }
                                            setAttemptingImpersonate(false);
                                        }}
                                    >
                                        Impersonate
                                    </Button>
                                }
                            />

                            <OnScreenKeyboard
                                inputElement={inputRef.current}
                                forMacAddress={true}
                            />
                        </div>
                    </React.Fragment>
                )}
            </div>

            <InteractiveScreenNavigation
                withBack={true}
                withClose={true}
                getLanguageLabel={props.getLanguageLabel}
            />
        </div>
    );
}

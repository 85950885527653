import moment from 'moment';

import { Domain, uuid } from 'api';

import { ThunkAction } from '@/action';
import { nightHatchLogApi } from '@/api';
import { selectGlobalSelectedDeviceId } from '@/Device/globalSelectionState';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';

export type NightHatchLogLineProps = keyof Domain.NightHatchLogLine;

export const defaultSorting: Domain.Sorting<NightHatchLogLineProps> = {
    field: 'loggedOn',
    direction: 'descending',
};

export const pageSize = 20;

export const selectors = makeSelectors<Domain.NightHatchLogLine, NightHatchLogLineProps>({
    getState: rootState => rootState.nightHatch.logOverview,
});

export const reducerActions = makeReducerActions<Domain.NightHatchLogLine, NightHatchLogLineProps>({
    reducerPrefix: '@nightHatch/logOverview',
});

export const actions = makeActions<Domain.NightHatchLogLine, NightHatchLogLineProps>({
    dataTableSaveKey: 'nightHatchOverview-v5',
    loadApi: options =>
        options.globallySelectedDeviceId
            ? nightHatchLogApi.GetNightHatchLog(options.pagination, options.sorting, options.globallySelectedDeviceId, options.search)
            : undefined,
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/vending-machines/night-hatch',
    reducerActions,
    selectors,
});

export const logOverviewReducer = makeReducer<Domain.NightHatchLogLine, NightHatchLogLineProps>({
    defaultSorting,
    pageSize,
    reducerActions,
});

export const Log =
    (logText: string): ThunkAction =>
    async (_1, getState) => {
        const state = getState();
        const deviceId = selectGlobalSelectedDeviceId(state);

        if (!deviceId) {
            throw new Error('No deviceId');
        }

        await nightHatchLogApi.CreateNightHatchLog({
            nightHatchLogId: uuid.nightHatchLog(),
            deviceId,
            logText,
            loggedOn: moment.utc().valueOf(),
        });
    };

import * as React from 'react';

import { useGettext } from 'data-store';
import { Container, Button, ButtonGroup, Tx } from 'ui-components';
import { useParams } from 'utils';

import { AppRouteErrorData } from '@/Infrastructure/App/types';
import LandingPage from '@/Layouts/LandingPage';

export default function Error(props: { error?: Error }) {
    const { gettext } = useGettext();
    const isApiError = window.location.pathname.includes('/error/');

    let errorData: AppRouteErrorData;

    if (!props.error) {
        const { error } = useParams();

        try {
            errorData = JSON.parse(decodeURIComponent(error));
        } catch (e) {
            errorData = {
                message: '',
            };
        }
    } else {
        errorData = props.error;
    }

    return (
        <div className="MainNavigation hidden-overflow-sm">
            <Container
                fullWidth={true}
                limitedWidth={true}
            >
                <LandingPage
                    title="An error occurred"
                    noFooter={true}
                >
                    <Tx as="p">
                        {errorData.status} {errorData.statusText}
                    </Tx>
                    {errorData.message ? (
                        <React.Fragment>
                            <Tx as="p"> {gettext('Message (truncated)')}:</Tx>
                            <Tx
                                as="pre"
                                style={{
                                    overflow: 'visible',
                                    fontFamily: 'inherit',
                                    fontSize: 'inherit',
                                }}
                            >
                                {errorData.message}
                            </Tx>
                        </React.Fragment>
                    ) : null}
                    <ButtonGroup separated={true}>
                        <Button
                            className="mr-15"
                            onClick={() => (isApiError ? window.history.back() : location.reload())}
                        >
                            {isApiError ? gettext('Go back') : gettext('Reload page')}
                        </Button>
                        <Button
                            as="a"
                            href="/"
                            variantSize="l"
                            variant="primary"
                            title={gettext('Go to platform')}
                        >
                            {gettext('Go to platform')}
                        </Button>
                    </ButtonGroup>
                </LandingPage>
            </Container>
        </div>
    );
}

import { LocalizedValue } from './Localisation';
import { Pagination } from './Pagination';
import { Sorting } from './Sorting';

export const APPOINTMENTS_OVERVIEW_EXPORT_TYPE = ['today', 'tomorrow', 'nextWeek', 'nextMonth'] as const;
export type AppointmentsOverviewExportType = (typeof APPOINTMENTS_OVERVIEW_EXPORT_TYPE)[number];
interface AdditionalQuestionsAndAnswers {
    question: LocalizedValue;
    type: string;
    selectedOptions: LocalizedValue[];
    answer: string;
}
export interface AppointmentDetails {
    appointmentTypeId: string;
    appointmentId: string;
    type: LocalizedValue;
    startsAt: string;
    patientEmailAddress?: string | null;
    patientName: string;
    patientLocale: string;
    patientPhoneNumber?: string | null;
    durationInMinutes: number;
    canceledAt: string | null;
    additionalQuestionsAndAnswers: AdditionalQuestionsAndAnswers[];
}
export type AppointmentsPageDetails = Omit<AppointmentDetails, 'additionalQuestionsAndAnswers'>;
export interface AppointmentsPage {
    items: AppointmentsPageDetails[];
    total: number;
    pagination: Pagination;
    sorting?: Sorting<keyof AppointmentsPageDetails> | null;
    search?: string | null;
}

export type MakeAppointmentPayload = Omit<AppointmentDetails, 'canceledAt' | 'type'> & {
    remindMeByEmail: boolean;
};

export type CancelAppointmentType = {
    appointmentId: string;
    phoneNumber?: string;
    canceledBy: string;
    emailAddress?: string;
};

export type UpdateAppointmentsActiveStatusPayload = {
    activeStatus: 'active' | 'inactive';
};

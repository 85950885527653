import { DeviceSignal } from '@/Domain';
import { getConstant } from '@/Infrastructure/Container';
import DebugConsole from '@/Infrastructure/DebugConsole';
import { Peer, HeartbeatSyncGroup, NightHatchTunnel, RemoteDebugConsoleTunnel } from '@/RoomService';

type ConnectHandler = () => void;
type DisconnectHandler = (
    exception?: Error & {
        status?: number;
    },
) => void;
type DeviceSignalHandler = (signal: DeviceSignal) => void;
type LocalTimeDeltaChangeHandler = (delta: number) => void;
type SlideshowTimingChangeHandler = (duration: number, startTime: number) => void;

interface Meta {
    version: string;
    fingerprint: string;
    contentVersion?: number | null;
    os: 'linux' | 'windows' | 'mac' | 'other';
    chromiumVersion: string | 'unknown';
    timelineId: string;
    [key: string]: number | string | boolean | null | undefined;
}

export default class DeviceSignaling {
    private peer: Peer | undefined;
    private nightHatchTunnel: NightHatchTunnel | undefined;
    private remoteDebugConsoleTunnel: RemoteDebugConsoleTunnel | undefined;
    private heartbeatSyncGroup: HeartbeatSyncGroup | undefined;

    private disabled = false;
    private group: string;
    private slideshowId: string;
    private myId: string;
    private slideshowDuration: number;
    private slideshowStartTime: number;
    private meta: Meta;
    private slideshowTimingChangeHandler: SlideshowTimingChangeHandler | undefined;
    private deviceSignalHandler: DeviceSignalHandler | undefined;
    private connectHandler: ConnectHandler | undefined;
    private disconnectHandler: DisconnectHandler | undefined;
    private localTimeDeltaChangeHandler: LocalTimeDeltaChangeHandler | undefined;

    private readonly debugConsole: DebugConsole;

    public constructor() {
        this.debugConsole = new DebugConsole();
    }

    public getPeer(): Peer | undefined {
        return this.peer;
    }

    public getNightHatchTunnel(): NightHatchTunnel | undefined {
        return this.nightHatchTunnel;
    }

    public startDebugConsole() {
        this.debugConsole.interceptConsole();
    }

    public stopDebugConsole() {
        this.debugConsole.stopInterceptingConsole();
    }

    public disable() {
        this.disabled = true;
    }

    public isDisabled() {
        return this.disabled;
    }

    public connect(
        companyId: string,
        branchId: string,
        group: string,
        slideshowId: string,
        deviceId: string,
        deviceMac: string,
        slideshowDuration: number,
        slideshowStartTime: number,
        meta: Meta,
        onFailedToConnect: () => void,
    ): void {
        this.myId = deviceId;
        this.group = companyId + ':' + branchId + ':' + group;
        this.slideshowId = slideshowId;
        this.slideshowDuration = slideshowDuration;
        this.slideshowStartTime = slideshowStartTime;
        this.meta = meta;

        console.log('CONNECT TO SIGNALING SERVICE');
        if (this.peer && this.peer.isConnected()) {
            this.peer.disconnect();
        }

        this.peer = new Peer(
            this.myId,
            {
                signalingServerHost: getConstant('deviceSignalingServerHost'),
                jwt: getConstant('deviceSignalingToken') || '',
                reconnect: false,
                info: {
                    peerId: this.myId,
                    peerMac: deviceMac,
                    peerMeta: this.meta,
                },
            },
            () => onFailedToConnect(),
        );

        this.peer.addEventListener('message', (event: MessageEvent) => {
            const data: any = JSON.parse(event.data);

            if (
                data.type &&
                [
                    'deviceRefresh',
                    'deviceReboot',
                    'contentVersion',
                    'livePreview',
                    'deviceScreenshot',
                    'reloadDeviceSoftware',
                    'openMtCollectLocker',
                ].indexOf(data.type) > -1
            ) {
                if (this.deviceSignalHandler) {
                    this.deviceSignalHandler({
                        type: data.type,
                        payload: data.payload ? JSON.parse(data.payload) : undefined,
                    });
                }
            }
        });

        this.peer.addEventListener('connected', () => {
            this.startHeartbeatSyncGroup();

            if (this.connectHandler) {
                this.connectHandler();
            }
        });

        this.peer.addEventListener('disconnected', () => {
            this.heartbeatSyncGroup?.close();

            if (this.disconnectHandler) {
                this.disconnectHandler();
            }
        });

        this.peer.addEventListener('localTimeDelta', () => {
            if (this.localTimeDeltaChangeHandler) {
                this.localTimeDeltaChangeHandler(this.peer?.getLocalTimeDelta() || 0);
            }
        });
    }

    public listenForNightHatch(
        listenForNightHatch: boolean,
        onNightHatchStartRequest: () => void,
        onNightHatchDisconnect: () => void,
    ): void {
        this.nightHatchTunnel?.close();

        if (this.peer && listenForNightHatch) {
            this.nightHatchTunnel = new NightHatchTunnel(this.peer, this.peer.id, {
                tunnelSide: 'expect',
            });

            this.nightHatchTunnel.addEventListener('connected', onNightHatchStartRequest);
            const onDisconnect = () => {
                this.nightHatchTunnel?.removeEventListener('disconnected', onDisconnect);
                this.nightHatchTunnel?.removeEventListener('connected', onNightHatchStartRequest);
                onNightHatchDisconnect();

                this.listenForNightHatch(listenForNightHatch, onNightHatchStartRequest, onNightHatchDisconnect);
            };
            this.nightHatchTunnel.addEventListener('disconnected', onDisconnect);
        }
    }

    public listenForRemoteDebugConsole(): void {
        if (this.remoteDebugConsoleTunnel) {
            this.cleanUpListeners(this.remoteDebugConsoleTunnel);
            this.remoteDebugConsoleTunnel.close();
            this.remoteDebugConsoleTunnel = undefined;
            this.debugConsole.onNewLog(undefined);
        }

        if (this.peer) {
            this.remoteDebugConsoleTunnel = new RemoteDebugConsoleTunnel(this.peer, this.peer.id, {
                tunnelSide: 'expect',
            });

            this.remoteDebugConsoleTunnel.addEventListener('connected', this.onTunelConnect);
            this.remoteDebugConsoleTunnel.addEventListener('disconnected', this.onTunnelDisconnect);
            this.remoteDebugConsoleTunnel.addEventListener('message', this.onTunnelMessage);
        }
    }

    private onTunelConnect = () => {
        this.remoteDebugConsoleTunnel?.removeEventListener('connected', this.onTunelConnect);

        console.info('remote debug console tunnel connected');

        const logs = this.debugConsole.getAllLogs();
        this.remoteDebugConsoleTunnel?.send({
            type: 'logs',
            tunnel: true,
            logs,
        });

        this.debugConsole.onNewLog(log => {
            this.remoteDebugConsoleTunnel?.send({
                type: 'logs',
                tunnel: true,
                logs: [log],
            });
        });
    };

    private onTunnelMessage = (event: MessageEvent) => {
        const message = JSON.parse(event.data);
        if (message.tunnel === true && message.type === 'execute-command') {
            this.debugConsole.run(message.command);
        }
    };

    private cleanUpListeners = (tunnel: RemoteDebugConsoleTunnel) => {
        tunnel.removeAllRegisteredEventListeners();
    };

    private onTunnelDisconnect = () => {
        if (this.remoteDebugConsoleTunnel) {
            this.cleanUpListeners(this.remoteDebugConsoleTunnel);
            this.remoteDebugConsoleTunnel = undefined;
        }
        console.info('remote debug console tunnel disconnected');
        this.debugConsole.onNewLog(undefined);

        this.listenForRemoteDebugConsole();
    };

    private startHeartbeatSyncGroup(): void {
        if (!this.peer) {
            return;
        }

        this.heartbeatSyncGroup?.close();
        this.heartbeatSyncGroup = new HeartbeatSyncGroup(this.peer, this.group + ':' + this.slideshowId);

        this.heartbeatSyncGroup.setMetaData({
            slideshowDuration: this.slideshowDuration,
            slideshowStartTime: this.slideshowStartTime,
        });

        this.heartbeatSyncGroup.addEventListener('groupChanged', () => {
            this.notifySlideshowTimingChanged();
        });

        this.notifySlideshowTimingChanged();
    }

    public update(
        slideshowId: string,
        timelineId: string,
        slideshowDuration: number,
        slideshowStartTime: number,
        contentVersion?: number | null,
    ): void {
        this.slideshowDuration = slideshowDuration;
        this.slideshowStartTime = slideshowStartTime;

        if (slideshowId !== this.slideshowId) {
            this.slideshowId = slideshowId;
            this.startHeartbeatSyncGroup();
        } else {
            this.heartbeatSyncGroup?.setMetaData({
                slideshowDuration: this.slideshowDuration,
                slideshowStartTime: this.slideshowStartTime,
            });
        }

        if (this.peer) {
            const oldInfo = this.peer.getInfo();
            this.peer.setInfo({
                ...oldInfo,
                peerMeta: {
                    ...oldInfo.peerMeta,
                    contentVersion,
                    timelineId,
                },
            });
        }
    }

    public updateContentVersion(contentVersion?: number | null): void {
        if (this.peer) {
            const oldInfo = this.peer.getInfo();
            this.peer.setInfo({
                ...oldInfo,
                peerMeta: {
                    ...oldInfo.peerMeta,
                    contentVersion,
                },
            });
        }
    }

    public isConnected(): boolean {
        return this.peer?.isConnected() || false;
    }

    public onConnect(connectHandler: ConnectHandler): void {
        this.connectHandler = connectHandler;
        if (this.peer?.isConnected()) {
            this.connectHandler();
        }
    }

    public onDisconnect(disconnectHandler: DisconnectHandler): void {
        this.disconnectHandler = disconnectHandler;
    }

    public close(): void {
        console.info('disconnect from signaling service');

        this.peer?.disconnect();
    }

    public onDeviceSignal(deviceSignalHandler: DeviceSignalHandler): void {
        this.deviceSignalHandler = deviceSignalHandler;
    }

    public onSlideshowTimingChange(slideshowTimingChangeHandler: SlideshowTimingChangeHandler) {
        this.slideshowTimingChangeHandler = slideshowTimingChangeHandler;
        this.notifySlideshowTimingChanged();
    }

    public onLocalTimeDeltaCange(localTimeDeltaChangeHandler: LocalTimeDeltaChangeHandler) {
        this.localTimeDeltaChangeHandler = localTimeDeltaChangeHandler;
    }

    private notifySlideshowTimingChanged(): void {
        const longestSlideshowInfo = this.heartbeatSyncGroup?.getPeerWithLongestSlideshowInfo();
        const earliestStartTimeInfo = this.heartbeatSyncGroup?.getPeerWithEarliestSlideshowStartTimeInfo();
        if (
            longestSlideshowInfo &&
            longestSlideshowInfo.peerMeta &&
            earliestStartTimeInfo &&
            earliestStartTimeInfo.peerMeta &&
            this.slideshowTimingChangeHandler
        ) {
            this.slideshowTimingChangeHandler(
                longestSlideshowInfo.peerMeta.slideshowDuration,
                earliestStartTimeInfo.peerMeta.slideshowStartTime,
            );
        }
    }
}

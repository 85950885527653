import * as React from 'react';

import classNames from 'classnames';

import { KeyboardHandler, InputBase, InputBaseProps, ContentEditable } from '@/core';

import getCSSPropsFromRTValue from './getCSSPropsFromRTValue';
import RTValue from './RTValue';

import styles from './RTEditor.scss';

export type ControlledRTEditorProps = InputBaseProps & {
    placeholder?: string;
    forwardRef?: React.Ref<HTMLInputElement>;
    onChange?: (newValue: RTValue) => void;
    onChangeStart?: () => void;
};

export type ControlledRTEditorWithValue = ControlledRTEditorProps & {
    value: RTValue;
};

interface IState {
    changeInProgress: boolean;
}

class ControlledRTEditor extends InputBase<ControlledRTEditorWithValue, IState> {
    private readonly ce = React.createRef<HTMLDivElement>();
    private readonly keyHandler = new KeyboardHandler();
    private changeTimeout: ReturnType<typeof setTimeout> | undefined = undefined;

    constructor(props: ControlledRTEditorWithValue) {
        super(props);

        this.state = {
            changeInProgress: false,
        };
    }

    private getValue(): RTValue {
        return this.props.value;
    }

    protected getOtherBaseWrapperProps(): any {
        return {
            onClick: () => {
                if (this.ce.current) {
                    this.ce.current.focus();
                }
            },
        };
    }

    protected renderInput(): React.ReactNode {
        const { name, disabled, forwardRef, placeholder } = this.props;
        const value = this.getValue();

        return (
            <React.Fragment>
                <input
                    ref={forwardRef}
                    type="hidden"
                    name={name}
                    value={value.toString()}
                    disabled={disabled}
                />

                <div
                    className={styles.RTEditorWrap}
                    style={getCSSPropsFromRTValue(value)}
                    onMouseUp={event => {
                        event.nativeEvent.stopImmediatePropagation();
                    }}
                >
                    {placeholder && !value.text ? <div className={styles.RTEditorPlaceholder}>{placeholder}</div> : null}

                    <ContentEditable
                        ref={this.ce}
                        className={styles.ContentEditable}
                        value={value.text}
                        onChange={this.handleTextChange}
                        onKeyDown={event => {
                            this.keyHandler.handleKey(
                                ['B', 'U', 'I'],
                                (_1: any, key: string) => {
                                    const newValue = this.getValue();

                                    switch (key) {
                                        case 'B':
                                            this.handleSetOption('bold', newValue.options ? !newValue.options.bold : undefined);
                                            break;
                                        case 'U':
                                            this.handleSetOption('underline', newValue.options ? !newValue.options.underline : undefined);
                                            break;
                                        case 'I':
                                            this.handleSetOption('italic', newValue.options ? !newValue.options.italic : undefined);
                                            break;
                                        default:
                                            break;
                                    }
                                },
                                { ctrl: true },
                            )(event);

                            this.keyHandler.handleKey(
                                ['S'],
                                () => {
                                    const newValue = this.getValue();
                                    this.handleSetOption('strikethrough', newValue.options ? !newValue.options.strikethrough : undefined);
                                },
                                { ctrl: true, shift: true },
                            )(event);
                        }}
                    />
                </div>
            </React.Fragment>
        );
    }

    private handleTextChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.value === '<br>') {
            event.target.value = '';
        }

        this.handleSetText(event.target.value);
    };

    private handleSetText = (text: string): void => {
        const crtValue = this.getValue();
        this.handleChange(crtValue.setText(text));
    };

    private handleSetOption = (option: string, value: any): void => {
        const crtValue = this.getValue();
        this.handleChange(crtValue.setOption(option, value));
    };

    private handleChange = (value: RTValue): void => {
        if (this.props.onChangeStart) {
            if (this.changeTimeout) {
                clearTimeout(this.changeTimeout);
            }

            this.changeTimeout = setTimeout(() => {
                this.setState({
                    changeInProgress: false,
                });
            }, 500);

            if (!this.state.changeInProgress) {
                this.setState({
                    changeInProgress: true,
                });

                this.props.onChangeStart();
            }
        }

        if (this.props.onChange) {
            this.props.onChange(value);
        }
    };

    protected getClassName(): string {
        return classNames(super.getClassName(), styles.RTEditor);
    }
}

export default React.forwardRef<HTMLInputElement, ControlledRTEditorWithValue>((props, ref) => {
    return (
        <ControlledRTEditor
            forwardRef={ref}
            {...props}
        />
    );
});

import * as React from 'react';

import { createPortal } from '../core';

export interface AnchoredPortalPositioningProps {
    anchorVPosition?: 'top' | 'center' | 'bottom' | 'auto';
    anchorHPosition?: 'left' | 'center' | 'right' | 'left-align';
}

export default function AnchoredPortal(
    props: {
        className?: string;
        children?: React.ReactNode;
        anchorElement: HTMLElement | null;
        inheritWidthFromAnchor?: boolean;
        inheritMinWidthFromAnchor?: boolean;
        anchorCanMove?: boolean;
        insideModal?: boolean;
    } & AnchoredPortalPositioningProps,
) {
    const refreshTimer = React.useRef<ReturnType<typeof setTimeout> | undefined>(undefined);
    const [contentsElement, setContentsElement] = React.useState<HTMLDivElement | null>(null);
    const [sidebarElement, setSidebarElement] = React.useState<HTMLElement | null>(document.getElementById('main-sidebar'));
    const contentsRef = useHookWithRefCallback<HTMLDivElement>(setContentsElement);
    const [portalContainer, setPortalContainer] = React.useState<HTMLElement | null>(document.getElementById('app-portal-container'));
    const [x, setX] = React.useState<number | undefined>(undefined);
    const [y, setY] = React.useState<number | undefined>(undefined);
    const [anchor, setAnchor] = React.useState<HTMLElement | null>(null);
    const [anchorHeight, setAnchorHeight] = React.useState<number | undefined>(undefined);
    const [anchorWidth, setAnchorWidth] = React.useState<number | undefined>(undefined);
    const [contentsHeight, setContentsHeight] = React.useState<number | undefined>(undefined);
    const [contentsWidth, setContentsWidth] = React.useState<number | undefined>(undefined);

    React.useEffect(() => {
        if (!portalContainer) {
            setPortalContainer(document.getElementById('app-portal-container'));
        }

        if (!sidebarElement) {
            setSidebarElement(document.getElementById('main-sidebar'));
        }
    }, []);

    const refresh = () => {
        if (refreshTimer.current) {
            clearTimeout(refreshTimer.current);
        }

        refreshTimer.current = setTimeout(
            () => {
                refresh();
            },
            props.anchorCanMove ? 10 : 1000,
        );

        if (!anchor) {
            return;
        }

        const anchorBox = anchor.getBoundingClientRect();

        setY(anchorBox.y);
        setX(anchorBox.x);
        setAnchorHeight(anchorBox.height);
        setAnchorWidth(anchorBox.width);

        if (contentsElement && contentsElement.children.length > 0) {
            const contentsBox = contentsElement.children[0].getBoundingClientRect();
            setContentsHeight(contentsBox.height);
            setContentsWidth(contentsBox.width);
        }
    };

    React.useEffect(() => {
        refresh();
    }, [props.anchorCanMove]);

    React.useEffect(() => {
        setAnchor(props.anchorElement);
    }, [props.anchorElement]);

    React.useEffect(() => {
        refresh();
    }, [anchor, contentsElement]);

    React.useEffect(() => {
        return () => {
            if (refreshTimer.current) {
                clearTimeout(refreshTimer.current);
            }
        };
    }, []);

    const style: React.CSSProperties = {
        position: 'absolute',
        zIndex: props.insideModal ? 4000 : 2000, // depends on SCSS $depth-main-sidebar variable
    };

    let left = 0;
    let top = 0;
    let opensOnTop = false;
    let minLeft = sidebarElement ? sidebarElement.getBoundingClientRect().width : 0;
    if (minLeft >= window.outerWidth) {
        minLeft = 0;
    }

    if (
        x === undefined ||
        y === undefined ||
        anchorWidth === undefined ||
        anchorHeight === undefined ||
        contentsWidth === undefined ||
        contentsHeight === undefined
    ) {
        style.opacity = 0;
        style.top = top + 'px';
        style.left = left + 'px';
    } else {
        if (props.inheritWidthFromAnchor) {
            style.width = anchorWidth + 'px';
        } else if (props.inheritMinWidthFromAnchor) {
            style.minWidth = anchorWidth + 'px';
        }

        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        const scrollLeft = document.documentElement.scrollLeft || document.body.scrollLeft;

        top = y + scrollTop;
        left = x + scrollLeft;

        if (props.anchorVPosition === 'top') {
            top -= contentsHeight;
        } else if (props.anchorVPosition === 'bottom' || props.anchorVPosition === 'auto') {
            top += anchorHeight;
        } else if (props.anchorVPosition === 'center') {
            top += anchorHeight / 2;
            top -= contentsHeight / 2;
        }

        if (props.anchorHPosition === 'right') {
            left += anchorWidth;
        } else if (props.anchorHPosition === 'left') {
            left -= contentsWidth;
        } else if (props.anchorHPosition === 'left-align') {
            left += anchorWidth;
            left -= contentsWidth;
        } else if (props.anchorHPosition === 'center') {
            left += anchorWidth / 2;
            left -= contentsWidth / 2;
        }

        if (
            props.anchorVPosition === 'auto' &&
            top + contentsHeight - scrollTop > document.documentElement.clientHeight &&
            top - anchorHeight - contentsHeight > 0
        ) {
            top -= anchorHeight + contentsHeight;
            opensOnTop = true;
        }

        const bodyRect = document.body.getBoundingClientRect();

        if (left + contentsWidth > bodyRect.width) {
            left = bodyRect.width - contentsWidth;
        }

        if (left < minLeft) {
            left = minLeft;
        }

        style.left = left + 'px';

        if (!opensOnTop) {
            style.top = top + 'px';
        } else {
            style.bottom = document.documentElement.clientHeight - top - contentsHeight + 'px';
        }
    }

    if (!portalContainer || x === undefined || y === undefined) {
        return <span />;
    }

    return createPortal(
        <div
            ref={contentsRef as any}
            className={props.className}
            style={style}
        >
            {props.children}
        </div>,
    );
}

function useHookWithRefCallback<T>(callback: (element: T | null) => void) {
    const ref = React.useRef<T>(null);
    return React.useCallback((element: T) => {
        callback(element);

        // @ts-ignore
        ref.current = element;
    }, []);
}

import DelegatedEventTarget from './DelegatedEventTarget';

interface PeerInfo {
    peerId?: string;
    peerMac?: string;
    peerMeta?: {
        [key: string]: number | string | boolean | null | undefined;
    };
}

export default class Peer extends DelegatedEventTarget {
    public readonly id: string;
    private readonly signalingServerHost: string;
    private readonly jwt: string;
    private info: PeerInfo;
    private reconnect: boolean;
    private reconnectTimeout: ReturnType<typeof setTimeout>;
    private socket: WebSocket | undefined;
    private localTimeDelta = 0;

    public constructor(
        id: string,
        options: {
            signalingServerHost: string;
            jwt: string;
            reconnect?: boolean;
            info: PeerInfo;
        },
        onFailedToConnect: (e: Event) => void,
    ) {
        super();

        this.id = id;
        this.signalingServerHost = options.signalingServerHost;
        this.jwt = options.jwt;
        this.reconnect = options.reconnect || false;
        this.info = options.info;

        this.connect(onFailedToConnect);
    }

    public onFirstConnect(handler: () => void): void {
        if (this.isConnected()) {
            handler();
            return;
        }

        const onConnect = () => {
            this.removeEventListener('connected', onConnect);
            handler();
        };

        this.addEventListener('connected', onConnect);
    }

    public joinGroup(groupId: string): void {
        this.send({
            action: 'joinGroup',
            payload: {
                groupId,
            },
        });
    }

    public setGroupMetaData(groupId: string, metaData: PeerInfo['peerMeta']): void {
        this.send({
            action: 'setGroupMetaData',
            payload: {
                groupId,
                metaData,
            },
        });
    }

    public leaveGroup(groupId: string): void {
        this.send({
            action: 'leaveGroup',
            payload: {
                groupId,
            },
        });
    }

    private connect(onFailedToConnect: (e: Event) => void): void {
        this.socket = new WebSocket(
            'wss://' +
                this.signalingServerHost +
                '/register?meta=' +
                JSON.stringify({
                    peerId: this.id,
                }),
            this.jwt,
        );

        const handleOpen = () => {
            this.dispatchEvent(new Event('connected'));
            this.socket?.addEventListener('close', handleClose);

            this.socket?.addEventListener('message', event => {
                if (event.data === 'ping') {
                    this.socket?.send('pong');
                    return;
                }

                if (event.data.startsWith('ping:')) {
                    const now = new Date().getTime();
                    const remoteNow = parseInt(event.data.split(':')[1], 10);
                    this.localTimeDelta = now - remoteNow;
                    this.dispatchEvent(new Event('localTimeDelta'));

                    this.socket?.send('pong:' + now);
                    return;
                }

                const data = JSON.parse(event.data);
                if (data.action === 'groupChanged') {
                    this.dispatchEvent(
                        new MessageEvent('groupChanged', {
                            data: event.data,
                        }),
                    );
                } else if (data.action === 'message' && data.payload.message.expectTunnel === true) {
                    this.dispatchEvent(
                        new MessageEvent('tunnelRequest:' + data.payload.message.prefix, {
                            data: {
                                from: data.payload.from,
                                prefix: data.payload.message.prefix,
                            },
                        }),
                    );
                }

                this.dispatchEvent(
                    new MessageEvent('message', {
                        data: event.data,
                    }),
                );
            });
        };

        const handleClose = () => {
            this.socket?.removeEventListener('close', handleClose);
            this.socket?.removeEventListener('open', handleOpen);

            console.info('PEER Socket closed');
            this.dispatchEvent(new Event('disconnected'));
            this.socket?.close();
            this.socket = undefined;

            clearTimeout(this.reconnectTimeout);
            this.reconnectTimeout = setTimeout(() => {
                if (this.reconnect) {
                    this.connect(onFailedToConnect);
                }
            }, 10000);
        };

        const handleError = (event: Event) => {
            this.socket?.removeEventListener('error', handleError);
            onFailedToConnect(event);
        };

        this.socket.addEventListener('open', handleOpen);
        this.socket.addEventListener('error', handleError);
    }

    public isConnected(): boolean {
        return !!(this.socket && this.socket.readyState === this.socket.OPEN);
    }

    public disconnect(): void {
        if (!this.socket || this.socket.readyState === this.socket.CLOSED) {
            throw new Error('Attempted to disconnect from an already disconnected socket');
        }

        this.reconnect = false;
        this.socket.close();
    }

    public send(message: object): void {
        if (!this.socket || this.socket.readyState === this.socket.CLOSED) {
            throw new Error('Attempted to send a message through a disconnected socket');
        }

        if (this.socket.readyState === this.socket.CONNECTING) {
            throw new Error('Attempted to send a message through a socket that is connecting');
        }

        if (this.socket.readyState === this.socket.CLOSING) {
            throw new Error('Attempted to send a message through a socket that is closing');
        }

        this.socket.send(
            JSON.stringify({
                peerInfo: this.info,
                ...message,
            }),
        );
    }

    public setInfo(info: PeerInfo): void {
        this.info = info;
    }

    public getInfo(): PeerInfo {
        return this.info;
    }

    public getLocalTimeDelta() {
        return this.localTimeDelta;
    }
}

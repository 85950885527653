import * as React from 'react';

import { Domain } from 'api';
import { CanvasFrame, EditorCanvas } from 'editor-canvas';
import { getScreenSizeInPX, PharmacyOnDuty } from 'utils';

import { store } from '../state';
import { Frame } from '../types';
import FrameBody from './FrameBody';
import ProductImageSizeComputer from './ProductImageSizeComputer';
import TouchTargets from './TouchTargets';

import styles from './styles/Designer.scss';

export default function LivePreview(props: {
    gettext: (text: string) => string;
    className?: string;
    style?: React.CSSProperties;
    proxyImageURL: (url: string) => string;
    getMediaItemURL: (mediaItemId: string) => string;
    getVideoDataURL?: (videoURL: string) => string | undefined;
    getVideoMediaItemPreviewURL: (mediaItemId: string) => string;
    onFirstCompleteRender?: () => void;
    autoPlayVideos: boolean;
    disableVideoPosters: boolean;
    loopVideos: boolean;
    onProductClick: (productId: string) => void;
    onHiddenSlideClick: (slideId: string) => void;
    usePreviewImageForContents?: false | string;
    renderVideo: (videoProps: React.VideoHTMLAttributes<any>) => React.ReactElement;
    getPharmaciesOnDuty: (numPharmacists: number, locale: string) => PharmacyOnDuty[];
    getOpeningHours: () => Domain.OpeningHours | null;
    locale: string;
    screenSizeInPX?: {
        width: number;
        height: number;
    };
}) {
    const {
        gettext,
        className,
        style,
        proxyImageURL,
        getMediaItemURL,
        getVideoDataURL,
        getVideoMediaItemPreviewURL,
        onProductClick,
        onHiddenSlideClick,
        autoPlayVideos,
        disableVideoPosters,
        loopVideos,
        renderVideo,
        getPharmaciesOnDuty,
        getOpeningHours,
        locale,
    } = props;
    const { state } = React.useContext(store);

    const screenSizeInPX = props.screenSizeInPX || getScreenSizeInPX(state.screenResolution);

    let usePreviewImageForContents = props.usePreviewImageForContents;
    for (const frame of state.canvas.frames) {
        if (
            (frame.type === 'text' && frame.styleBackground && frame.backgroundType === 'video') ||
            (frame.type === 'media' && frame.mediaType === 'video') ||
            (frame.type === 'background' && frame.backgroundType === 'video')
        ) {
            usePreviewImageForContents = false;
            break;
        }
    }

    return (
        <div
            className={(className || '') + ' ' + styles.CustomPageDesigner + ' ' + styles.CustomPageDesignerPreviewMode}
            style={style}
        >
            {usePreviewImageForContents ? (
                <img
                    src={usePreviewImageForContents}
                    alt=""
                />
            ) : null}

            {!usePreviewImageForContents ? (
                <EditorCanvas
                    frameRenderer={(frame: CanvasFrame) => {
                        return (
                            <FrameBody
                                gettext={gettext}
                                frame={frame as Frame}
                                proxyImageURL={proxyImageURL}
                                getMediaItemURL={getMediaItemURL}
                                getVideoDataURL={getVideoDataURL}
                                getVideoMediaItemPreviewURL={getVideoMediaItemPreviewURL}
                                autoPlayVideos={autoPlayVideos}
                                disableVideoPosters={disableVideoPosters}
                                loopVideos={loopVideos}
                                renderVideo={renderVideo}
                                getPharmaciesOnDuty={getPharmaciesOnDuty}
                                getOpeningHours={getOpeningHours}
                                locale={locale}
                            />
                        );
                    }}
                    getFrameClassName={(frame: CanvasFrame) => (frame as Frame).type}
                    draggedBlockRenderer={() => null}
                    resizeMiddleIsDisabledWhenEditingFrame={() => true}
                    scaleTo={screenSizeInPX}
                />
            ) : null}

            <TouchTargets
                scaleTo={screenSizeInPX}
                onProductClick={onProductClick}
                onHiddenSlideClick={onHiddenSlideClick}
            />

            <ProductImageSizeComputer proxyImageURL={proxyImageURL} />
        </div>
    );
}
